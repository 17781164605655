import commonConstant from '../../common/commonConstant';

export const getProfile = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_PROFILE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_PROFILE_FAILURE:
      return { ...state, ...action };
    case commonConstant.LOGOUT_SUCCESS:
      return {};
    case commonConstant.LOGOUT_FAILURE:
      return {};
    default:
      return state;
  }
};
