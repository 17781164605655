import commonConstant from '../../common/commonConstant';

export const getClientType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_CLIENT_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_CLIENT_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getDeliveryZoneType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_DELIVERY_ZONE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_DELIVERY_ZONE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getLateStatusType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_LATE_STATUS_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_LATE_STATUS_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getDayType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_DAY_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_DAY_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getWareHouseType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_WARE_HOUSE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_WARE_HOUSE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getProductGroupType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_PRODUCT_GROUP_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_PRODUCT_GROUP_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getBrandType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_BRAND_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_BRAND_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getReceiveType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_RECEIVE_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_RECEIVE_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getAgingDayType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_AGING_DAY_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_AGING_DAY_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getTransporType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_TRANSPORT_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_TRANSPORT_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getCarrierType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_CARRIER_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_CARRIER_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getPickType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_PICK_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_PICK_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getPackType = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_PACK_TYPE_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_PACK_TYPE_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
