import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchOrganizations } from '../../actions';

import './AreaManagement.scss';

const AreaManagement = () => {
  const [organ, setOrgan] = useState([]);
  const getOrganizations = useSelector(({ getOrganizations }) => getOrganizations.data);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (getOrganizations) {
      setOrgan(getOrganizations);
    } else {
      dispatch(fetchOrganizations());
    }
  }, [getOrganizations]);

  return (
    <div className="AreaManagement">
      <div className="template-with-sidebar-navbar">
        {
          organ.map((item, key) =>
            <Link key={key} to={`area-management/organize/${item.organizationId}`}>
              <div className="area-item mb-3">
                <div className="area-item-logo"></div>
                <div className="area-item-title">{item.name}</div>
              </div>
            </Link>
          )
        }
      </div>
    </div>
  );
};

export default AreaManagement;
