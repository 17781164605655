export const timeFormatHHMM = time => {
  try {
    if (time) {
      const newTime = new Date(time);
      return `${newTime.getHours() >= 10 ? newTime.getHours() : `0${newTime.getHours()}`}:${newTime.getMinutes() >= 10 ? newTime.getMinutes() : `0${newTime.getMinutes()}`}`;
    }
    return '-';
  } catch (err) {
    return '-';
  }
};

export const dateDDMMYYFormat = date => {
  try {
    if (date) {
      const newDate = new Date(date);
      return `${newDate.getDate() > 9 ? newDate.getDate() : `0${newDate.getDate()}`}/${(newDate.getMonth() + 1) > 9 ? newDate.getMonth() + 1 : `0${newDate.getMonth() + 1}`}/${newDate.getFullYear().toString().substr(-2)}`;
    }
    return '-';
  } catch (err) {
    return '-';
  }
};
