import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Chart from 'react-apexcharts';
import { CardPieAndPercent, ChartAndLegend, ChartBox } from '../../components';

import { Dropdowns } from '../../helpers';
import { fetchHighView, fetchClientType } from '../../actions';
const colors = ['#34D1BF', '#FF715B', '#FED70D'];

const HighLevel = () => {
  const [durationType] = useState([{ text: 'Daily (in 5 day)', value: 'daily' }, { text: 'Weekly (in 4 week)', value: 'weekly' }, { text: 'Monthy (in 3 month)', value: 'monthly' }]);
  const [clientType, setClientType] = useState([]);
  const [duration, setDuration] = useState({ text: 'Daily (in 5 day)', value: 'daily' });
  const [client, setClient] = useState();
  const [delivery, setDelivery] = useState([]);
  const [pending, setPending] = useState({ topProduct: [], topClient: [] });
  const [top, setTop] = useState({ topProduct: [], topClient: [] });
  const [today, setToday] = useState({ percentWHTS: [], orderStatusToday: [] });
  const [monthly, setMonthly] = useState({ orderStatus: [], orderAsSla: [] });
  const getHighView = useSelector(({ getHighView }) => getHighView.data);
  const getClientType = useSelector(({ getClientType }) => getClientType.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getHighView) {
      const { deliveryStatus, pendingStatus, topFive, orderToday, orderMonthly } = getHighView;
      
      setDelivery(deliveryStatus);
      setPending(pendingStatus);
      setTop(topFive);
      setToday(orderToday);
      setMonthly(orderMonthly);
    } else {
      init('', 'daily');
    }
  }, [getHighView]);
  useEffect(() => {
    if (getClientType) {
      setClientType(getClientType.result.list);
    } else {
      dispatch(fetchClientType());
    }
  }, [getClientType]);
  useEffect(() => {
    const interval = setInterval(() => {
      init('', '');
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  const series = [{
    type: 'column',
    data: monthly.orderStatus.map(i => i.totalOrder),
  }, {
    type: 'line',
    data: monthly.orderStatus.map(i => i.totalOrder),
  }];
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 1],
      curve: 'straight',
      dashArray: [0, 2],
    },
    plotOptions: {
      bar: {
        columnWidth: '12%',
      },
    },
    labels: monthly.orderStatus.map(i => i.month),
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    legend: { show: false },
  };
  const init = (_client, _period) => {
    const params = {
      param: { 
        client: _client ? _client : client ? client.value : '',
        period: _period ? _period : Object.keys(duration).length ? duration.value : '',
      },
      url: '/post/HighLevelViewDashBoard',
    };
    dispatch(fetchHighView(params));
  };
  const toggleDuration = data => {
    setDuration(data);
    init(client && Object.keys(client).length ? client.value : '', data.value);
  };
  const toggleClient = data => {
    setClient(data);
    init(data.value, duration.value);
  };
  const checkToday = day => day === new Date().toISOString().split('T')[0] ? 'Today' : day;
  
  return (
    <div className="HighLevel">
      <div className="c-filter">
        <div className="txt-filter"></div>
        <div className="filter-dropdown">
          <Dropdowns
            placeholder="Duration..."
            items={durationType}
            selected={duration}
            disabled={true}
            handleSelect={value => toggleDuration(value)} />
        </div>
        <div className="filter-dropdown">
          <Dropdowns
            placeholder="Clients..."
            items={clientType}
            selected={client}
            disabled={true}
            handleSelect={value => toggleClient(value)} />
        </div>
      </div>
      <div className="c-pie-card">
        <div className="c-pie-card-header">
          <div className="div-cpie-l">
            <div className="_title">Delivery status</div>
          </div>
          <div className="div-cpie-r">
            <div><i style={{ background: colors[0] }} /> Complete</div>
            <div><i style={{ background: colors[1] }} /> Packing</div>
            <div><i style={{ background: colors[2] }} /> Intransit</div>
          </div>
        </div>
        <div className="c-pie-card-body">
          {
            delivery.map((item, key) => (
              <div key={key} className="c-pie-item">
                <CardPieAndPercent label={item.totalPercentComplete} 
                  series={[
                    parseInt(item.totalPercentComplete), 
                    parseInt(item.totalPercentWH),
                    parseInt(item.totalPercentTS),
                  ]} />
                <div className="_title">{item.totalOrderDelivery ? parseInt(item.totalOrderDelivery).toLocaleString() : 0} Total order</div>
                <div className="_detail">{checkToday(item.date)}</div>
              </div>
            ))
          }
        </div>
      </div>

      <div className="div-pending-status">
        <div className="pending-status">
          <div className="card-title">Pending Status</div>
          <div className="pending-status-rows">
            <ChartAndLegend title="Product" 
              series={pending.topProduct.map(i => i.totalPercent)} 
              labels={pending.topProduct.map(i => i.topName)} />
            <ChartAndLegend title="Client" 
              series={pending.topClient.map(i => i.totalPercent)} 
              labels={pending.topClient.map(i => i.topName)} />
          </div>
        </div>
        <div className="top-product">
          <div className="top-product-title">Top 5 Products order</div>
          {
            top.topProduct.map((item, key) => (
              <p key={key}>{key + 1} ) {item.topName}</p>
            ))
          }
        </div>
        <div className="top-op-client">
          <div className="top-op-client-title">Top 5 Clients order</div>
          {
            top.topClient.map((item, key) => (
              <p key={key}>{key + 1} ) {item.topName}</p>
            ))
          }
        </div>
      </div>

      <div className="div-monthly-line">Daily</div>
      <div className="c-overall">
        {
          today.percentWHTS.map((item, key) => key < 1
            ? <ChartBox key={key} header={item.orderStatus} unit={item.totalUnit} total={item.totalOrder} percent={item.bkkPercent} />
            : <ChartBox key={key} header={item.orderStatus} total={`${item.totalOrderPercent}%`} percent={item.bkkPercent} />,
          )
        }
      </div>
      <div className="c-overall">
        {
          today.orderStatusToday.map((item, key) => (
            <ChartBox key={key} header={item.orderStatus} total={item.totalOrder} unit={item.totalUnit} isChangeTxt={true} />
          ))
        }
      </div>

      <div className="div-monthly-line">Monthly</div>
      <div className="div-monthly">
        <div className="order-status">
          <div className="card-title">Order Volume<small>(Monthly)</small></div>
          <Chart series={series} options={options} type="line" height="250" />
        </div>
      </div>
    </div>
  );
};

export default HighLevel;
