export default {
  'th': {
    'title': 'CHECK YOUR EMAIL',
    'description': 'Please check your email to re-create password.',
    'backToLogin': 'BACK TO LOGIN',
  },
  'en': {
    'title': 'CHECK YOUR EMAIL',
    'description': 'Please check your email to re-create password.',
    'backToLogin': 'BACK TO LOGIN',
  },
};
