export default {
  'th': {
    'login': 'LOGIN',
    'email': 'E-mail',
    'password': 'Password',
    'forgotPassword': 'forgot password',
    'btnLogin': 'LOG IN',
    'textWarning': 'อีเมลหรือรหัสผ่านผิด',
  },
  'en': {
    'login': 'LOGIN',
    'email': 'E-mail',
    'password': 'Password',
    'forgotPassword': 'forgot password',
    'btnLogin': 'LOG IN',
    'textWarning': 'อีเมลหรือรหัสผ่านผิด',
  },
};
