import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Button, Table } from 'reactstrap';
import {
  fetchOrderTrackingReport,
  fetchOrderTrackingExport,
  fetchLateStatusType,
  fetchClientType,
  fetchDeliveryZoneType,
  fetchDayType, fetchGetProfile,
} from '../../actions';

import { WhiteBox, InputWithIcon, Dropdowns, DatePicker, Checkbox, ThSorting } from '../../helpers';
import commonConstant from '../../common/commonConstant';
import { formatDate } from '../../common/commonFunction';

import { Loading, RefreshToken } from '../../components';

class OrderTrackingReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientProps: '',
      client: '',
      deliveryZone: null,
      latestStatus: null,
      orderFromDate: null,
      orderToDate: null,
      deliveryFromDate: null,
      deliveryToDate: null,
      tracking: null,
      deliveryDate: null,
      orderTracking: null,
      orderDetails: [],
      clientType: [],
      deliveryZoneType: [],
      lateStatusType: [],
      dayType: [],
      modalOrderDetails: null,
      lateStatusTypeAll: false,
      current: 1,
      typeSort: '',
      column: '',
      isLoading: false,
      total: {},
      roleList: [],
    };

    this.props.dispatch(fetchGetProfile());
    this.props.dispatch(fetchLateStatusType());
  }
  componentDidMount() {
    if (this.props.getProfile) {
      const { clients, roles } = this.props.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { clients, roles } = nextProps.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
    if (nextProps.getOrderTrackingReport && nextProps.getOrderTrackingReport !== this.props.getOrderTrackingReport) {
      this.setState({
        orderTracking: nextProps.getOrderTrackingReport && nextProps.getOrderTrackingReport.data && nextProps.getOrderTrackingReport.data.length ? nextProps.getOrderTrackingReport : {},
        total: nextProps.getOrderTrackingReport.total && Object.keys(nextProps.getOrderTrackingReport.total) ? nextProps.getOrderTrackingReport.total : {},
        isLoading: false });
    }
    if (nextProps.getOrderTrackingExport && nextProps.getOrderTrackingExport !== this.props.getOrderTrackingExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getClientType && nextProps.getClientType !== this.props.getClientType) {
      const clientType = nextProps.getClientType.result.list.map(item => {
        item.label = item.text;
        return item;
      });
      this.setState({ clientType });
    }
    if (nextProps.getLateStatusType && nextProps.getLateStatusType !== this.props.getLateStatusType) {
      this.setState({ lateStatusType: nextProps.getLateStatusType.result.list });
    }
    if (nextProps.getDeliveryZoneType && nextProps.getDeliveryZoneType !== this.props.getDeliveryZoneType) {
      this.setState({ deliveryZoneType: nextProps.getDeliveryZoneType.result.list });
    }
    if (nextProps.getDayType && nextProps.getDayType !== this.props.getDayType) {
      this.setState({ dayType: nextProps.getDayType.result.list });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current } = this.state;

    this.setState({ column: await column, typeSort: await typeSort });
    this.handleSearch(current);
  }
  getInit = code => {
    this.setState({
      deliveryZoneType: 'LOADING',
      dayType: 'LOADING',
    });
    this.props.dispatch(fetchDeliveryZoneType(code));
    this.props.dispatch(fetchDayType(code));
  }
  checkRole = clients => {
    this.setState({ clientProps: clients });
    const roleList = this.state.roleList;
    if (roleList[0] === 'SUPER_ADMIN') {
      this.props.dispatch(fetchClientType());
      this.getInit('');
    } else {
      const items = clients.map(item => {
        const data = {};
        data.label = item.text;
        data.value = item.value;
        return data;
      });

      this.setState({ clientType: items });
      this.getInit(clients.map(_ => _.value).join());
    }
  }
  handleTitle = () => (
    <div className="box-title">
      <span className="title">Search</span>
      <InputWithIcon
        onChange={event => this.handleSelect('tracking', event.target.value)}
        value={this.state.tracking}
        icon="icon-search"
        placeholder="ex. Accessor"
      />
    </div>
  );
  selectClient = client => this.setState({ client });
  selectClientOnblue = (value, action) => {
    if (action.action === 'input-blur') {
      const { client } = this.state;
      const clientFilter = client && client.length > 0 ? client.map(item => item.value).join() : '';
      if (clientFilter) {
        this.getInit(clientFilter);
      }
    }
  }
  handleSelect = (key, value) => this.setState({ [key]: value });
  handleSelectStatus = key => {
    const { lateStatusType, lateStatusTypeAll } = this.state;
    if (key === 'ALL') {
      let items = lateStatusType;
      if (!lateStatusTypeAll) {
        items = lateStatusType.map(item => {
          item.status = true;
          return item;
        });
      } else {
        items = lateStatusType.map(item => {
          item.status = false;
          return item;
        });
      }
      this.setState({ lateStatusType: items, lateStatusTypeAll: !lateStatusTypeAll });
    } else {
      const items = lateStatusType.map((item, i) => {
        if (i === key) {item.status = !item.status;}
        return item;
      });
      this.setState({ lateStatusType : items, lateStatusTypeAll: false });
    }
  }
  handleCheckAll = selectStatus => {
    if (
      selectStatus.receivedOrder &&
      selectStatus.picked &&
      selectStatus.packed &&
      selectStatus.readyToShip &&
      selectStatus.inTransit &&
      selectStatus.delivered && selectStatus.undelivered
    ) {
      return true;
    }
    return false;
  }
  handleSearch = (current, isSch) => {
    this.setState({ isLoading: true });
    if (isSch) {this.setState({ current, typeSort: '', column: '' });}
    const { client, clientProps, deliveryZone, orderFromDate, orderToDate, tracking, lateStatusType, deliveryDate, deliveryFromDate, deliveryToDate, typeSort, column } = this.state;
    const lateItem = lateStatusType.map(item => {
      if (item.status === true) {
        return item.value;
      }
      return null;
    }).filter(item => item).join();
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      orderDatefrom: orderFromDate,
      orderDateto: orderToDate,
      deliverydatefrom: deliveryFromDate,
      deliverydateto: deliveryToDate,
      deliveryZone: deliveryZone ? deliveryZone.value : '',
      latestStatus: lateItem,
      dayType: deliveryDate ? deliveryDate.value : '',
      tracking: tracking,
      orderBy: typeSort ? column : '',
      orderType: typeSort,
      pageNumber: current,
      pageSize: commonConstant.pagination.itemsCountPerPage,
    };

    this.props.dispatch(fetchOrderTrackingReport(params));
  }
  clsSearch = () => {
    const clsLateStatus = this.state.lateStatusType.map(item => {
      item.status = false;
      return item;
    });
    const { clientProps } = this.state;
    this.getInit(clientProps);
    this.setState({
      client: '',
      orderFromDate: '',
      orderToDate: '',
      deliveryFromDate: '',
      deliveryToDate: '',
      deliveryZone: '',
      lateItem: '',
      deliveryDate: '',
      tracking: '',
      column: '',
      typeSort: '',
      lateStatusType: clsLateStatus,
      lateStatusTypeAll: false,
    });
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  statusColor = status => {
    switch (status.toLowerCase()) {
      case 'ready for ship':
        return 'ready-for-ship';
      case 'picked':
        return 'picked';
      case 'packed':
        return 'packed';
      case 'in transit':
        return 'in-transit';
      case 'received order':
        return 'received-order';
      case 'delivered':
        return 'delivered';
      case 'undelivered':
        return 'undelivered';
      default:
        return;
    }
  };
  toggleOrderDetail = orderDetails => {
    document.body.style.overflow = 'hidden';
    this.setState({ orderDetails: [orderDetails] });
  };
  toggleClsOrderDetail = () => {
    document.body.style.overflow = 'auto';
    this.setState({ orderDetails: [] });
  };
  exportWorkBook = type => {
    this.setState({ isLoading: true });
    const { client, clientProps, deliveryZone, orderFromDate, orderToDate, tracking, lateStatusType, deliveryDate, deliveryFromDate, deliveryToDate } = this.state;
    const lateItem = lateStatusType.map(item => {
      if (item.status === true) {
        return item.value;
      }
      return null;
    }).filter(item => item).join();
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      orderDatefrom: orderFromDate,
      orderDateto: orderToDate,
      deliverydatefrom: deliveryFromDate,
      deliverydateto: deliveryToDate,
      deliveryZone: deliveryZone ? deliveryZone.value : '',
      latestStatus: lateItem,
      dayType: deliveryDate ? deliveryDate.value : '',
      tracking: tracking,
    };

    this.props.dispatch(fetchOrderTrackingExport(params, type));
  }

  checkNull = data => data ? data : '-';

  render() {
    const {
      client,
      deliveryZone,
      orderFromDate,
      orderToDate,
      orderTracking,
      orderDetails,
      deliveryDate,
      clientType,
      deliveryZoneType,
      lateStatusType,
      dayType,
      lateStatusTypeAll,
      current,
      column,
      isLoading,
      total,
    } = this.state;

    if (clientType.length < 1 || lateStatusType.length < 1) {
      return <Loading />;
    }
    if (deliveryZoneType === 'LOADING' || dayType === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="OrderTrackingReport">
        {isLoading ? <Loading /> : null}
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={this.handleTitle()} hr>
            <div className="row mb-2">
              <div className="col-4">
                <Select
                  placeholder="Client"
                  closeMenuOnSelect={false}
                  isMulti
                  value={client}
                  options={clientType}
                  onChange={this.selectClient}
                  onInputChange={this.selectClientOnblue.bind(this)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Delivery Zone"
                  items={deliveryZoneType}
                  selected={deliveryZone}
                  disabled={Array.isArray(deliveryZoneType)}
                  handleSelect={value => this.handleSelect('deliveryZone', value)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Delivery Date"
                  items={dayType}
                  selected={deliveryDate}
                  disabled={Array.isArray(dayType)}
                  handleSelect={value => this.handleSelect('deliveryDate', value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="box-date">
                  <span className="title">Order date</span>
                  <DatePicker
                    selectYear
                    selectDate={orderFromDate}
                    handleSelectDate={value => this.handleSelect('orderFromDate', value)}
                    placeholder="from"
                  />
                  <DatePicker
                    selectYear
                    selectDate={orderToDate}
                    handleSelectDate={value => this.handleSelect('orderToDate', value)}
                    placeholder="to"
                    disabledDays={orderFromDate}
                    disabled={orderFromDate ? false : true}
                  />
                </div>
              </div>
            </div>
            <div className="box-status">
              <span className="title">Lastest status</span>
              <div className="box-checkbox">
                <Checkbox label="All" value="All" checked={lateStatusTypeAll} handleSelect={() => this.handleSelectStatus('ALL')} />
                {
                  lateStatusType ? lateStatusType.map((item,i) => (
                    <Checkbox key={i} label={item.value} value={item.value} checked={item.status} handleSelect={() => this.handleSelectStatus(i)} />
                  )) : null
                }
              </div>
            </div>
            <hr className="hr" />
            <div className="box-btn">
              <Button className="btn-light" onClick={this.clsSearch}>Clear</Button>
              <Button className="btn-primary" onClick={() => this.handleSearch(1, true)}>
                Browse
                <span className="icon icon-search" />
              </Button>
            </div>
          </WhiteBox>

          {
            Object.keys(total).length ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportWorkBook('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportWorkBook('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                  </div>
                  <div className="overall-detail">
                    <div className="o-item">
                      <span>Received Order</span>
                      <p>{Object.keys(total).length ? parseInt(total.receivedOrder.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.receivedOrder.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                    <div className="o-item">
                      <span>Picked</span>
                      <p>{Object.keys(total).length ? parseInt(total.picked.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.picked.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                    <div className="o-item">
                      <span>Packed</span>
                      <p>{Object.keys(total).length ? parseInt(total.packed.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.packed.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                    <div className="o-item">
                      <span>Ready For Ship</span>
                      <p>{Object.keys(total).length ? parseInt(total.readyToShip.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.readyToShip.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                    <div className="o-item">
                      <span>In-Transit</span>
                      <p>{Object.keys(total).length ? parseInt(total.inTransit.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.inTransit.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                    <div className="o-item">
                      <span>Delivered</span>
                      <p>{Object.keys(total).length ? parseInt(total.delivered.totalOrder).toLocaleString() : 0}</p>
                      <small>{Object.keys(total).length ? parseInt(total.delivered.totalUnit).toLocaleString() : 0} Unit.</small>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Order Number" name="orderNumber" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Number" name="customerNo" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Name" name="customerName" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Delivery Zone" name="zone" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="QTY" name="qty" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order Date/Time" name="orderDateTime" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Latest Staus" name="latestStaus" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Object.keys(orderTracking).length && orderTracking.data && orderTracking.data.length ? orderTracking.data.map((item, key) => (
                          <tr key={key} className="pointer" onClick={() => this.toggleOrderDetail(item)}>
                            <td>{item.orderNumber}</td>
                            <td>{item.customerNo}</td>
                            <td>{item.customerName ? item.customerName : '-'}</td>
                            <td>{item.zone ? item.zone : '-'}</td>
                            <td>{item.qty}</td>
                            <td>{formatDate(item.orderDateTime)}</td>
                            <td className={this.statusColor(item.latestStaus)}>{item.latestStaus}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>

                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                    Showing {current === 1 ? current : (current - 1) * commonConstant.pagination.itemsCountPerPage}{' '}
                    to {current === 1 ? commonConstant.pagination.itemsCountPerPage : (current - 1) * commonConstant.pagination.itemsCountPerPage + commonConstant.pagination.itemsCountPerPage}{' '}
                    of {Object.keys(orderTracking).length ? orderTracking.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={commonConstant.pagination.itemsCountPerPage}
                      pageRangeDisplayed={commonConstant.pagination.pageRangeDisplayed}
                      totalItemsCount={Object.keys(orderTracking).length ? orderTracking.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>

        {/* Modal */}
        {
          orderDetails.length > 0 ?
            <div className="modal-order-details">
              <div className="yas-order-detail container">
                <div className="head-detail">
                  <div className="row order-header">
                    <div className="col-8">
                      <span>Order number</span>
                      <h1>{orderDetails[0].orderNumber}</h1>
                    </div>
                    <div className="col text-right">
                      <span>Status</span>
                      <h1 className={this.statusColor(orderDetails[0].latestStaus)}>{orderDetails[0].latestStaus}</h1>
                    </div>
                  </div>
                  <div className="row order-detail">
                    <div className="col-3">
                      <span>Customer Number</span>
                      <p>{this.checkNull(orderDetails[0].customerNo)}</p>
                    </div>
                    <div className="col-3">
                      <span>Customer  Name</span>
                      <p>{this.checkNull(orderDetails[0].customerName)}</p>
                    </div>
                    <div className="col-3">
                      <span>Address</span>
                      <p>{this.checkNull(orderDetails[0].address)}</p>
                    </div>
                    <div className="col-3">
                      <span>Zone</span>
                      <p>{this.checkNull(orderDetails[0].zone)}</p>
                    </div>
                    <div className="col-3">
                      <span>QTY</span>
                      <p>{this.checkNull(orderDetails[0].qty)}</p>
                    </div>
                    <div className="col-3">
                      <span>AWB No.</span>
                      <p>{this.checkNull(orderDetails[0].awb)}</p>
                    </div>
                  </div>
                </div>
                <div className="body-detail yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <th>Picked</th>
                        <th>Packed</th>
                        <th>Ready for ship</th>
                        <th>In-transit</th>
                        <th>Delivered</th>
                        <th>Undelivered</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        orderDetails ? orderDetails.map((item, key) => (
                          <tr key={key}>
                            <td>{formatDate(item.picked)}</td>
                            <td>{formatDate(item.packed)}</td>
                            <td>{formatDate(item.readyForShip)}</td>
                            <td>{formatDate(item.inTransit)}</td>
                            <td>{formatDate(item.delivered)}</td>
                            <td>{formatDate(item.unDelivered)}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="yas-modal-footer close">
                  <button className="btn btn-light" onClick={this.toggleClsOrderDetail}>ปิดหน้าต่าง <i className="menu-icon icon-close" /></button>
                </div>
              </div>
            </div> : null
        }
        {/* Modal */}
        <RefreshToken />
      </div>
    );
  }
}

OrderTrackingReport.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getOrderTrackingReport: PropTypes.object,
  getOrderTrackingExport: PropTypes.object,
  getClientType: PropTypes.object,
  getDeliveryZoneType: PropTypes.object,
  getDayType: PropTypes.object,
  getLateStatusType: PropTypes.object,
  getProfile: PropTypes.object,
};

const mapStateToProps = state => ({
  getProfile: state.getProfile,
  getOrderTrackingReport: state.getOrderTrackingReport.data,
  getOrderTrackingExport: state.getOrderTrackingExport,
  getClientType: state.getClientType.data,
  getDeliveryZoneType: state.getDeliveryZoneType.data,
  getDayType: state.getDayType.data,
  getLateStatusType: state.getLateStatusType.data,
});

export default connect(mapStateToProps)(OrderTrackingReport);
