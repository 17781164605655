export default {
  'th': {
    'forgotPassword': 'FORGOT PASSWORD',
    'description': 'Please type your password. The system will send a new password to your email.',
    'email': 'E-mail',
    'send': 'SEND',
    'backToLogin': 'BACK TO LOGIN',
  },
  'en': {
    'forgotPassword': 'FORGOT PASSWORD',
    'description': 'Please type your password. The system will send a new password to your email.',
    'email': 'E-mail',
    'send': 'SEND',
    'backToLogin': 'BACK TO LOGIN',
  },
};
