import commonConstant from '../../common/commonConstant';
import { getCookieYASToken } from '../../common/commonFunction';
import { history } from '../../helpers';

export const fetchGetProfile = () => (
  async dispatch => {
    try {
      if (getCookieYASToken()) {
        const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/me?access_token=${getCookieYASToken()}`);
        const data = await res.json();

        if (res.status === 200 && (data.userRoles && data.userRoles.length > 0)) {
          const clients = data.userRoles.length > 0 ? data.userRoles.map(item => ({ 
            value: item.role.organization.code,
            text: item.role.organization.name,
          })) : [];
          const menus = data.userRoles.length > 0 ? [...new Set([].concat(...data.userRoles.map(item => item.role.roleFeatures.map(i => i.feature.code))))] : [];
          const roles = data.userRoles.length > 0 ? [...new Set([].concat(...data.userRoles.map(item => item.role.code)))] : [];

          return dispatch({
            type: commonConstant.GET_PROFILE_SUCCESS,
            data,
            clients,
            menus,
            roles,
            status: res.status,
            dateTime: new Date(),
          });
        } else if (res.status === 401) {
          history.push(commonConstant.pathLogout);
          return dispatch({
            type: commonConstant.GET_PROFILE_FAILURE,
            data: null,
            status: res.status ? res.status : res,
            dateTime: new Date(),
          });
        } else if (res.status === 500 || res.status === 502) {
          return history.push(commonConstant.pathServerError);
        }
        history.push(commonConstant.pathOop);
        return dispatch({
          type: commonConstant.GET_PROFILE_FAILURE,
          data: null,
          status: res.status ? res.status : res,
          dateTime: new Date(),
        });
      }
      return history.push(commonConstant.pathLogout);
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        type: commonConstant.GET_PROFILE_FAILURE,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);
