import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table } from 'reactstrap';

import './UserManagenemt.scss';
import { history } from '../../helpers';
import { fetchUserListAdd, fetchExportRole } from '../../actions';

const UserManagenemtAdd = () => {
  const [usersFile, setUsersFile] = useState({});
  const [userList, setUserList] = useState({ data: [], status: null });
  const getUserListAdd = useSelector(({ getUserListAdd }) => getUserListAdd);
  const getExportRole = useSelector(({ getExportRole }) => getExportRole);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getUserListAdd && getUserListAdd.status === 422 && usersFile.name) {
      const data = Array.isArray(getUserListAdd.data.error.details) ? getUserListAdd.data.error.details : [getUserListAdd.data.error.details];
      setUserList({ data, status: getUserListAdd.status });
    } else if (getUserListAdd && getUserListAdd.status === 200 && usersFile.name) {
      setUserList({ data: getUserListAdd.data.data, status: getUserListAdd.status });
    }
  }, [getUserListAdd]);
  useEffect(() => {
    if (getExportRole && usersFile.name) {
      document.getElementById('upload-users-lists').value = '';
    }
  }, [getExportRole]);

  const addUserList = e => {
    if (e.target.files[0]){
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      
      setUsersFile(...e.target.files);
      dispatch(fetchUserListAdd(formData));
    }
  };
  const goBack = () => userList.status === 200 ? history.push('/user-management') : null;
  const expRole = () => {
    dispatch(fetchExportRole());
  };

  return (
    <div className="UserManagenemtAdd">
      <div className="template-with-sidebar-navbar">
        <div className="template-white-box mb-2">
          <label htmlFor="upload-users-lists" className="upload-box">
            <i className="icon-upload" /> Upload
          </label>
          <input onChange={addUserList} id="upload-users-lists" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
        </div>
        <div className="example-upload mb-4">
          Download Example File : 
          <a href="/files/user_example_import.xlsx"><span>Users-Example</span></a>
          <span onClick={expRole}>Roles-List</span>
          <a href="/files/dashboard_role.xlsx"><span>Dashboard-Role</span></a>
        </div>
        {
          userList.data.length > 0 ?
            <>
              <div className="template-white-box yas-grid mb-4">
                <div className="h4">{usersFile.name ? usersFile.name : 'User list file'}</div>
                <Table className="tb-verify">
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>User Type</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Password</th>
                      <th>Fullname</th>
                      <th>Force change password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userList.data.map((item, key) => (
                        <tr key={key}>
                          <td className={item.client ? '' : 'invalid'}>{item.clientValue}</td>
                          <td className={item.userType ? '' : 'invalid'}>{item.userTypeValue}</td>
                          <td className={item.role ? '' : 'invalid'}>{item.roleValue}</td>
                          <td className={item.email ? '' : 'invalid'}>{item.emailValue}</td>
                          <td className={item.password ? '' : 'invalid'}>{item.passwordValue}</td>
                          <td className={item.fullname ? '' : 'invalid'}>{item.fullnameValue}</td>
                          <td className={item.forceChangePassword ? '' : 'invalid'}>{item.forceChangePasswordValue}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
              <div className="text-right">
                <Button onClick={goBack} color="primary" disabled={userList.status !== 200}>ยืนยัน</Button>
              </div>
            </> : null
        }
      </div>
    </div>
  );
};

export default UserManagenemtAdd;
