import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { fetchOrganizations, fetchUserType, fetchOrganizationsRole, fetchLocations, fetchUsersListCntSch, fetchUsersListCnt, fetchUsersSearch } from '../../actions';
import { history, WhiteBox, Dropdowns, InputWithIcon } from '../../helpers';
import common from '../../common/commonConstant';

const UserManagenemt = () => {
  const [clientType, setClientType] = useState([]);
  const [userType, setUserType] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const [locationType, setLocation] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [usersListCnt, setUsersListCnt] = useState(0);
  const [current, setCurrent] = useState(1);
  const [model, setModel] = useState({
    search: '',
    client: '',
    user: '',
    role: '',
    location: '',
    limit: common.pagination.itemsCountPerPage,
    skip: 0,
  });
  const getOrganizations = useSelector(({ getOrganizations }) => getOrganizations.data);
  const getUserType = useSelector(({ getUserType }) => getUserType.data);
  const getOrganizationsRole = useSelector(({ getOrganizationsRole }) => getOrganizationsRole.data);
  const getLocations = useSelector(({ getLocations }) => getLocations.data);
  const getUsersList = useSelector(({ getUsersList }) => getUsersList.data);
  const getUsersSearch = useSelector(({ getUsersSearch }) => getUsersSearch.data);
  const getUsersListCnt = useSelector(({ getUsersListCnt }) => getUsersListCnt.data);
  const getUsersListCntSch = useSelector(({ getUsersListCntSch }) => getUsersListCntSch.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current === 1) {
      const params = {
        limit: common.pagination.itemsCountPerPage, 
        skip: 0,
      };
      dispatch(fetchUsersSearch(params));
      dispatch(fetchUsersListCnt());
    }
  }, [current]);
  useEffect(() => {
    if (getOrganizations) {
      const items = getOrganizations.map(item => {
        return { text: item.name, value: item.organizationId };
      });
      setClientType(items);
    }
    else { dispatch(fetchOrganizations()); }
  }, [getOrganizations]);
  useEffect(() => {
    if (getUserType) {
      const items = getUserType.map(item => {
        return { text: item.name, value: item.userTypeId };
      });
      setUserType(items);
    } 
    else { dispatch(fetchUserType()); }
  }, [getUserType]);
  useEffect(() => {
    if (getOrganizationsRole) {
      const items = getOrganizationsRole.map(item => {
        return { text: item.name, value: item.organizationRoleId };
      });
      setRoleType(items);
    } 
    else { dispatch(fetchOrganizationsRole()); }
  }, [getOrganizationsRole]);
  useEffect(() => {
    if (getLocations) {
      const items = getLocations.map(item => {
        return { text: item.name, value: item.locationId };
      });
      setLocation(items);
    }
    else { dispatch(fetchLocations()); }
  }, [getLocations]);
  useEffect(() => {
    if (getUsersList) {
      setUsersList(getUsersList);
    }
  }, [getUsersList]);
  useEffect(() => {
    if (getUsersSearch) {
      setUsersList(getUsersSearch);
    }
  }, [getUsersSearch]);
  useEffect(() => {
    if (getUsersListCnt) {
      setUsersListCnt(getUsersListCnt.count);
    }
  }, [getUsersListCnt]);
  useEffect(() => {
    if (getUsersListCntSch) {
      setUsersListCnt(getUsersListCntSch.count);
    }
  }, [getUsersListCntSch]);

  const handleSelect = (key, value) => setModel(Object.assign({ ...model }, { [key]: value }));
  const handleSearch = page => {
    setCurrent(page);
    const { client, user, search } = model;
    const paramsCnt = {
      text: search,
      organizationId: client.value ? client.value : null,
      userTypeId: user.value ? user.value : null,
      regionId: null,
      zoneId: null,
      branchId: null,
    };
    const params = {
      text: search,
      organizationId: client.value ? client.value : null,
      userTypeId: user.value ? user.value : null,
      regionId: null,
      zoneId: null,
      branchId: null,
      limit: common.pagination.itemsCountPerPage,
      skip: page === 1 ? 0 : (page - 1) * common.pagination.itemsCountPerPage,
    };

    dispatch(fetchUsersListCntSch(paramsCnt));
    dispatch(fetchUsersSearch(params));
  };
  const pageChange = curr => {
    handleSearch(curr);
  };

  const handleTitle = () => (
    <div className="box-title">
      <span className="title">ค้นหา</span>
      <InputWithIcon
        onChange={e => handleSelect('search', e.target.value)}
        value={model.tracking}
        icon="icon-search"
        placeholder="User name lastname.."
      />
      <div className="text-right w-100">
        <Button className="btn-primary" onClick={() => handleSearch(1)}>
          Browse <span className="icon icon-search" />
        </Button>
      </div>
    </div>
  );
  const viewUserProfile = code => {
    return history.push(`./user-management/${code}`);
  };

  return <div className="RoleManagement">
    <div className="template-with-sidebar-navbar">
      <WhiteBox title={handleTitle()} hr>
        <div className="row mb-2">
          <div className="col-3">
            <Dropdowns
              placeholder="เลือก Client/Warehouse"
              items={userType}
              selected={model.user}
              disabled={true}
              handleSelect={value => handleSelect('user', value)}
            />
          </div>
          <div className="col-3">
            <Dropdowns
              placeholder="เลือก Organization"
              items={clientType}
              selected={model.client}
              disabled={true}
              handleSelect={value => handleSelect('client', value)}
            />
          </div>
          <div className="col-3">
            <Dropdowns
              placeholder="เลือก Location"
              items={locationType}
              selected={model.location}
              disabled={true}
              handleSelect={value => handleSelect('location', value)}
            />
          </div>
          <div className="col-3">
            <Dropdowns
              placeholder="เลือก Role"
              items={roleType}
              selected={model.role}
              disabled={true}
              handleSelect={value => handleSelect('role', value)}
            />
          </div>
        </div>
      </WhiteBox>
      
      <div className="overall-details">
        <div className="overall">
          <div className="overall-head">
            <div className="title">ทั้งหมด {usersListCnt} role</div>
            <Link to={common.pathUserManagementAdd} className="export success mr-3">สร้าง Role ใหม่ <i className="menu-icon icon-plus" /></Link>
            {/* <div onClick={() => this.exportWorkBook('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
              <div onClick={() => this.exportWorkBook('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div> */}
          </div>
        </div>
        <div className="template-white-box yas-grid">
          <Table>
            <thead>
              <tr>
                <th>Name Lastname</th>
                <th>Client/Warehouse</th>
                <th>Organization</th>
                <th>Location</th>
                <th>Roles</th>
                {/* <th>Province</th>
                <th>Zone</th>
                <th>Branch</th> */}
              </tr>
            </thead>
            <tbody>
              {
                usersList.map((item, key) => (
                  <tr key={key} onClick={() => viewUserProfile(item.userId)} className="pointer" >
                    <td>{item.name}</td>
                    <td>{item.userType ? item.userType.name : '-'}</td>
                    <td>{item.organization ? item.organization.name : '-'}</td>
                    <td>{
                      item.userLocations && item.userLocations.length > 0 && item.userLocations[0].region
                        ? item.userLocations[0].region.regionName 
                        : '-'
                    }</td>
                    <td>{
                      item.userRoles && item.userRoles.length > 0 
                        ? item.userRoles[0].role.name 
                        : '-'
                    }</td>
                    {/* <td>BKK</td>
                    <td>Rama 4</td>
                    <td>Bangkok</td> */}
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </div>
        <div className="text-div-right">
          <Pagination
            activePage={current}
            itemsCountPerPage={common.pagination.itemsCountPerPage}
            pageRangeDisplayed={common.pagination.pageRangeDisplayed}
            totalItemsCount={usersListCnt}
            onChange={pageChange}
          />
        </div>
      </div>
    </div>
  </div>;
};

export default UserManagenemt;
