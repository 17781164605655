import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { fetchOrganizationsRole } from '../../actions';
import { history } from '../../helpers';

import './RoleManagement.scss';

const RoleManagementView = ({ match }) => {
  const [roleModel, setRoleModel] = useState({
    name: '',
    organization: { name: '' },
    userType: { name: '' },
    roleTemplate: { name: '', roleFeatureTemplates: [] },
  });
  const [params, setParams] = useState({});
  const getOrganizationsRole = useSelector(({ getOrganizationsRole }) => getOrganizationsRole.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getOrganizationsRole && Object.keys(match.params).length > 0) {
      const items = getOrganizationsRole.filter(item => item.organizationRoleId === match.params.code);
      
      if (items.length) {
        setParams(match.params);
        setRoleModel(...items);
      } else {
        setParams(match.params);
        dispatch(fetchOrganizationsRole({ 'where': { 'organizationRoleId': match.params.code } }));
      }
    } else if (match && Object.keys(match.params).length > 0) {
      setParams(match.params);
      dispatch(fetchOrganizationsRole({ 'where': { 'organizationRoleId': match.params.code } }));
    }
  }, [match, getOrganizationsRole]);


  return <div className="RoleManagement">
    <div className="template-with-sidebar-navbar">
      <div className="template-white-box  mb-4">
        <div className="title-white-box mb-3">
          <div className="title-white-box-r">View role detail</div>
          <div className="title-white-box-l"><Link to={`./${params.code}/edit`}>Edit</Link></div>
        </div>
        <div className="last-title mb-3">รายละเอียด Role</div>
        <div className="row mb-3">
          <div className="col-2">ชื่อ Role</div>
          <div className="col-5">{roleModel.name}</div>
        </div>
        <div className="row mb-3">
          <div className="col-2">องค์กร</div>
          <div className="col-5">{roleModel.organization ? roleModel.organization.name : '-'}</div>
        </div>
        <div className="row mb-3">
          <div className="col-2">รูปแบบ</div>
          <div className="col">{roleModel.userType ? roleModel.userType.name : '-'}</div>
        </div>
        <div className="last-title mb-3">เลือกประเภท Role</div>
        <div className="row mb-3">
          <div className="col-2">Role</div>
          <div className="col-5">{roleModel.roleTemplate ? roleModel.roleTemplate.name : '-'}</div>
        </div>
        <div className="items-roles row mb-3">
          {
            roleModel.roleFeatures ? roleModel.roleFeatures.map((item, key) => (
              <div key={key} className="col-3">
                <i className="icon-dashboard" /> {item.feature.name}
              </div>
            )) : null
          }
        </div>
      </div>
    </div>
  </div>;
};

RoleManagementView.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organId: PropTypes.string,
      localId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default RoleManagementView;
