export default {
  'th': {
    'title': 'CHANGE PASSWORD',
    'description': 'Plase enter a new password',
    'oldPassword': 'Old password',
    'newPassword': 'New Password',
    'confirmPassword': 'Confirm new password',
    'submit': 'SUBMIT',
  },
  'en': {
    'title': 'CHANGE PASSWORD',
    'description': 'Plase enter a new password',
    'oldPassword': 'Old password',
    'newPassword': 'New Password',
    'confirmPassword': 'Confirm new password',
    'submit': 'SUBMIT',
  },
};
