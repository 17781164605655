import React, { Component, createRef } from 'react';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';

import './DatePicker.scss';

import { Dropdowns, dateDDMMYYFormat } from '../../helpers';

const MONTHS = [
  { 'text': 'Jan', 'value': 0 },
  { 'text': 'Feb', 'value': 1 },
  { 'text': 'Mar', 'value': 2 },
  { 'text': 'Apr', 'value': 3 },
  { 'text': 'May', 'value': 4 },
  { 'text': 'Jun', 'value': 5 },
  { 'text': 'Jul', 'value': 6 },
  { 'text': 'Aug', 'value': 7 },
  { 'text': 'Sep', 'value': 8 },
  { 'text': 'Oct', 'value': 9 },
  { 'text': 'Nov', 'value': 10 },
  { 'text': 'Dec', 'value': 11 },
];

export default class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: MONTHS[new Date().getMonth()],
      optionYear: this.handleOptionYear(),
      year: { text: new Date().getFullYear().toString(), value: new Date().getFullYear() },
      showCalendar: false,
    };
    this.carlendar = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.carlendar.current && !this.carlendar.current.contains(event.target)) {
      return this.setState({ 'showCalendar': false });
    }
    return;
  };

  handleOptionYear = () => {
    const optionYear = [];
    let year = 2018;

    while (year <= new Date().getFullYear()) {
      optionYear.push({ text: year.toString(), value: year });
      year += 1;
    }
    return optionYear;
  }

  handleSelect = (key, value) => this.setState({ [key]: value });

  handleSelectMonthYear = (key, value) => this.setState({ [key]: value });

  handleShowSelectMonthYear = () => {
    const { month, optionYear, year } = this.state;
    const { selectYear } = this.props;

    return (
      <div className="box-month-year">
        <Dropdowns
          placeholder="Month"
          items={MONTHS}
          selected={month}
          disabled={true}
          handleSelect={value => this.handleSelectMonthYear('month', value)}
        />
        {
          selectYear ?
            <Dropdowns
              placeholder="Year"
              items={optionYear}
              selected={year}
              disabled={true}
              handleSelect={value => this.handleSelectMonthYear('year', value)}
            />
            : null
        }
      </div>
    );
  }

  handleSelect = (day, modifiers) => {
    if (modifiers.disabled) { return; }
    this.props.handleSelectDate(day);
    return this.setState({ 'showCalendar': false });
  }

  handleShowCalendar = () => {
    const { disabled } = this.props;

    if (!disabled) {
      return this.setState({ 'showCalendar': !this.state.showCalendar });
    }
    return;
  }

  render() {
    const { month, year, showCalendar } = this.state;
    const { selectDate, placeholder, disabledDays } = this.props;

    return (
      <div className="DatePicker" ref={this.carlendar}>
        <div className="box-input" onClick={this.handleShowCalendar}>
          <div className={`input${!selectDate ? ' placeholder' : ''}`}>
            {selectDate ? dateDDMMYYFormat(selectDate) : placeholder}
          </div>
          <div className="box-icon">
            <span className="icon icon-event" />
          </div>
        </div>
        <div className={`box-calendar${showCalendar ? ' active' : ''}`}>
          <DayPicker
            fromMonth={new Date(2019, 10)}
            toMonth={new Date(new Date().getFullYear(), new Date().getMonth())}
            month={new Date(new Date(year.value, month.value))}
            captionElement={props => this.handleShowSelectMonthYear(props)}
            canChangeMonth={false}
            onDayClick={this.handleSelect}
            disabledDays={disabledDays ? { 'before': new Date(disabledDays) } : null}
          />
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  'selectYear': PropTypes.bool,
  'selectDate': PropTypes.any,
  'handleSelectDate': PropTypes.func.isRequired,
  'placeholder': PropTypes.string,
  'disabledDays': PropTypes.any,
  'disabled': PropTypes.bool,
};
