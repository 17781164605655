import React from 'react';
import Chart from 'react-apexcharts';
const colors = ['#34D1BF', '#FF715B', '#FED70D'];

const CardPieAndPercent = ({ label, series }) => {
  const pieOption = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          background: 'transparent',
          labels: {
            show: true,
            value: {
              show: true,
              fontSize: '14px',
            },
            total: {
              show: true,
              showAlways: true,
              label: label ? `${label}%` : '0%',
              fontSize: '24px',
              formatter: () => 'Complete',
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    colors,
  };

  return <Chart options={pieOption} series={series} type="donut" width={'100%'} height="140" />;
};

export default CardPieAndPercent;
