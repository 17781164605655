import commonConstant from '../../common/commonConstant';

export const getTracking = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_TRACKING_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_TRACKING_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
