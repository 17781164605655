import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { menus } from '../../common/commonConstant';
import Client from './Client';
import OPView from './OPView';
import HighLevel from './HighLevel';

import './Dashboard.scss';

const Dashboard = () => {
  const [isDbo, setIsDbo] = useState();
  const [roles, setRoles] = useState([]);
  const getProfile = useSelector(({ getProfile }) => getProfile);

  useEffect(() => {
    if (getProfile && getProfile.status === 200) {
      const { roles, data } = getProfile;
      if (roles.find(item => item === menus.SUPER_ADMIN)) {
        setIsDbo('HIGH_LEVEL');
      } else {
        setIsDbo(data.dashboardRoleCode);
      }
      setRoles(roles);
    }
  }, [getProfile]);

  const handleDashboard = isDbo => {
    setIsDbo(isDbo);
    switchBoard();
  };

  const switchBoard = () => {
    switch (isDbo) {
      case 'OPERATION':
        return <OPView />;
      case 'HIGH_LEVEL':
        return <HighLevel />;
      case 'CLIENT':
        return <Client />;
      default:
        return <p className="text-center"> Dashboard </p>;
    }
  };

  return <div className="Dashboard">
    {
      roles.find(item => item === menus.SUPER_ADMIN) ?
        <div className="tab-dashboard">
          <div className="item-tab" onClick={() => handleDashboard('CLIENT')}>Client</div>
          <div className="item-tab" onClick={() => handleDashboard('OPERATION')}>OP View</div>
          <div className="item-tab" onClick={() => handleDashboard('HIGH_LEVEL')}>High Level</div>
        </div> : null
    }
    {
      switchBoard()
    }
  </div>;
};

export default Dashboard;
