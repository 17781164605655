import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import commonConstant from '../../common/commonConstant';
import { getCookieYASToken } from '../../common/commonFunction';
import { Loading } from '../../components';
import { fetchLogout } from '../../actions';
import { history } from '../../helpers';

class Logout extends Component {
  UNSAFE_componentWillMount() {
    if (getCookieYASToken()) {
      return this.props.dispatch(fetchLogout());
    }
    return history.push(commonConstant.pathLogin);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { logout } = this.props;

    if (nextProps.logout && nextProps.logout && logout) {
      return history.push(commonConstant.pathLogin);
    }
  }

  render() {
    return <Loading />;
  }
}

Logout.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'logout': PropTypes.shape({
    'status': PropTypes.number,
  }),
};

const mapStateToprops = ({ logout }) => ({ logout });

export default connect(mapStateToprops)(Logout);
