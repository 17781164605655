import commonConstant from '../../common/commonConstant';
import { createCookieYASToken, getCookieYASToken, deleteCookieYASToken } from '../../common/commonFunction';
import { history } from '../../helpers';

export const fetchLogin = formLogin => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/login`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          ...formLogin,
          'ttl': commonConstant.defaultTTLLogin,
        }),
      });
      const data = await res.json();
      
      if (res.status === 200 || res.status === 401) {
        createCookieYASToken(data.token);

        return dispatch({
          'type': commonConstant.LOGIN_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.LOGIN_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.LOGIN_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchLogout = () => (
  async dispatch => {
    try {
      if (getCookieYASToken()) {
        const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/logout?access_token=${getCookieYASToken()}`, {
          'headers': new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }),
          'method': 'POST',
        });

        if (res.status === 200 || res.status === 204 || res.status === 401) {
          deleteCookieYASToken();
          return dispatch({
            'type': commonConstant.LOGOUT_SUCCESS,
            'status': res.status,
            'dateTime': new Date(),
          });
        } else if (res.status === 500 || res.status === 502) {
          return history.push(commonConstant.pathServerError);
        }
        history.push(commonConstant.pathOop);
        return dispatch({
          'type': commonConstant.LOGOUT_FAILURE,
          'status': res.status ? res.status : res,
          'dateTime': new Date(),
        });
      }
      return history.push(commonConstant.pathLogin);
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.LOGOUT_FAILURE,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchRefreshToken = () => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/refresh-token?access_token=${getCookieYASToken()}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({ 'ttl': commonConstant.defaultTTLRefreshToken }),
      });

      if (res.status === 200) {
        createCookieYASToken(getCookieYASToken());
        return dispatch({
          'type': commonConstant.REFRESH_TOKEN_SUCCESS,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 401) {
        history.push(commonConstant.pathLogout);
        return dispatch({
          'type': commonConstant.REFRESH_TOKEN_FAILURE,
          'status': res.status ? res.status : res,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.REFRESH_TOKEN_FAILURE,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.REFRESH_TOKEN_FAILURE,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchForgotPassword = formForgotPassword => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/forgot-password`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({ ...formForgotPassword }),
      });

      if (res.status === 200) {
        return dispatch({
          'type': commonConstant.FORGOT_PASSWORD_SUCCESS,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 400) {
        return dispatch({
          'type': commonConstant.FORGOT_PASSWORD_FAILURE,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.FORGOT_PASSWORD_FAILURE,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.FORGOT_PASSWORD_FAILURE,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchChangePassword = formChangePassword => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasUserServiceAPI}/users/change-password?access_token=${getCookieYASToken()}`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({ ...formChangePassword }),
      });

      if (res.status === 200) {
        return dispatch({
          'type': commonConstant.CHANGE_PASSWORD_SUCCESS,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 400) {
        return dispatch({
          'type': commonConstant.CHANGE_PASSWORD_FAILURE,
          'status': res.status ? res.status : res,
          'dateTime': new Date(),
        });
      } else if (res.status === 401) {
        history.push(commonConstant.pathLogout);
        return dispatch({
          'type': commonConstant.CHANGE_PASSWORD_FAILURE,
          'status': res.status ? res.status : res,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.CHANGE_PASSWORD_FAILURE,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.CHANGE_PASSWORD_FAILURE,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
