export default {
  'th': {
    'addNewUser': 'Add new user',
    'home': 'Home',
    'addNew': 'Add new',
    'selectType': 'เลือก ประเภท User Client/warehouse',
    'type': 'เลือกประเภท',
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
    'cancel': 'ยกเลิก',
    'next': 'ถัดไป',
  },
  'en': {
    'addNewUser': 'Add new user',
    'home': 'Home',
    'addNew': 'Add new',
    'selectType': 'เลือก ประเภท User Client/warehouse',
    'type': 'เลือกประเภท',
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
    'cancel': 'ยกเลิก',
    'next': 'ถัดไป',
  },
};
