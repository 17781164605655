import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

import './ForceChangePassword.scss';
import content from './Language';

import commonConstant from '../../common/commonConstant';
import { getCookieLanguage } from '../../common/commonFunction';
import { setLanguage, fetchChangePassword } from '../../actions';
import { Loading } from '../../components';
import { InputWithIcon, validatePassword, history } from '../../helpers';
import { ChangePasswordSuccess } from '../../pages';

class ForceChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': false,
      'warning': null,
      'oldPassword': '',
      'newPassword': '',
      'confirmPassword': '',
      'redirectChangePasswordSuccess': false,
    };
    if (!getCookieLanguage()) {
      return history.push(commonConstant.pathLogin);
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { changePassword } = this.props;

    if (nextProps.changePassword && nextProps.changePassword !== changePassword) {
      if (nextProps.changePassword.status === 200) {
        return this.setState({ 'redirectChangePasswordSuccess': true, 'loading': false });
      } else if (nextProps.changePassword.status === 400) {
        return this.setState({ 'warning': 'รหัสผ่านเก่าไม่ถูกต้อง', 'loading': false });
      }
    }
  }

  handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (oldPassword && newPassword && confirmPassword) {
      if (oldPassword !== newPassword) {
        if (newPassword === confirmPassword) {
          if (validatePassword(newPassword)) {
            this.setState({ 'loading': true });
            return this.props.dispatch(fetchChangePassword({ oldPassword, newPassword }));
          }
          return this.setState({ 'warning': 'รหัสผ่านต้องมีจำนวนตัวอักษรระหว่าง 8-20 ตัวอักษร รวมถึง มีตัวอักษรพิมพ์เล็ก, พิมพ์ใหญ่ และตัวเลขอย่างน้อยหนึ่งตัวอักษร' });
        }
        return this.setState({ 'warning': 'รหัสผ่านใหม่ไม่ตรงกัน' });
      }
      return this.setState({ 'warning': 'รหัสผ่านเดิมและรหัสผ่านใหม่ตรงกัน' });
    }
  }

  handleEvent = (event, key) => this.setState({ [key]: event.target.value });

  render() {
    const { loading, oldPassword, newPassword, confirmPassword, warning, redirectChangePasswordSuccess } = this.state;
    const { stateLanguage } = this.props;

    if (!stateLanguage) {
      return <Loading />;
    } else if (redirectChangePasswordSuccess) {
      return <ChangePasswordSuccess />;
    }

    return (
      <div className="ForceChangePassword template-center">
        {loading ? <Loading /> : null}
        <form className="box-page">
          <img className="logo" src={commonConstant.yasLogo} alt="" />
          <div className="box-form">
            <div className="title">{content[stateLanguage]['title']}</div>
            <div className="description">{content[stateLanguage]['description']}</div>
            {warning ? <span className="warning-text">*{warning}</span> : null}
            <InputWithIcon
              onChange={event => this.handleEvent(event, 'oldPassword')}
              value={oldPassword}
              icon="icon-lock"
              placeholder={content[stateLanguage]['oldPassword']}
              type="password"
              textWarning={content[stateLanguage]['textWarning']}
              autoComplete
            />
            <InputWithIcon
              onChange={event => this.handleEvent(event, 'newPassword')}
              value={newPassword}
              icon="icon-lock"
              placeholder={content[stateLanguage]['newPassword']}
              type="password"
              textWarning={content[stateLanguage]['textWarning']}
              autoComplete
              maxLength="20"
            />
            <InputWithIcon
              onChange={event => this.handleEvent(event, 'confirmPassword')}
              value={confirmPassword}
              icon="icon-lock"
              placeholder={content[stateLanguage]['confirmPassword']}
              type="password"
              textWarning={content[stateLanguage]['textWarning']}
              onKeyPress={this.handleKeyPress}
              autoComplete
              maxLength="20"
            />
            <Button className="btn-primary btn-submit" onClick={this.handleSubmit}>
              {content[stateLanguage]['submit']}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ForceChangePassword.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
  'changePassword': PropTypes.shape({
    'status': PropTypes.number,
  }),
};

const mapStateToProps = ({ stateLanguage, changePassword }) => ({ 'stateLanguage': stateLanguage.data, changePassword });

export default connect(mapStateToProps)(ForceChangePassword);
