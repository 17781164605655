import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';
import Types from '../Types';

export const fetchTransportation = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/TransportReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_TRANSPORTATION_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
export const fetchTransportationNew = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/TransportReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': Types.transportNew.success,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': Types.transportNew.failure,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': Types.transportNew.failure,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Order No', 'AWB No', 'Customer Code', 'Customer Name', 'Address', 'Province', 'Zone', 'Period', 'Type', 'Courier', 'Total box', 'QTY', 'Weight',
    'Order Date', 'Shipped Date', 'Delivered Date', 'Recipient', 'Status Tracking', 'Remark']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].orderNo, data[i].awbNo, data[i].customerCode, data[i].customerName, data[i].customerAddress, data[i].customerProvince, data[i].zone, data[i].period,
      data[i].type, data[i].courier, data[i].totalBox, data[i].qty, data[i].weight,
      formatDate(data[i].orderDateTime), formatDate(data[i].shippedDateTime), formatDate(data[i].deliveredDateTime), data[i].recipient, data[i].statusTracking, data[i].remark]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'TransportExport.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'TransportExport.xlsx');
  }
};
const exportWorkBookNew = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Order No', 'AWB No', 'Customer Code', 'Customer Name', 'Address', 'Province', 'Zone', 'Period', 'Type', 'Courier', 'Total box', 'QTY', 'Weight',
    'Order Date', 'Shipped Date', 'Delivered Date', 'Recipient', 'Status Tracking', 'In Transit Status', 'Retry To Deliver Status', 'Remark']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].orderNo, data[i].awbNo, data[i].customerCode, data[i].customerName, data[i].customerAddress, data[i].customerProvince, data[i].zone, data[i].period,
      data[i].type, data[i].courier, data[i].totalBox, data[i].qty, data[i].weight,
      formatDate(data[i].orderDateTime), formatDate(data[i].shippedDateTime), formatDate(data[i].deliveredDateTime), data[i].recipient, data[i].statusTracking, data[i].delivery_sla, data[i].intransitstatus_des, data[i].remark]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'TransportExport.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'TransportExport.xlsx');
  }
};

export const fetchTransportationCsv = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/TransportExport',
          'param': params,
        }),
      });
      const data = await res.json();
      
      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, 'CSV');

        return dispatch({
          'type': commonConstant.GET_TRANSPORTATION_CSV_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_TRANSPORTATION_CSV_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_CSV_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_CSV_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchTransportationXls = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/TransportExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, 'XLSX');

        return dispatch({
          'type': commonConstant.GET_TRANSPORTATION_XLS_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_TRANSPORTATION_XLS_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_XLS_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORTATION_XLS_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchTransportationExportNew = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/TransportExport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBookNew(data.data, type);

        return dispatch({
          type: Types.transportExportNew.success,
          data: 'OK',
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          type: Types.transportExportNew.success,
          data: 'OK',
          status: 500,
          dateTime: new Date(),
        });
      }
      return dispatch({
        type: Types.transportExportNew.failure,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      return dispatch({
        type: Types.transportExportNew.failure,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);
