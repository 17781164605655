import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Button, Table } from 'reactstrap';
import {
  fetchTransportationNew,
  fetchClientType,
  fetchCarrierType,
  fetchWareHouseType,
  fetchTransportType,
  fetchTransportationExportNew,
  fetchGetProfile,
} from '../../actions';

import { WhiteBox, InputWithIcon, Dropdowns, DatePicker, ThSorting } from '../../helpers';
import common from '../../common/commonConstant';
import { formatDate } from '../../common/commonFunction';

import { Loading, RefreshToken } from '../../components';

class TransportNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientProps: '',
      client: '',
      carrier: null,
      orderDateFrom: null,
      orderDateTo: null,
      transportDateFrom: null,
      transportDateTo: null,
      dispatchDueDateFrom: null,
      dispatchDueDateTo: null,
      deliveryDueDateFrom: null,
      deliveryDueDateTo: null,
      deliveredDateFrom: null,
      deliveredDateTo: null,
      tracking: null,
      warehouse: null,
      status: null,
      transport: null,
      intransit: null,
      retryToDeliver: null,
      modalTransportDetail: null,
      clientType: [],
      warehouseType: [],
      carrierType: [],
      statusType: [],
      intransitType: common.intransitType,
      retryToDeliverType: common.retryToDeliverType,
      current: 1,
      typeSort: '',
      column: '',
      isLoading: false,
      roleList: [],
    };
    this.props.dispatch(fetchGetProfile());
  }
  componentDidMount() {
    const { clients, roles } = this.props.getProfile;
    const roleList = roles && roles.length > 0 ? roles : [];
    this.setState({ roleList });
    if (clients) { this.checkRole(clients); }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { clients, roles } = nextProps.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
    if (nextProps.getTransportation && nextProps.getTransportation !== this.props.getTransportation) {
      this.setState({ transport: nextProps.getTransportation, isLoading: false });
    }
    if (nextProps.getClientType && nextProps.getClientType !== this.props.getClientType) {
      const clientType = nextProps.getClientType.result.list.map(item => {
        item.label = item.text;
        return item;
      });
      this.setState({ clientType });
    }
    if (nextProps.getTransportationExport && nextProps.getTransportationExport !== this.props.getTransportationExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getTransporType && nextProps.getTransporType !== this.props.getTransporType) {
      this.setState({ statusType: nextProps.getTransporType.result.list });
    }
    if (nextProps.getWareHouseType && nextProps.getWareHouseType !== this.props.getWareHouseType) {
      this.setState({ warehouseType: nextProps.getWareHouseType.result.list });
    }
    if (nextProps.getCarrierType && nextProps.getCarrierType !== this.props.getCarrierType) {
      this.setState({ carrierType: nextProps.getCarrierType.result.list });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current } = this.state;

    this.setState({ column: await column, typeSort: await typeSort });
    this.handleSearch(current);
  }
  getInit = code => {
    this.setState({
      warehouseType: 'LOADING',
      carrierType: 'LOADING',
      statusType: 'LOADING',
    });
    this.props.dispatch(fetchWareHouseType(code));
    this.props.dispatch(fetchTransportType(code));
    this.props.dispatch(fetchCarrierType(code));
  }
  checkRole = clients => {
    this.setState({ clientProps: clients });
    const roleList = this.state.roleList;
    if (roleList[0] === 'SUPER_ADMIN') {
      this.props.dispatch(fetchClientType());
      this.getInit('');
    } else {
      const items = clients.map(item => {
        const data = {};
        data.label = item.text;
        data.value = item.value;
        return data;
      });

      this.setState({ clientType: items });
      this.getInit(clients.map(_ => _.value).join());
    }
  }
  handleTitle = () => (
    <div className="box-title">
      <span className="title">Search</span>
      <InputWithIcon
        onChange={event => this.handleSelect('tracking', event.target.value)}
        value={this.state.tracking}
        icon="icon-search"
        placeholder="AWB number"
      />
    </div>
  );
  selectClient = client => {
    this.setState({ client });
  };
  selectClientOnblue = (value, action) => {
    if (action.action === 'input-blur') {
      const { client } = this.state;
      const clientFilter = client && client && client.length > 0 ? client.map(item => item.value).join() : '';
      if (clientFilter) {
        this.getInit(clientFilter);
      }
    }
  }
  handleSelect = (key, value) => this.setState({ [key]: value });
  handleSearch = (current, isSch) => {
    this.setState({ isLoading: true });
    if (isSch) {this.setState({ current, typeSort: '', column: '' });}
    const { client, clientProps, carrier, status, orderDateFrom, orderDateTo, transportDateFrom, transportDateTo, tracking, warehouse, typeSort, column,
      dispatchDueDateFrom, dispatchDueDateTo, deliveryDueDateFrom, deliveryDueDateTo, deliveredDateFrom, deliveredDateTo, intransit, retryToDeliver } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      status: status ? status.value : '',
      carrier: carrier ? carrier.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      orderDateFrom: orderDateFrom,
      orderDateTo: orderDateTo,
      transportDateFrom: transportDateFrom,
      transportDateTo: transportDateTo,
      dispatchdatefrom: dispatchDueDateFrom,
      dispatchdateto: dispatchDueDateTo,
      deliverydatefrom: deliveryDueDateFrom,
      deliverydateto: deliveryDueDateTo,
      delivereddatefrom: deliveredDateFrom,
      delivereddateto: deliveredDateTo,
      slaStatus: intransit ? intransit.value : '',
      retrytodealiver: retryToDeliver ? retryToDeliver.value : '',
      tracking: tracking,
      pageNumber: current,
      orderBy: typeSort ? column : '',
      orderType: typeSort,
      pageSize: common.pagination.itemsCountPerPage,
    };
    this.props.dispatch(fetchTransportationNew(params));
  }
  clsSearch = () => {
    const { clientProps } = this.state;
    this.getInit(clientProps);
    this.setState({
      client: '',
      status: '',
      carrier: '',
      deliveryZone: '',
      orderDateFrom: '',
      orderDateTo: '',
      transportDateFrom: '',
      transportDateTo: '',
      dispatchDueDateFrom: '',
      dispatchDueDateTo: '',
      deliveryDueDateFrom: '',
      deliveryDueDateTo: '',
      deliveredDateFrom: '',
      deliveredDateTo: '',
      tracking: '',
      warehouse: '',
      typeSort: '',
      column: '',
    });
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  toggleTransportDetail = modalTransportDetail => {
    document.body.style.overflow = 'hidden';
    this.setState({ modalTransportDetail });
  };
  toggleClsTransportDetail = () => {
    document.body.style.overflow = 'auto';
    this.setState({ modalTransportDetail: null });
  };
  exportTransport = type => {
    this.setState({ isLoading: true });
    const { client, clientProps, carrier, status, orderDateFrom, orderDateTo, transportDateFrom, transportDateTo, tracking, warehouse,
      dispatchDueDateFrom, dispatchDueDateTo, deliveryDueDateFrom, deliveryDueDateTo, deliveredDateFrom, deliveredDateTo, intransit, retryToDeliver } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      status: status ? status.value : '',
      carrier: carrier ? carrier.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      orderDateFrom: orderDateFrom,
      orderDateTo: orderDateTo,
      transportDateFrom: transportDateFrom,
      transportDateTo: transportDateTo,
      dispatchdatefrom: dispatchDueDateFrom,
      dispatchdateto: dispatchDueDateTo,
      deliverydatefrom: deliveryDueDateFrom,
      deliverydateto: deliveryDueDateTo,
      delivereddatefrom: deliveredDateFrom,
      delivereddateto: deliveredDateTo,
      slaStatus: intransit ? intransit.value : '',
      retrytodealiver: retryToDeliver ? retryToDeliver.value : '',
      tracking: tracking,
    };

    this.props.dispatch(fetchTransportationExportNew(params, type));
  }
  checkNull = data => data ? data : '-';

  render() {
    const {
      client,
      carrier,
      orderDateFrom,
      orderDateTo,
      transportDateFrom,
      transportDateTo,
      dispatchDueDateFrom,
      dispatchDueDateTo,
      deliveryDueDateFrom,
      deliveryDueDateTo,
      deliveredDateFrom,
      deliveredDateTo,
      warehouse,
      status,
      transport,
      modalTransportDetail,
      clientType,
      carrierType,
      warehouseType,
      statusType,
      current,
      column,
      isLoading,
      intransitType,
      retryToDeliverType,
      intransit,
      retryToDeliver,
    } = this.state;

    if (carrierType === 'LOADING' || warehouseType === 'LOADING' || statusType === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="Transportation">
        {isLoading || clientType.length < 1 ? <Loading /> : null}
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={this.handleTitle()} hr>
            <div className="row mb-2">
              <div className="col-4">
                <Select
                  placeholder="Client"
                  closeMenuOnSelect={false}
                  isMulti
                  value={client}
                  options={clientType}
                  onChange={this.selectClient}
                  onInputChange={this.selectClientOnblue.bind(this)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Warehouse"
                  items={warehouseType}
                  selected={warehouse}
                  disabled={Array.isArray(warehouseType)}
                  handleSelect={value => this.handleSelect('warehouse', value)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Courier"
                  items={carrierType}
                  selected={carrier}
                  disabled={Array.isArray(carrierType)}
                  handleSelect={value => this.handleSelect('carrier', value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 box-date">
                <span className="title">Order date</span>
                <DatePicker
                  selectYear
                  selectDate={orderDateFrom}
                  handleSelectDate={value => this.handleSelect('orderDateFrom', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={orderDateTo}
                  handleSelectDate={value => this.handleSelect('orderDateTo', value)}
                  placeholder="to"
                  disabledDays={orderDateFrom}
                  disabled={orderDateFrom ? false : true}
                />
              </div>
              <div className="col-6 box-date">
                <span className="title">Transport date</span>
                <DatePicker
                  selectYear
                  selectDate={transportDateFrom}
                  handleSelectDate={value => this.handleSelect('transportDateFrom', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={transportDateTo}
                  handleSelectDate={value => this.handleSelect('transportDateTo', value)}
                  placeholder="to"
                  disabledDays={transportDateFrom}
                  disabled={transportDateFrom ? false : true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 box-date">
                <span className="title">Dispatch due</span>
                <DatePicker
                  selectYear
                  selectDate={dispatchDueDateFrom}
                  handleSelectDate={value => this.handleSelect('dispatchDueDateFrom', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={dispatchDueDateTo}
                  handleSelectDate={value => this.handleSelect('dispatchDueDateTo', value)}
                  placeholder="to"
                  disabledDays={dispatchDueDateFrom}
                  disabled={dispatchDueDateFrom ? false : true}
                />
              </div>
              <div className="col-6 box-date">
                <span className="title">Delivery due</span>
                <DatePicker
                  selectYear
                  selectDate={deliveryDueDateFrom}
                  handleSelectDate={value => this.handleSelect('deliveryDueDateFrom', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={deliveryDueDateTo}
                  handleSelectDate={value => this.handleSelect('deliveryDueDateTo', value)}
                  placeholder="to"
                  disabledDays={deliveryDueDateFrom}
                  disabled={deliveryDueDateFrom ? false : true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 box-date">
                <span className="title">Delivered date</span>
                <DatePicker
                  selectYear
                  selectDate={deliveredDateFrom}
                  handleSelectDate={value => this.handleSelect('deliveredDateFrom', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={deliveredDateTo}
                  handleSelectDate={value => this.handleSelect('deliveredDateTo', value)}
                  placeholder="to"
                  disabledDays={deliveredDateFrom}
                  disabled={deliveredDateFrom ? false : true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3 box-date">
                <span className="title">Status</span>
                <Dropdowns
                  placeholder="Status"
                  items={statusType}
                  selected={status}
                  disabled={Array.isArray(statusType)}
                  handleSelect={value => this.handleSelect('status', value)}
                />
              </div>
              {/* { console.log('>>>> status ', status) } */}
              {
                status && status.value === 'In Transit' ? 
                  <div className="col-3">
                    <Dropdowns
                      placeholder="In transit status"
                      items={intransitType}
                      selected={intransit}
                      disabled={true}
                      handleSelect={value => this.handleSelect('intransit', value)}
                    />
                  </div> : null
              }
              {
                intransit && intransit.text === 'Retry to deliver' ?
                  <div className="col-5">
                    <Dropdowns
                      placeholder="Retry to deliver status"
                      items={retryToDeliverType}
                      selected={retryToDeliver}
                      disabled={true}
                      handleSelect={value => this.handleSelect('retryToDeliver', value)}
                    />
                  </div> : null
              }
            </div>
            <hr className="hr" />
            <div className="row">
              <div className="col text-right">
                <div className="box-btn">
                  <Button className="btn-light" onClick={this.clsSearch}>Clear</Button>
                  <Button className="btn-primary" onClick={() => this.handleSearch(1, true)}>
                    Browse
                    <span className="icon icon-search" />
                  </Button>
                </div>
              </div>
            </div>
          </WhiteBox>

          {
            transport ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportTransport('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportTransport('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                  </div>
                  <div className="overall-detail">
                    <div className="o-item">
                      <span>Ready for ship</span>
                      <p>{transport.total && transport.total[0].readyForShipTotal ? transport.total[0].readyForShipTotal.toLocaleString() : 0}</p>
                    </div>
                    <div className="o-item">
                      <span>In transit</span>
                      <p>{transport.total ? transport.total[0].inTransitTotal.toLocaleString() : 0}</p>
                    </div>
                    <div className="o-item">
                      <span>Delivered</span>
                      <p>{transport.total ? transport.total[0].successfulDeliveryTotal.toLocaleString() : 0}</p>
                    </div>
                    <div className="o-item">
                      <span>Delivered failed</span>
                      <p>{transport.total ? transport.total[0].deliveryFailedTotal.toLocaleString() : 0}</p>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Order No." name="orderNo" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="AWB No." name="awbNo" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Code" name="customerCode" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Period" name="period" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Name" name="customerName" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Courier" name="courier" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order Date" name="orderDateTime" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Delivered date" name="deliveredDateTime" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Status Tracking" name="statusTracking" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        transport && transport.data ? transport.data.map((item, key) => (
                          <tr key={key} className="pointer" onClick={() => this.toggleTransportDetail(item)}>
                            <td>{item.orderNo}</td>
                            <td>{this.checkNull(item.awbNo)}</td>
                            <td>{item.customerCode}</td>
                            <td>{this.checkNull(item.period)}</td>
                            <td>{this.checkNull(item.customerName)}</td>
                            <td>{item.courier}</td>
                            <td>{item.orderDateTime ? formatDate(item.orderDateTime) : '-'}</td>
                            <td>{item.deliveredDateTime ? formatDate(item.deliveredDateTime) : '-'}</td>
                            <td>{item.statusTracking}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                      Showing {current === 1 ? current : (current - 1) * common.pagination.itemsCountPerPage}{' '}
                      to {current === 1 ? common.pagination.itemsCountPerPage : (current - 1) * common.pagination.itemsCountPerPage + common.pagination.itemsCountPerPage}{' '}
                      of {transport && transport.totalRow ? transport.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={common.pagination.itemsCountPerPage}
                      pageRangeDisplayed={common.pagination.pageRangeDisplayed}
                      totalItemsCount={transport && transport.totalRow ? transport.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>

        {
          modalTransportDetail ?
            <div className="modal-order-details">
              <div className="yas-order-detail container">
                <div className="head-detail">
                  <div className="row order-header">
                    <div className="col-8">
                      <span>Order number</span>
                      <h1>{modalTransportDetail.orderNo}</h1>
                    </div>
                    <div className="col text-right">
                      <span>Status</span>
                      <h1>{this.checkNull(modalTransportDetail.statusTracking)}</h1>
                    </div>
                  </div>
                  <div className="row order-detail">
                    <div className="col-3">
                      <span>Customer Name</span>
                      <p>{this.checkNull(modalTransportDetail.customerName)}</p>
                    </div>
                    <div className="col-3">
                      <span>Customer Code</span>
                      <p>{this.checkNull(modalTransportDetail.customerCode)}</p>
                    </div>
                    <div className="col-3">
                      <span>AWB No.</span>
                      <p>{this.checkNull(modalTransportDetail.awbNo)}</p>
                    </div>
                    <div className="col-3">
                      <span>รอบการส่งสินค้า</span>
                      <p>{this.checkNull(modalTransportDetail.period)}</p>
                    </div>
                    <div className="col-12">
                      <span>Ship to address</span>
                      <p>{this.checkNull(modalTransportDetail.customerAddress)}</p>
                    </div>
                    <div className="col-3">
                      <span>Province</span>
                      <p>{this.checkNull(modalTransportDetail.customerProvince)}</p>
                    </div>
                    <div className="col-3">
                      <span>Zone</span>
                      <p>{this.checkNull(modalTransportDetail.zone)}</p>
                    </div>
                    <div className="col-3">
                      <span>Courier</span>
                      <p>{this.checkNull(modalTransportDetail.courier)}</p>
                    </div>
                    <div className="col-3">
                      <span>Type</span>
                      <p>{this.checkNull(modalTransportDetail.type)}</p>
                    </div>
                    <div className="col-3">
                      <span>Recipient</span>
                      <p>{this.checkNull(modalTransportDetail.recipient)}</p>
                    </div>
                    <div className="col-3">
                      <span>QTY.</span>
                      <p>{this.checkNull(modalTransportDetail.qty)}</p>
                    </div>
                    <div className="col-3">
                      <span>Total box</span>
                      <p>{this.checkNull(modalTransportDetail.totalBox)}</p>
                    </div>
                    <div className="col-3">
                      <span>Weight</span>
                      <p>{this.checkNull(modalTransportDetail.weight)}</p>
                    </div>
                  </div>
                  {
                    modalTransportDetail.statusTracking && modalTransportDetail.statusTracking === 'In transit' ?
                      <div className="intransit-status">
                        <div className="row">
                          <div className="col-3">
                            <span>Status</span>
                            <p>{this.checkNull(modalTransportDetail.statusTracking)}</p>
                          </div>
                          <div className="col-3">
                            <span>In Transit Status</span>
                            <p>{this.checkNull(modalTransportDetail.delivery_sla)}</p>
                          </div>
                          <div className="col-3">
                            <span>Retry To Deliver Status</span>
                            <p>{this.checkNull(modalTransportDetail.intransitstatus_des)}</p>
                          </div>
                        </div>
                      </div> : null
                  }
                </div>
                <div className="body-detail yas-grid">
                  <div className="scroll-grid">
                    <Table>
                      <thead>
                        <tr>
                          <th>Order Date</th>
                          <th>Shipped Date</th>
                          <th>Delivered Date</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{modalTransportDetail.orderDateTime ? formatDate(modalTransportDetail.orderDateTime) : '-'}</td>
                          <td>{modalTransportDetail.shippedDateTime ? formatDate(modalTransportDetail.shippedDateTime) : '-'}</td>
                          <td>{modalTransportDetail.deliveredDateTime ? formatDate(modalTransportDetail.deliveredDateTime) : '-'}</td>
                          <td>{this.checkNull(modalTransportDetail.remark)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="yas-modal-footer close">
                  <button className="btn btn-light" onClick={this.toggleClsTransportDetail}>ปิดหน้าต่าง <i className="menu-icon icon-close" /></button>
                </div>
              </div>
            </div> : null
        }
        <RefreshToken />
      </div>
    );
  }
}

TransportNew.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getTransportation: PropTypes.func,
  getClientType: PropTypes.func,
  getTransporType: PropTypes.func,
  getWareHouseType: PropTypes.func,
  getCarrierType: PropTypes.func,
  getTransportationExport: PropTypes.func,
  getProfile: PropTypes.func,
};

const mapStateToProps = state => ({
  getProfile: state.getProfile,
  getTransportation: state.getTransportNew.data,
  getClientType: state.getClientType.data,
  getTransporType: state.getTransporType.data,
  getWareHouseType: state.getWareHouseType.data,
  getCarrierType: state.getCarrierType.data,
  getTransportationExport: state.getTransportExportNew.data,
  // getTransportationXls: state.getTransportationXls.data,
});

export default connect(mapStateToProps)(TransportNew);
