import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';

export const fetchPack = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/PackReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_PACK_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PACK_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PACK_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Warehouse', 'Order Type', 'Shipper', 'Order No.', 'Customer Code', 'Customer Name', 'Brand', 'Product Number', 'Product Description', 'QTY', 'UOM', 'Finish Pack Date']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].warehouse, data[i].orderType, data[i].shipper, data[i].orderNo, data[i].customerCode, data[i].customerName, data[i].brand, data[i].productNumber, data[i].productDescription, data[i].qty, data[i].uom, formatDate(data[i].finishPackDateTime)]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'Pack.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'Pack.xlsx');
  }
};

export const fetchPackExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/PackExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, type);

        return dispatch({
          'type': commonConstant.GET_PACK_EXPORT_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_PACK_EXPORT_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_PACK_EXPORT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PACK_EXPORT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
