import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';

export default class Checkbox extends Component {
  render() {
    const { checked, label, handleSelect } = this.props;

    return (
      <div className="Checkbox" onClick={handleSelect}>
        <div className={`box-check${checked ? ' active' : ''}`}>
          {checked ? <span className="icon fas fa-check" /> : null}
        </div>
        <span className="label">{label}</span>
      </div>
    );
  }
}

Checkbox.propTypes = {
  'checked': PropTypes.bool,
  'label': PropTypes.string.isRequired,
  'handleSelect': PropTypes.func.isRequired,
};
