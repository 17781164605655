import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import content from './Language';

import commonConstant from '../../common/commonConstant';
import { setLanguage } from '../../actions';
import { getCookieLanguage } from '../../common/commonFunction';
import { Loading } from '../../components';
import { CheckRedirect } from '../../pages';

class Oop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'redirect': false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  handleRedirect = () => this.setState({ 'redirect': true });

  render() {
    const { stateLanguage } = this.props;
    const { redirect } = this.state;

    if (!stateLanguage) {
      return <Loading />;
    } else if (redirect) {
      return <CheckRedirect />;
    }

    return (
      <div className="Oop template-error">
        <div className="box-page">
          <div className="error-title">:-(</div>
          <div className="box-detail">
            <img className="error-icon" src={commonConstant.errorOop} alt="" />
            <div className="error-detail">{content[stateLanguage]['detail']}</div>
            <div className="error-description">{content[stateLanguage]['description']}</div>
            <Button className="btn-primary" onClick={this.handleRedirect}>
              {content[stateLanguage]['backToHome']}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

Oop.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
};

const mapStateToProps = ({ stateLanguage }) => ({ 'stateLanguage': stateLanguage.data });

export default connect(mapStateToProps)(Oop);
