import commonConstant from '../../common/commonConstant';

const content = {
  'th': {
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
  },
  'en': {
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
  },
};

export const namingUserType = (stateLanguage, type) => {
  switch (type) {
    case commonConstant.typePathWarehouse:
      return content[stateLanguage]['warehouse'];
    case commonConstant.typePathClient:
      return content[stateLanguage]['client'];
    case commonConstant.typePathAE:
      return content[stateLanguage]['ae'];
    default:
      return null;
  }
};
