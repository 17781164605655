import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import './AddNewUserSuccess.scss';
import content from './Language';

import { getCookieLanguage } from '../../common/commonFunction';
import { setLanguage } from '../../actions';
import { WhiteBox, namingUserType } from '../../helpers';
import { Loading } from '../../components';
import { NotFound } from '../../pages';

class AddNewUserSuccess extends Component {
  UNSAFE_componentWillMount() {
    if (this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  render() {
    const { stateLanguage, 'match': { 'params': { type } } } = this.props;

    if (!stateLanguage) {
      return <Loading />;
    } else if (!namingUserType(stateLanguage, type)) {
      return (
        <div className="template-with-sidebar-navbar">
          <NotFound />
        </div>
      );
    }

    return (
      <div className="AddNewUserSuccess">
        <div className="template-with-sidebar-navbar">
          <WhiteBox>
            <span className="line-left" />
            <div className="box-detail">
              <div className="box-icon">
                <span className="icon icon-check" />
              </div>
              <div className="detail">
                <div className="title">
                  {content[stateLanguage]['title']}-{namingUserType(stateLanguage, type)} {content[stateLanguage]['title2']}
                </div>
                <div className="description">{content[stateLanguage]['description']}</div>
                {/* TODO: path */}
                <Link to="" className="link">{content[stateLanguage]['back']}</Link>
              </div>
            </div>
          </WhiteBox>
          <div className="box-btn">
            {/* TODO: path */}
            <Link to="" className="btn btn-primary">{content[stateLanguage]['backHome']}</Link>
          </div>
        </div>
      </div>
    );
  }
}

AddNewUserSuccess.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'match': PropTypes.shape({
    'params': PropTypes.shape({
      'type': PropTypes.string,
    }).isRequired,
  }).isRequired,
  'stateLanguage': PropTypes.string,
};

const mapStateToProps = ({ stateLanguage }) => ({ 'stateLanguage': stateLanguage.data });

export default connect(mapStateToProps)(AddNewUserSuccess);
