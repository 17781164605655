import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import './Login.scss';
import content from './Language';

import { getCookieLanguage, getCookieYASToken } from '../../common/commonFunction';
import commonConstant from '../../common/commonConstant';
import { setLanguage, fetchLogin } from '../../actions';
import { Loading } from '../../components';
import { InputWithIcon, history } from '../../helpers';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'email': '',
      'password': '',
      'loading': false,
      'warning': false,
    };
    if (getCookieYASToken()) {
      // TODO: change path to home
      history.push(commonConstant.pathDashboard);
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { login } = this.props;

    if (nextProps.login && nextProps.login && login) {
      this.setState({ 'email': '', 'password': '', 'loading': false });
      if (nextProps.login.status === 200) {
        if (nextProps.login.data.forceChangePassword) {
          return history.push(commonConstant.pathForceChangePassword);
        }
        // TODO: change path to home
        return history.push(commonConstant.pathDashboard);
      } else if (nextProps.login.status === 401) {
        this.setState({ 'warning': true });
      }
    }
  }

  handleEvent = (event, key) => this.setState({ [key]: event.target.value });

  handleLogin = () => {
    const { email, password } = this.state;

    if (email && password) {
      this.setState({ 'loading': true, 'warning': false });
      this.props.dispatch(fetchLogin({ email, password }));
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleLogin();
    }
  }

  render() {
    const { stateLanguage } = this.props;
    const { email, password, loading, warning } = this.state;

    if (!stateLanguage) {
      return <Loading />;
    }

    return (
      <div className="Login template-center">
        {loading ? <Loading /> : null}
        <form className="box-page">
          <img className="logo" src={commonConstant.yasLogo} alt="" />
          <div className="box-form">
            <div className="title">{content[stateLanguage]['login']}</div>
            <InputWithIcon
              warning={warning}
              onChange={event => this.handleEvent(event, 'email')}
              value={email}
              icon="icon-email"
              placeholder={content[stateLanguage]['email']}
              textWarning={content[stateLanguage]['textWarning']}
              autoComplete
            />
            <InputWithIcon
              warning={warning}
              onChange={event => this.handleEvent(event, 'password')}
              value={password}
              icon="icon-lock"
              placeholder={content[stateLanguage]['password']}
              type="password"
              textWarning={content[stateLanguage]['textWarning']}
              onKeyPress={this.handleKeyPress}
              autoComplete
            />
            <Link className="link" to={commonConstant.pathForgotPassword}>
              {content[stateLanguage]['forgotPassword']}
            </Link>
            <Button className="btn-primary btn-login" onClick={this.handleLogin}>
              {content[stateLanguage]['btnLogin']}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
  'login': PropTypes.shape({
    'status': PropTypes.number,
    'data': PropTypes.shape({
      'forceChangePassword': PropTypes.bool.isRequired,
    }),
  }),
};

const mapStateToProps = ({ stateLanguage, login }) => ({
  'stateLanguage': stateLanguage.data,
  login,
});

export default connect(mapStateToProps)(Login);
