export default {
  'th': {
    'detail': 'Sorry! The page not found',
    'description': 'The linkyou folowed probably broken. or the page has been remove',
    'backToHome': 'BACK TO HOMEPAGE',
  },
  'en': {
    'detail': 'Sorry! The page not found',
    'description': 'The linkyou folowed probably broken. or the page has been remove',
    'backToHome': 'BACK TO HOMEPAGE',
  },
};
