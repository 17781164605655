import React from 'react';

const ChartLineWidth = ({ type, percent, txt, width, color }) => (
  <div className={`_div_chart ${type ? type : '_success'}`} style={{ width: width }} >
    <div className="_chart_percent">
      <div className="item_percent" style={{ width: percent, background: color }}></div>
    </div>
    <div className="_chart_txt">{txt}</div>
  </div>
);

export default ChartLineWidth;
