import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sortType } from '../../common/commonConstant';

const Sorting = ({ click, label, name, currentColumn, className }) => {
  const [typeSort, setTypeSort] = useState();
  const showType = type => {
    if (currentColumn === name) {
      if (type === 'asc') {
        return 'icon-arrow-top-t';
      } else if (type === 'desc') {
        return 'icon-arrow-down-t';
      }
    }
    return;
  };
  const onSorting = () => {
    if (name === currentColumn && typeSort) {
      if (typeSort === sortType.ASC) {
        setTypeSort(sortType.DESC);
        return click(name, sortType.DESC);
      } else {
        setTypeSort('');
        return click('', '');
      }
    } else {
      setTypeSort(sortType.ASC);
      return click(name, sortType.ASC);
    }
  };

  return (
    <th className={`sorting ${className} ${showType(typeSort) ? '_active' : null}`} onClick={onSorting}>{label}
      {showType(typeSort) ? <span className={`menu-icon ${showType(typeSort)}`} /> : null}
    </th>
  );
};

Sorting.propTypes = {
  'click': PropTypes.func,
  'label': PropTypes.string,
  'name': PropTypes.string,
  'currentColumn': PropTypes.string,
  'className': PropTypes.string,
};

export default Sorting;
