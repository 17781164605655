import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Profile.scss';

import commonConstant from '../../common/commonConstant';
import { WhiteBox } from '../../helpers';
import { Loading } from '../../components';
import { fetchGetProfile } from '../../actions';

class Profile extends Component {
  UNSAFE_componentWillMount() {
    if (!this.props.getProfile) {
      return this.props.dispatch(fetchGetProfile());
    }
  }

  // handlePrivilage = () => {
  //   const
  // }

  render() {
    const { getProfile } = this.props;

    if (!getProfile) {
      return <Loading />;
    }

    return (
      <div className="Profile">
        <div className="template-with-sidebar-navbar">
          <WhiteBox>
            <div className="box-name">
              {
                getProfile.avatar ?
                  <img className="image" src={getProfile.avatar} alt="" />
                  : <img className="image" src={commonConstant.userDummy} alt="" />
              }
              <div className="box">
                <div className="user">User</div>
                <div className="name">{getProfile.name ? getProfile.name : null}</div>
                <div className="status">สถานะ: ใช้งาน<span className="status-color" /></div>
              </div>
            </div>
            <hr className="hr" />
            <div className="box-detail">
              <div className="title">รายละเอียด</div>
              <div className="box">
                <span className="icon icon-edit" />
                <span className="title">สร้างเมื่อ:</span>
                <span className="detail bold">
                  15 พฤศจิกายน 2562
                </span>
              </div>
              <div className="box">
                <span className="icon icon-person" />
                <span className="title">สร้างโดย:</span>
                <span className="detail bold">
                  Super admin#1
                </span>
              </div>
              <div className="box">
                <span className="icon icon-history" />
                <span className="title">การแก้ไขล่าสุด:</span>
                <span className="detail bold">
                  20 ธันวาคม 2562
                </span>
              </div>
            </div>
            <hr className="hr" />
            <div className="box-type">
              <div className="title">ประเภท</div>
              <div className="detail">test</div>
            </div>
            <hr className="hr" />
            <div className="box-org">
              <div className="title">องค์กร</div>
              <div className="detail">
                <img src={commonConstant.yasLogoSmall} alt="" />
                test
              </div>
            </div>
            <hr className="hr" />
            <div className="box-privilage">
              <div className="title">สิทธิ์#</div>
              <div className="privilage">
                <span className="title">Area: </span>
                <span className="detail">ภาคกลาง - กรุงเทพ - โซนกลาง - บึงกุ่ม</span>
              </div>
              <div className="privilage">
                <span className="title">Role: </span>
                <span className="detail">ภาคกลาง - กรุงเทพ - โซนกลาง - บึงกุ่ม</span>
              </div>
            </div>
          </WhiteBox>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'getProfile': PropTypes.shape({
    'avatar': PropTypes.string,
    'name': PropTypes.string,
  }),
};

const mapStateToPorps = ({ stateLanguage, getProfile }) => ({ 'stateLanguage': stateLanguage.data, 'getProfile': getProfile.data });

export default connect(mapStateToPorps)(Profile);
