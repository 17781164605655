import React from 'react';
import PropTypes from 'prop-types';

import './RadioButton.scss';

const RadioButton = props => (
  <div className="RadioButton" onClick={props.handleSelect}>
    <span className="check">
      <span className={props.active ? 'active' : ''} />
    </span>
    <span className="text">{props.text}</span>
  </div>
);

RadioButton.propTypes = {
  'active': PropTypes.bool,
  'text': PropTypes.string,
  'handleSelect': PropTypes.func,
};

export default RadioButton;
