import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Button, Table } from 'reactstrap';
import { fetchSOHReport, fetchClientType, fetchProductGroupType, fetchWareHouseType, fetchBrandType, fetchHOSExport, fetchGetProfile } from '../../actions';

import { WhiteBox, InputWithIcon, Dropdowns, ThSorting } from '../../helpers';
import commonConstant from '../../common/commonConstant';

import { Loading, RefreshToken } from '../../components';

class StockOnHand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientProps: '',
      client: '',
      productGroup: null,
      brand: null,
      searchProductId: null,
      warehouse: null,
      sohReport: null,
      clientType: [],
      warehouseType: null,
      productGroupType: null,
      brandType: null,
      current: 1,
      typeSort: '',
      column: '',
      isLoading: false,
      roleList: [],
    };
    this.props.dispatch(fetchGetProfile());
  }
  componentDidMount() {
    if (this.props.getProfile) {
      const { clients , roles} = this.props.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { clients, roles } = nextProps.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
    if (nextProps.getSOHReport && nextProps.getSOHReport !== this.props.getSOHReport) {
      this.setState({ sohReport: nextProps.getSOHReport, isLoading: false });
    }
    if (nextProps.getSOHExport && nextProps.getSOHExport !== this.props.getSOHExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getClientType && nextProps.getClientType !== this.props.getClientType) {
      const clientType = nextProps.getClientType.result.list.map(item => {
        item.label = item.text;
        return item;
      });
      this.setState({ clientType });
    }
    if (nextProps.getWareHouseType && nextProps.getWareHouseType !== this.props.getWareHouseType) {
      this.setState({ warehouseType: nextProps.getWareHouseType.result.list });
    }
    if (nextProps.getBrandType && nextProps.getBrandType !== this.props.getBrandType) {
      this.setState({ brandType: nextProps.getBrandType.result.list });
    }
    if (nextProps.getProductGroupType && nextProps.getProductGroupType !== this.props.getProductGroupType) {
      this.setState({ productGroupType: nextProps.getProductGroupType.result.list });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current } = this.state;

    this.setState({ column: await column, typeSort: await typeSort });
    this.handleSearch(current);
  }
  getInit = code => {
    this.setState({
      productGroupType: 'LOADING',
      warehouseType: 'LOADING',
      brandType: 'LOADING',
    });
    this.props.dispatch(fetchProductGroupType(code));
    this.props.dispatch(fetchWareHouseType(code));
    this.props.dispatch(fetchBrandType(code));
  }
  checkRole = clients => {
    this.setState({ clientProps: clients });
    const roleList = this.state.roleList;
    console.log('------- client --------');
    console.log(roleList);
    if (roleList[0] === 'SUPER_ADMIN') {
      this.props.dispatch(fetchClientType());
      this.getInit('');
    } else {
      const items = clients.map(item => {
        const data = {};
        data.label = item.text;
        data.value = item.value;
        return data;
      });
      this.setState({ clientType: items });
      this.getInit(clients.map(_ => _.value).join());
    }
  }
  handleTitle = () => (
    <div className="box-title">
      <span className="title">Search</span>
      <InputWithIcon
        onChange={event => this.handleSelect('searchProductId', event.target.value)}
        value={this.state.searchProductId}
        icon="icon-search"
        placeholder="ex. Accessor"
      />
    </div>
  );
  selectClient = client => this.setState({ client });
  selectClientOnblue = (value, action) => {
    if (action.action === 'input-blur') {
      const { client } = this.state;
      const clientFilter = client && client.length > 0 ? client.map(item => item.value).join() : '';
      if (clientFilter) {
        this.getInit(clientFilter);
      }
    }
  }
  handleSelect = (key, value) => this.setState({ [key]: value });
  handleSearch = (current, isSch) => {
    this.setState({ isLoading: true });
    if (isSch) {this.setState({ current, typeSort: '', column: '' });}
    const { client, clientProps, productGroup, brand, warehouse, searchProductId, column, typeSort } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      productGroup: productGroup ? productGroup.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      brand:  brand ? brand.value : '',
      searchProductId: searchProductId,
      orderBy: typeSort ? column : '',
      orderType: typeSort,
      pageNumber: current,
      pageSize: commonConstant.pagination.itemsCountPerPage,
    };
    this.props.dispatch(fetchSOHReport(params));
  }
  clsSearch = () => {
    const { clientProps } = this.state;
    this.getInit(clientProps);
    this.setState({
      client: '',
      productGroup: null,
      brand: null,
      searchProductId: '',
      warehouse: null,
      typeSort: '',
      column: '',
    });
  }
  exportWorkBook = type => {
    this.setState({ isLoading: true });
    const { client, clientProps, productGroup, brand, warehouse, searchProductId } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      productGroup: productGroup ? productGroup.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      brand:  brand ? brand.value : '',
      searchProductId: searchProductId,
    };

    this.props.dispatch(fetchHOSExport(params, type, 'StockOnHand'));
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  checkNull = data => data ? data : '-';

  render() {
    const {
      client,
      productGroup,
      brand,
      warehouse,
      sohReport,
      clientType,
      warehouseType,
      productGroupType,
      brandType,
      current,
      column,
      isLoading,
    } = this.state;
    if (warehouseType === 'LOADING' || productGroupType === 'LOADING' || brandType === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="StockOnHand">
        { isLoading || clientType.length < 1 ? <Loading /> : null }
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={this.handleTitle()} hr>
            <div className="row mb-2">
              <div className="col-5">
                <Select
                  placeholder="Client"
                  closeMenuOnSelect={false}
                  isMulti
                  value={client}
                  options={clientType}
                  onChange={this.selectClient}
                  onInputChange={this.selectClientOnblue.bind(this)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <Dropdowns
                  placeholder="Warehouse"
                  items={warehouseType}
                  selected={warehouse}
                  disabled={Array.isArray(warehouseType)}
                  handleSelect={value => this.handleSelect('warehouse', value)}
                />
              </div>
              <div className="col-3">
                <Dropdowns
                  placeholder="Product Group"
                  items={productGroupType}
                  selected={productGroup}
                  disabled={Array.isArray(productGroupType)}
                  handleSelect={value => this.handleSelect('productGroup', value)}
                />
              </div>
              <div className="col-3">
                <Dropdowns
                  placeholder="Brand"
                  items={brandType}
                  selected={brand}
                  disabled={Array.isArray(brandType)}
                  handleSelect={value => this.handleSelect('brand', value)}
                />
              </div>
              <div className="col text-right">
                <div className="box-btn">
                  <Button className="btn-light" onClick={this.clsSearch}>Clear</Button>
                  <Button className="btn-primary" onClick={() => this.handleSearch(1, true)}>
                    Browse
                    <span className="icon icon-search" />
                  </Button>
                </div>
              </div>
            </div>
          </WhiteBox>

          {
            sohReport ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportWorkBook('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportWorkBook('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                  </div>
                  <div className="overall-detail">
                    <div className="o-item">
                      <span>Total Quantity</span>
                      <p>{sohReport.total[0].totalQty.toLocaleString()}</p>
                    </div>
                    <div className="o-item">
                      <span>On Order</span>
                      <p>{sohReport.total[0].onOrder.toLocaleString()}</p>
                    </div>
                    <div className="o-item">
                      <span>Available Quantity</span>
                      <p>{sohReport.total[0].availableQty.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Warehouse" name="warehouse" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Owner" name="owner" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Group" name="productGroup" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Brand" name="brand" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Number" name="productNumber" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Description" name="productDescription" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Total Qty." name="totalQty" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="On order" name="onOrder" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Available Qty." name="availableQty" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="UOM" name="uom" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        sohReport ? sohReport.data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.warehouse}</td>
                            <td>{this.checkNull(item.owner)}</td>
                            <td>{this.checkNull(item.productGroup)}</td>
                            <td>{item.brand}</td>
                            <td>{item.productNumber}</td>
                            <td>{this.checkNull(item.productDescription)}</td>
                            <td>{item.totalQty}</td>
                            <td>{item.onOrder}</td>
                            <td>{item.availableQty}</td>
                            <td>{item.uom}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>

                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                      Showing {current === 1 ? current : (current - 1) * commonConstant.pagination.itemsCountPerPage}{' '}
                      to {current === 1 ? commonConstant.pagination.itemsCountPerPage : (current - 1) * commonConstant.pagination.itemsCountPerPage + commonConstant.pagination.itemsCountPerPage}{' '}
                      of {sohReport ? sohReport.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={commonConstant.pagination.itemsCountPerPage}
                      pageRangeDisplayed={commonConstant.pagination.pageRangeDisplayed}
                      totalItemsCount={sohReport ? sohReport.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>
        <RefreshToken />
      </div>
    );
  }
}

StockOnHand.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getSOHReport: PropTypes.func,
  getClientType: PropTypes.func,
  getProductGroupType: PropTypes.func,
  getWareHouseType: PropTypes.func,
  getBrandType: PropTypes.func,
  getProfile: PropTypes.func,
  getSOHExport: PropTypes.func,
};

const mapStateToProps = state => ({
  getProfile: state.getProfile,
  getSOHReport: state.getSOHReport.data,
  getClientType: state.getClientType.data,
  getProductGroupType: state.getProductGroupType.data,
  getWareHouseType: state.getWareHouseType.data,
  getBrandType: state.getBrandType.data,
  getSOHExport: state.getSOHExport,
});

export default connect(mapStateToProps)(StockOnHand);
