import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Button, Table } from 'reactstrap';
import {
  fetchPick,
  fetchClientType,
  fetchProductGroupType,
  fetchWareHouseType,
  fetchBrandType,
  fetchPickType,
  fetchPickExport,
  fetchGetProfile
} from '../../actions';

import { WhiteBox, InputWithIcon, Dropdowns, DatePicker, ThSorting } from '../../helpers';
import commonConstant from '../../common/commonConstant';
import { formatDate } from '../../common/commonFunction';

import { Loading, RefreshToken } from '../../components';

class Pick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientProps: '',
      client: '',
      productGroup: null,
      brand: null,
      searchProductId: null,
      warehouse: null,
      pick: null,
      pickDateFrom: null,
      pickDateTo: null,
      pickModel: null,
      clientType: [],
      warehouseType: [],
      productGroupType: [],
      brandType: [],
      pickType: [],
      current: 1,
      typeSort: '',
      column: '',
      isLoading: false,
      roleList: [],
    };

    this.props.dispatch(fetchGetProfile());
  }
  componentDidMount() {
    if (this.props.getProfile) {
      const { clients, roles } = this.props.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { clients, roles } = nextProps.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
    if (nextProps.getPickExport && nextProps.getPickExport !== this.props.getPickExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getPick && nextProps.getPick !== this.props.getPick) {
      this.setState({ pickModel: nextProps.getPick, isLoading: false });
    }
    if (nextProps.getClientType && nextProps.getClientType !== this.props.getClientType) {
      const clientType = nextProps.getClientType.result.list.map(item => {
        item.label = item.text;
        return item;
      });
      this.setState({ clientType });
    }
    if (nextProps.getWareHouseType && nextProps.getWareHouseType !== this.props.getWareHouseType) {
      this.setState({ warehouseType: nextProps.getWareHouseType.result.list });
    }
    if (nextProps.getBrandType && nextProps.getBrandType !== this.props.getBrandType) {
      this.setState({ brandType: nextProps.getBrandType.result.list });
    }
    if (nextProps.getPickType && nextProps.getPickType !== this.props.getPickType) {
      this.setState({ pickType: nextProps.getPickType.result.list });
    }
    if (nextProps.getProductGroupType && nextProps.getProductGroupType !== this.props.getProductGroupType) {
      this.setState({ productGroupType: nextProps.getProductGroupType.result.list });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current } = this.state;

    this.setState({ column: await column, typeSort: await typeSort });
    this.handleSearch(current);
  }
  getInit = code => {
    this.setState({
      warehouseType: 'LOADING',
      productGroupType: 'LOADING',
      brandType: 'LOADING',
      pickType: 'LOADING',
    });
    this.props.dispatch(fetchProductGroupType(code));
    this.props.dispatch(fetchWareHouseType(code));
    this.props.dispatch(fetchBrandType(code));
    this.props.dispatch(fetchPickType(code));
  }
  checkRole = clients => {
    this.setState({ clientProps: clients });
    const roleList = this.state.roleList;
    console.log('------- client --------');
    console.log(roleList);
    if (roleList[0] === 'SUPER_ADMIN') {
      this.props.dispatch(fetchClientType());
      this.getInit('');
    } else {
      const items = clients.map(item => {
        const data = {};
        data.label = item.text;
        data.value = item.value;
        return data;
      });

      this.setState({ clientType: items });
      this.getInit(clients.map(_ => _.value).join());
    }
  }
  handleTitle = () => (
    <div className="box-title">
      <span className="title">Search</span>
      <InputWithIcon
        onChange={event => this.handleSelect('searchProductId', event.target.value)}
        value={this.state.searchProductId}
        icon="icon-search"
        placeholder="Product number"
      />
    </div>
  );
  selectClient = client => this.setState({ client });
  selectClientOnblue = (value, action) => {
    if (action.action === 'input-blur') {
      const { client } = this.state;
      const clientFilter = client && client.length > 0 ? client.map(item => item.value).join() : '';
      if (clientFilter) {
        this.getInit(clientFilter);
      }
    }
  }
  handleSelect = (key, value) => this.setState({ [key]: value });

  handleSearch = (current, isSch) => {
    this.setState({ isLoading: true });
    if (isSch) {this.setState({ current, typeSort: '', column: '' });}
    const { client, clientProps, productGroup, brand, warehouse, searchProductId, pick, pickDateFrom, pickDateTo, column, typeSort } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      productGroup: productGroup ? productGroup.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      brand:  brand ? brand.value : '',
      searchProductId: searchProductId,
      pickDateFrom: pickDateFrom,
      pickDateTo: pickDateTo,
      pickType: pick ? pick.value : '',
      orderBy: typeSort ? column : '',
      orderType: typeSort,
      pageNumber: current,
      pageSize: commonConstant.pagination.itemsCountPerPage,
    };
    this.props.dispatch(fetchPick(params));
  }
  clsSearch = () => {
    const { clientProps } = this.state;
    this.getInit(clientProps);
    this.setState({
      client: '',
      productGroup: '',
      brand: '',
      searchProductId: '',
      pickType: '',
      warehouse: '',
      pickDateFrom: null,
      pickDateTo: null,
      typeSort: '',
      column: '',
    });
  }
  exportWorkBook = type => {
    this.setState({ isLoading: true });
    const { client, clientProps, productGroup, brand, warehouse, searchProductId, pick, pickDateFrom, pickDateTo } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps.map(item => item.value).join();
      // : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      productGroup: productGroup ? productGroup.value : '',
      warehouse:  warehouse ? warehouse.value : '',
      brand:  brand ? brand.value : '',
      searchProductId: searchProductId,
      pickDateFrom: pickDateFrom,
      pickDateTo: pickDateTo,
      pickType: pick ? pick.value : '',
    };
    this.props.dispatch(fetchPickExport(params, type));
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  checkNull = data => data ? data : '-';

  render() {
    const {
      client,
      productGroup,
      brand,
      warehouse,
      pick,
      pickModel,
      pickDateFrom,
      pickDateTo,
      clientType,
      warehouseType,
      productGroupType,
      brandType,
      pickType,
      current,
      column,
      isLoading,
    } = this.state;

    if (warehouseType === 'LOADING' || productGroupType === 'LOADING' || brandType === 'LOADING' || pickType === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="Pick-page">
        { isLoading || clientType.length < 1 ? <Loading /> : null }
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={this.handleTitle()} hr>
            <div className="row mb-3">
              <div className="col-4">
                <Select
                  placeholder="Client"
                  closeMenuOnSelect={false}
                  isMulti
                  value={client}
                  options={clientType}
                  onChange={this.selectClient}
                  onInputChange={this.selectClientOnblue.bind(this)} />
              </div>
              <div className="col-3">
                <Dropdowns
                  placeholder="Warehouse"
                  items={warehouseType}
                  selected={warehouse}
                  disabled={Array.isArray(warehouseType)}
                  handleSelect={value => this.handleSelect('warehouse', value)} />
              </div>
              <div className="col-3">
                <Dropdowns
                  placeholder="Product Group"
                  items={productGroupType}
                  selected={productGroup}
                  disabled={Array.isArray(productGroupType)}
                  handleSelect={value => this.handleSelect('productGroup', value)} />
              </div>
              <div className="col-2">
                <Dropdowns
                  placeholder="Brand"
                  items={brandType}
                  selected={brand}
                  disabled={Array.isArray(brandType)}
                  handleSelect={value => this.handleSelect('brand', value)} />
              </div>
            </div>
            <div className="row">
              <div className="col-5 box-date">
                <span className="title">Pick date</span>
                <DatePicker
                  selectYear
                  selectDate={pickDateFrom}
                  handleSelectDate={value => this.handleSelect('pickDateFrom', value)}
                  placeholder="from" />
                <DatePicker
                  selectYear
                  selectDate={pickDateTo}
                  handleSelectDate={value => this.handleSelect('pickDateTo', value)}
                  placeholder="to"
                  disabledDays={pickDateFrom}
                  disabled={pickDateFrom ? false : true} />
              </div>
              <div className="col-2">
                <Dropdowns
                  placeholder="Status"
                  items={pickType}
                  selected={pick}
                  disabled={Array.isArray(pickType)}
                  handleSelect={value => this.handleSelect('pick', value)} />
              </div>
              <div className="col text-right">
                <div className="box-btn">
                  <Button className="btn-light" onClick={this.clsSearch}>Clear</Button>
                  <Button className="btn-primary" onClick={() => this.handleSearch(1, true)}>
                    Browse
                    <span className="icon icon-search" />
                  </Button>
                </div>
              </div>
            </div>
          </WhiteBox>

          {
            pickModel ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportWorkBook('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportWorkBook('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                  </div>
                  <div className="overall-detail row">
                    <div className="o-item">
                      <span>Total Order</span>
                      <p>{pickModel ? pickModel.total[0].total.toLocaleString() : 0}</p>
                    </div>
                    <div className="o-item">
                      <span>Total Qty</span>
                      <p>{pickModel.total[0].totalQty ? pickModel.total[0].totalQty.toLocaleString() : 0}</p>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Warehouse" name="warehouse" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order Type" name="orderType" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Pick Slip" name="pickSlip" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Code" name="customerCode" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Name" name="customerName" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Brand" name="brand" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Number" name="productNumber" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Description" name="productDescription" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="QTY" name="qty" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="UOM" name="uom" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Finish Pick Date" name="finishPickDateTime" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pickModel ? pickModel.data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.warehouse}</td>
                            <td>{this.checkNull(item.orderType)}</td>
                            <td>{item.pickSlip}</td>
                            <td>{item.customerCode}</td>
                            <td>{this.checkNull(item.customerName)}</td>
                            <td>{this.checkNull(item.brand)}</td>
                            <td>{item.productNumber}</td>
                            <td>{this.checkNull(item.productDescription)}</td>
                            <td>{item.qty}</td>
                            <td>{item.uom}</td>
                            <td>{formatDate(item.finishPickDateTime)}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>

                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                      Showing {current === 1 ? current : (current - 1) * commonConstant.pagination.itemsCountPerPage}{' '}
                      to {current === 1 ? commonConstant.pagination.itemsCountPerPage : (current - 1) * commonConstant.pagination.itemsCountPerPage + commonConstant.pagination.itemsCountPerPage}{' '}
                      of {pickModel ? pickModel.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={commonConstant.pagination.itemsCountPerPage}
                      pageRangeDisplayed={commonConstant.pagination.pageRangeDisplayed}
                      totalItemsCount={pickModel ? pickModel.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>
        <RefreshToken />
      </div>
    );
  }
}

Pick.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getPick: PropTypes.func,
  getClientType: PropTypes.func,
  getProductGroupType: PropTypes.func,
  getWareHouseType: PropTypes.func,
  getBrandType: PropTypes.func,
  getPickType: PropTypes.func,
  getProfile: PropTypes.func,
  getPickExport: PropTypes.func,
};

const mapStateToProps = state => ({
  getProfile: state.getProfile,
  getPick: state.getPick.data,
  getClientType: state.getClientType.data,
  getProductGroupType: state.getProductGroupType.data,
  getWareHouseType: state.getWareHouseType.data,
  getBrandType: state.getBrandType.data,
  getPickType: state.getPickType.data,
  getPickExport: state.getPickExport,
});

export default connect(mapStateToProps)(Pick);
