export default {
  'th': {
    'main': 'MAIN',
    'dashboard': 'Dashboard',
    'stockOnHand': 'Stock on hand',
    'soh': 'SOH serial',
    'stockAging': 'Stock aging',
    'orderTrackingReport': 'Order tracking report',
    'orderHistory': 'Order history',
    'tracking': 'Tracking',
    'transportationPerformance': 'Transportation performance',
    'stockReceive': 'Stock receive',
    'pick': 'Pick',
    'pack': 'Pack',
    'management': 'MANAGEMENT',
    'addNewUser': 'Add new user',
    'userManagement': 'User management',
    'roleManagement': 'Role management',
    'areaManagement': 'Area management',
    'setting': 'SETTING',
    'profile': 'Profile',
    'logout': 'Log out',
    'orderStatusTracking': 'Order Status Tracking Report',
  },
  'en': {
    'main': 'MAIN',
    'dashboard': 'Dashboard',
    'stockOnHand': 'Stock on hand',
    'soh': 'SOH serial',
    'stockAging': 'Stock aging',
    'orderTrackingReport': 'Order tracking report',
    'orderHistory': 'Order history',
    'tracking': 'Tracking',
    'transportationPerformance': 'Transportation performance',
    'stockReceive': 'Stock receive',
    'pick': 'Pick',
    'pack': 'Pack',
    'management': 'MANAGEMENT',
    'addNewUser': 'Add new user',
    'userManagement': 'User management',
    'roleManagement': 'Role management',
    'areaManagement': 'Area management',
    'setting': 'SETTING',
    'profile': 'Profile',
    'logout': 'Log out',
  },
};
