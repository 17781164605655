import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import commonConstant from '../../common/commonConstant';

import { fetchRefreshToken } from '../../actions';
import { getCookieYASToken } from '../../common/commonFunction';
import { history } from '../../helpers';

class RefreshToken extends Component {
  UNSAFE_componentWillMount() {
    if (getCookieYASToken()) {
      this.props.dispatch(fetchRefreshToken());
    } else {
      history.push(commonConstant.pathLogout);
    }
  }

  render() {
    return null;
  }
}

RefreshToken.propTypes = {
  'dispatch': PropTypes.func.isRequired,
};

export default connect()(RefreshToken);
