export default {
  'th': {
    'detail': 'Unexpected error',
    'description': 'The server Enountered an internal error and wasunavle to process your request',
    'backToHome': 'BACK TO HOMEPAGE',
  },
  'en': {
    'detail': 'Unexpected error',
    'description': 'The server Enountered an internal error and wasunavle to process your request',
    'backToHome': 'BACK TO HOMEPAGE',
  },
};
