import commonConstant from '../common/commonConstant';

import {
  createCookie,
  getCookie,
  deleteCookie,
} from '../helpers';

// cookie language
const createCookieLanguage = value => createCookie(commonConstant.cookieLanguage, value);
const getCookieLanguage = () => getCookie(commonConstant.cookieLanguage) ? getCookie(commonConstant.cookieLanguage) : commonConstant.defaultLanguage;

// cookie token login
const createCookieYASToken = token => createCookie(commonConstant.cookieToken, token, 3);
const getCookieYASToken = () => getCookie(commonConstant.cookieToken) ? getCookie(commonConstant.cookieToken) : null;
const deleteCookieYASToken = () => deleteCookie(commonConstant.cookieToken);

const pad = (n, len) => (new Array(len + 1).join('0') + n).slice(-len);
const formatDate = date => {
  if (date) {
    const d = date.split(/[^0-9]/);
    const newDate = new Date(d[0],d[1] - 1,d[2],d[3],d[4],d[5]);
    return `${pad(newDate.getDate(), 2)}/${pad(newDate.getUTCMonth() + 1, 2)}/${newDate.getUTCFullYear()} ${pad(newDate.getHours(), 2)}:${pad(newDate.getUTCMinutes(), 2)}`;
  } else {
    return '-';
  }
};
const checkNull = data => data ? data : '-';

export {
  // cookie
  createCookieLanguage,
  getCookieLanguage,
  createCookieYASToken,
  getCookieYASToken,
  deleteCookieYASToken,
  // Format date
  formatDate,
  checkNull,
};
