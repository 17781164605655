import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import Pagination from 'react-js-pagination';

import { fetchOrganizations, fetchUserType, fetchRoleType, fetchOrganizationsRoleSearch, fetchRoleCnt } from '../../actions';
import { WhiteBox, Dropdowns, InputWithIcon, history } from '../../helpers';
import common from '../../common/commonConstant';

const RoleManagement = () => {
  const [roleList, setRoleList] = useState([]);
  const [clientType, setClientType] = useState([]);
  const [userType, setUserType] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const [current, setCurrent] = useState(1);
  const [roleCnt, setRoleCnt] = useState(0);
  const [model, setModel] = useState({
    tracking: '',
    client: '',
    user: '',
    role: '',
  });
  const getOrganizations = useSelector(({ getOrganizations }) => getOrganizations.data);
  const getUserType = useSelector(({ getUserType }) => getUserType.data);
  const getOrganizationsRole = useSelector(({ getOrganizationsRole }) => getOrganizationsRole.data);
  const getOrganizationsRoleSearch = useSelector(({ getOrganizationsRoleSearch }) => getOrganizationsRoleSearch.data);
  const getRoleType = useSelector(({ getRoleType }) => getRoleType.data);
  const getRoleCnt = useSelector(({ getRoleCnt }) => getRoleCnt.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current === 1) {
      handleSearch(1);
    }
  }, [current]);
  useEffect(() => {
    if (getOrganizations) {
      const items = getOrganizations.map(item => {
        return { text: item.name, value: item.organizationId };
      });
      setClientType(items);
    } 
    else { dispatch(fetchOrganizations()); }
  }, [getOrganizations]);
  useEffect(() => {
    if (getUserType) {
      const items = getUserType.map(item => {
        return { text: item.name, value: item.userTypeId };
      });
      setUserType(items);
    }
    else { dispatch(fetchUserType()); }
  }, [getUserType]);
  useEffect(() => {
    if (getOrganizationsRole) {
      setRoleList(getOrganizationsRole);
    }
  }, [getOrganizationsRole]);
  useEffect(() => {
    if (getOrganizationsRoleSearch) {
      setRoleList(getOrganizationsRoleSearch);
    }
  }, [getOrganizationsRoleSearch]);
  useEffect(() => {
    if (getRoleCnt) {
      setRoleCnt(getRoleCnt);
    }
  }, [getRoleCnt]);
  useEffect(() => {
    if (getRoleType) {
      const items = getRoleType.map(item => {
        return { text: item.name, value: item.roleTemplateId };
      });
      setRoleType(items);
    }
    else { dispatch(fetchRoleType()); }
  }, [getRoleType]);

  const handleSelect = (key, value) => setModel(Object.assign({ ...model }, { [key]: value }));
  const handleTitle = () => (
    <div className="box-title">
      <div className="title">คำค้นหา</div>
      <InputWithIcon
        onChange={e => handleSelect('tracking', e.target.value)}
        value={model.tracking}
        icon="icon-search"
        placeholder="ค้นหา Role"
      />
    </div>
  );
  const handleSearch = curr => {
    const { tracking, client, user, role } = model;
    const params = {
      userTypeId: user.value ? user.value : null,
      roleTemplateId: role.value ? role.value : null,
      organizationId: client.value ? client.value : null,
      text: tracking,
      limit: common.pagination.itemsCountPerPage, 
      skip: curr === 1 ? 0 : (curr - 1) * common.pagination.itemsCountPerPage, 
    };
    const paramsCnt = {
      userTypeId: user.value ? user.value : null,
      roleTemplateId: role.value ? role.value : null,
      organizationId: client.value ? client.value : null,
      text: tracking,
    };
    
    dispatch(fetchRoleCnt(paramsCnt));
    dispatch(fetchOrganizationsRoleSearch(params));
  };
  const clsSearch = () => setModel({
    tracking: '',
    client: '',
    user: '',
    role: '',
    current: 1,
  });
  const pageChange = curr => {
    setCurrent(curr);
    handleSearch(curr);
    // dispatch(fetchOrganizationsRole({ limit: common.pagination.itemsCountPerPage, skip: curr === 1 ? 0 : (curr - 1) * common.pagination.itemsCountPerPage }));
  };
  const viewRole = code => {
    return history.push(`./role-management/${code}`);
  };

  return (
    <div className="RoleManagement">
      <div className="template-with-sidebar-navbar">
        <WhiteBox title={handleTitle()} hr>
          <div className="row mb-2">
            <div className="col-3">
              <Dropdowns
                placeholder="โปรเลือกองค์กร"
                items={clientType}
                selected={model.client}
                disabled={true}
                handleSelect={value => handleSelect('client', value)}
              />
            </div>
            <div className="col-3">
              <Dropdowns
                placeholder="โปรเลือกรูปแบบ"
                items={userType}
                selected={model.user}
                disabled={true}
                handleSelect={value => handleSelect('user', value)}
              />
            </div>
            <div className="col-3">
              <Dropdowns
                placeholder="โปรเลือกประเภท"
                items={roleType}
                selected={model.role}
                disabled={true}
                handleSelect={value => handleSelect('role', value)}
              />
            </div>
            <div className="col text-right">
              <Button color="link" onClick={clsSearch}>Clear</Button>
              <Button color="secondary" onClick={() => handleSearch(1)}>
                    Browse
                <span className="icon icon-search" />
              </Button>
            </div>
          </div>
        </WhiteBox>
        <div className="overall-details">
          <div className="overall">
            <div className="overall-head">
              <div className="title">ทั้งหมด { roleCnt.count } role</div>
              <Link to={common.pathRoleManagementAdd} className="export success">สร้าง Role ใหม่ <i className="menu-icon icon-plus" /></Link>
            </div>

            <div className="template-white-box yas-grid">
              <Table>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>องค์กร</th>
                    <th>รูปแบบ</th>
                    <th>ประเภท</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    roleList.map((item, key) => (
                      <tr key={key} onClick={() => viewRole(item.organizationRoleId)} className="pointer" >
                        <td>{item.name}</td>
                        <td>{item.organization.name}</td>
                        <td>{item.userType ? item.userType.name : '-'}</td>
                        <td>{item.roleTemplate ? item.roleTemplate.name : '-'}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="text-div-right">
          <Pagination
            activePage={current}
            itemsCountPerPage={common.pagination.itemsCountPerPage}
            pageRangeDisplayed={common.pagination.pageRangeDisplayed}
            totalItemsCount={roleCnt.count}
            onChange={pageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
