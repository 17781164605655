import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import CommonConstant from '../common/commonConstant';

const NewLoading = () => {
  const getLoading = useSelector(state => _.findKey(state , ['status', 'PENDING']));

  useEffect(() => {
    if (getLoading) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [getLoading]);
  return (
    <>
      {getLoading ?
        <div className="Loading">
          <div className="box">
            <div className="box-loading">
              <img className="image-loading" src={CommonConstant.loading} alt="" />
              <img className="image-logo" src={CommonConstant.yasLogo} alt="" />
            </div>
          </div>
        </div>
        : null}
    </>
  );
};

export default NewLoading;
