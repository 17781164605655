export default {
  'th': {
    'addNewUser': 'Add new user',
    'home': 'Home',
    'addNew': 'Add new',
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
    'selectType': 'ประเภท User ที่เลือก',
    'changeType': 'เปลี่ยนประเภท User',
    'selectOrg': 'เลือกองค์กร',
    'chooseOrg': 'โปรดเลือกองค์กร',
    'choosePrivilege': 'เลือกสิทธิ์',
    'deletePrivilege': 'ลบสิทธิ์',
    'addPrivilege': '+ เพิ่มสิทธิ์',
    'chooseArea': 'เลือก Area',
    'area': 'ภาค',
    'province': 'จังหวัด',
    'zone': 'โซน',
    'branch': 'สาขา',
    'createArea': 'สร้าง AREA ใหม่',
    'selectRole': 'เลือก Role',
    'chooseRole': 'โปรดเลือก Role',
    'createRole': 'สร้าง Role ใหม่',
    'createUser': 'สร้าง User',
    'personalDetail': 'รายละเอียดส่วนตัว',
    'name': 'ชื่อ-นามสกุล/ร้าน',
    'email': 'อีเมล',
    'phone': 'เบอร์โทรศัพท์',
    'upload': 'อัพโหลดโปรไฟล์',
    'previous': 'ย้อนกลับ',
    'confirm': 'ยืนยัน',
  },
  'en': {
    'addNewUser': 'Add new user',
    'home': 'Home',
    'addNew': 'Add new',
    'warehouse': 'Warehouse',
    'client': 'Client',
    'ae': 'AE (Client)',
    'selectType': 'ประเภท User ที่เลือก',
    'changeType': 'เปลี่ยนประเภท User',
    'selectOrg': 'เลือกองค์กร',
    'chooseOrg': 'โปรดเลือกองค์กร',
    'choosePrivilege': 'เลือกสิทธิ์',
    'deletePrivilege': 'ลบสิทธิ์',
    'addPrivilege': '+ เพิ่มสิทธิ์',
    'chooseArea': 'เลือก Area',
    'area': 'ภาค',
    'province': 'จังหวัด',
    'zone': 'โซน',
    'branch': 'สาขา',
    'createArea': 'สร้าง AREA ใหม่',
    'selectRole': 'เลือก Role',
    'chooseRole': 'โปรดเลือก Role',
    'createRole': 'สร้าง Role ใหม่',
    'createUser': 'สร้าง User',
    'personalDetail': 'รายละเอียดส่วนตัว',
    'name': 'ชื่อ-นามสกุล/ร้าน',
    'email': 'อีเมล',
    'phone': 'เบอร์โทรศัพท์',
    'upload': 'อัพโหลดโปรไฟล์',
    'previous': 'ย้อนกลับ',
    'confirm': 'ยืนยัน',
  },
};
