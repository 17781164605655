import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './SideBar.scss';
import content from './Language';

import commonConstant, { menus } from '../../../common/commonConstant';
import { getCookieLanguage } from '../../../common/commonFunction';
import { Loading } from '../../../components';
import { setLanguage, fetchGetProfile } from '../../../actions';
import { addClassOnElement, removeClassOnElement, haveElement, history } from '../../../helpers';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: { userType: {} },
      menuList: [],
      roleList: [],
    };

    this.props.dispatch(fetchGetProfile());
  }
  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { menus, roles } = nextProps.getProfile;
      const menuList = menus && menus.length > 0 ? menus : [];
      const roleList = roles && roles.length > 0 ? roles : [];
      
      this.setState({ profile: nextProps.getProfile.data, menuList, roleList, isLoading: false });
    }
  }

  handleSize = () => {
    if (haveElement('.SideBar.size-small')) {
      removeClassOnElement('.SideBar.size-small', 'SideBar');
      removeClassOnElement('.box-page.size-small', 'box-page');
      return;
    }
    addClassOnElement('.SideBar', 'size-small');
    addClassOnElement('.box-page', 'size-small');
    return;
  }
  logout = () => {
    if (window.confirm('ต้องการออกจากระบบหรือไม่')) {
      return history.push(commonConstant.pathLogout);
    }
  }

  render() {
    const { stateLanguage } = this.props;
    const { roleList, menuList } = this.state;

    if (!stateLanguage) {
      return <Loading />;
    }

    return (
      <div className="SideBar">
        <div className="box-logo">
          <img className="logo" src={commonConstant.yasLogoWhite} alt="" />
          <span className="icon-menu" onClick={this.handleSize} />
        </div>
        <div className="title-section">
          <span className="text">{content[stateLanguage]['main']}</span>
        </div>
        <NavLink to={commonConstant.pathDashboard} className="box-menu-item" activeClassName="active">
          <span className="menu-icon icon-dashboard" />
          <span className="menu-title">{content[stateLanguage]['dashboard']}</span>
        </NavLink>
        {
          menuList.find(item => item === menus.STOCK_ON_HAND) ?
            <NavLink to={commonConstant.pathStockOnHand} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-factory-stock-house" />
              <span className="menu-title">{content[stateLanguage]['stockOnHand']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.SOH_SERIAL) ?
            <NavLink to={commonConstant.pathSOHSerial} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-soh" />
              <span className="menu-title">{content[stateLanguage]['soh']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.STOCK_AGING) ?
            <NavLink to={commonConstant.pathStockAging} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-stock" />
              <span className="menu-title">{content[stateLanguage]['stockAging']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.ORDER_TRACKING_REPORT) ?
            <NavLink to={commonConstant.pathOrderTrackingReport} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-report" />
              <span className="menu-title">{content[stateLanguage]['orderTrackingReport']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.ORDER_HISTORY) ?
            <NavLink to={commonConstant.pathOrderHistory} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-history" />
              <span className="menu-title">{content[stateLanguage]['orderHistory']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.STOCK_RECEIVE) ?
            <NavLink to={commonConstant.pathStockReceive} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-inbox" />
              <span className="menu-title">{content[stateLanguage]['stockReceive']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.TRACKING) ?
            <NavLink to={commonConstant.pathTracking} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-parcel" />
              <span className="menu-title">{content[stateLanguage]['tracking']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.TRANSPORTATION_PERFORMANCE) ?
            <NavLink to={commonConstant.pathTransport} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-speedometer" />
              <span className="menu-title">{content[stateLanguage]['transportationPerformance']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.PICK_REPORT) ?
            <NavLink to={commonConstant.pathPick} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-hand" />
              <span className="menu-title">{content[stateLanguage]['pick']}</span>
            </NavLink> : null
        }
        {
          menuList.find(item => item === menus.PICK_REPORT) ?
            <NavLink to={commonConstant.pathPack} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-box" />
              <span className="menu-title">{content[stateLanguage]['pack']}</span>
            </NavLink> : null
        }
        <NavLink to={commonConstant.pathOrderStatusTracking} className="box-menu-item" activeClassName="active">
          <span className="menu-icon icon-report" />
          <span className="menu-title">{content[stateLanguage]['orderStatusTracking']}</span>
        </NavLink>
        {
          roleList.find(item => item === menus.SUPER_ADMIN) ?
            <>
              <div className="title-section">
                <span className="text">{content[stateLanguage]['management']}</span>
              </div>
              {/* <NavLink to={commonConstant.pathUserManagementAdd} className="box-menu-item" activeClassName="active">
              <span className="menu-icon icon-add" />
              <span className="menu-title">{content[stateLanguage]['addNewUser']}</span>
            </NavLink> */}
              <NavLink to={commonConstant.pathUserManagement} className="box-menu-item" activeClassName="active">
                <span className="menu-icon icon-user" />
                <span className="menu-title">{content[stateLanguage]['userManagement']}</span>
              </NavLink>
              <NavLink to={commonConstant.pathRoleManagement} className="box-menu-item" activeClassName="active">
                <span className="menu-icon icon-assignment" />
                <span className="menu-title">{content[stateLanguage]['roleManagement']}</span>
              </NavLink>
              <NavLink to={commonConstant.pathAreaManagement} className="box-menu-item" activeClassName="active">
                <span className="menu-icon icon-location" />
                <span className="menu-title">{content[stateLanguage]['areaManagement']}</span>
              </NavLink>
            </> : null
        }

        <div className="title-section">
          <span className="text">{content[stateLanguage]['setting']}</span>
        </div>
        <a onClick={this.logout} className="box-menu-item" activeClassName="active">
          <span className="menu-icon icon-logout" />
          <span className="menu-title">{content[stateLanguage]['logout']}</span>
        </a>
      </div>
    );
  }
}

SideBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  stateLanguage: PropTypes.string,
  getProfile: PropTypes.object,
};

const mapStateToProps = ({ stateLanguage, getProfile }) => ({ stateLanguage: stateLanguage.data, getProfile: getProfile });

export default connect(mapStateToProps)(SideBar);
