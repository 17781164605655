import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { fetchMenberProfile } from '../../actions';
import { checkNull, formatDate } from '../../common/commonFunction';

import './UserManagenemt.scss';

const UserManagenemtProfile = ({ match }) => {
  const [params, setParams] = useState({});
  const [userProfile, setUserProfile] = useState({
    userType: {},
    organization: { name: '' },
    userRoles: [],
  });
  const getMenberProfile = useSelector(({ getMenberProfile }) => getMenberProfile.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match && Object.keys(match.params).length > 0) {
      setParams(match.params);
      dispatch(fetchMenberProfile(match.params.code));
    }
  }, [match]);
  useEffect(() => {
    if (getMenberProfile) {
      setUserProfile(getMenberProfile);
    }
  }, [getMenberProfile]);

  return (
    <div className="UserManagenemtProfile">
      <div className="template-with-sidebar-navbar">
        <div className="template-white-box mb-4">
          <div className="row mb-3">
            <div className="col h4">ข้อมูลส่วนตัว</div>
            <div className="col-1"><Link to={`./${params.code}/edit`}>Edit</Link></div>
          </div>
          <div className="row mb-3">
            <div className="col-4 form-user">
              <small>ประเภท user </small>
              <p>{userProfile.userType.name}</p>
            </div>
            <div className="col form-user">
              <small>องค์กร</small>
              <p>{userProfile.organization.name}</p>
            </div>
          </div>
          <div className="row align-items-center">
            {/* <div className="form-user-avetar">
              <div className="user-box-avetar"></div>
            </div> */}
            <div className="col form-user">
              <small>ชื่อ - นามสกุล</small>
              <p>{userProfile.name}</p>
            </div>
            <div className="col form-user">
              <small>อีเมลล์</small>
              <p>{userProfile.email}</p>
            </div>
            <div className="col form-user">
              <small>เบอร์โทรศัพท์</small>
              <p>PHONE</p>
            </div>
          </div>
          <div className="row bg-light">
            <div className="col-4 form-user">
              <small>สร้างเมื่อ</small>
              <p>{formatDate(userProfile.createdAt)}</p>
            </div>
            <div className="col form-user">
              <small>สร้างโคย</small>
              <p>Super Administrator</p>
            </div>
            <div className="col form-user">
              <small>การแก้ไข</small>
              <p>ล่าสุด {formatDate(userProfile.createdAt)}</p>
            </div>
          </div>
          <div className="row-role mb-4">
            <div className="h6">User roles</div>
            {
              userProfile.userLocations && userProfile.userLocations.length
                ? userProfile.userLocations.map((item, key) => (
                  <div key={key} className="role-detail mb-3">
                    <div className="role-detail-number">สิทธิ์ {key + 1}</div>
                    <div className="form-user">
                      <small>ภาค</small>
                      <p>{item.region ? item.region.regionName : '-'}</p>
                    </div>
                    <div className="form-user">
                      <small>โชน</small>
                      <p>{item.zone ? item.zone.zoneName : '-'}</p>
                    </div>
                    <div className="form-user">
                      <small>สาขา</small>
                      <p>{item.branch ? item.branch.branchName : '-'}</p>
                    </div>
                    <div className="form-user">
                      <small>Role</small>
                      <p>{item.userRoles && item.userRoles.length ? item.userRoles.name : '-'}</p>
                    </div>
                  </div>
                ))
                : userProfile.userRoles.map((item, key) => (
                  <div key={key} className="role-detail mb-3">
                    <div className="role-detail-number">สิทธิ์ {key + 1}</div>
                    <div className="form-user">
                      <small>ภาค</small>
                      <p>-</p>
                    </div>
                    <div className="form-user">
                      <small>โชน</small>
                      <p>-</p>
                    </div>
                    <div className="form-user">
                      <small>สาขา</small>
                      <p>-</p>
                    </div>
                    <div className="form-user">
                      <small>Role</small>
                      <p>{item.role.name}</p>
                    </div>
                  </div>
                ))
            }
          </div>
          <div className="row">
            <div className="col">
              สถานะ { userProfile.isActive ? <span className="text-success">Active</span> : <span>inActive</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserManagenemtProfile.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default UserManagenemtProfile;
