import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';

export const fetchOrderHistory = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/OrderHistoryReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_ORDER_HISTORY_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchOrderDetails = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/OrderDetails',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_ORDER_DETAILS_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_DETAILS_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_DETAILS_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Client', 'Order Date/Time',	'Order Number', 'Customer Code', 'Customer Name',	'Address', 'Region', 'BOX', 'Order QTY']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].client, formatDate(data[i].orderDateTime), data[i].orderNo, data[i].customerCode, data[i].customerName, data[i].address, data[i].region, data[i].boxQty, data[i].orderQty]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'OrderHistory.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'OrderHistory.xlsx');
  }
};

export const fetchOrderHistoryExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/OrderHistoryExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, type);

        return dispatch({
          'type': commonConstant.GET_ORDER_HISTORY_CSV_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_ORDER_HISTORY_CSV_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_CSV_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_CSV_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBookDetail = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Client', 'warehous', 'Order Date/Time',	'Order Number', 'Customer Code', 'Customer Name',	'Province', 'Region', 'Product Number', 'Product Desc', 'Imei', 'QTY']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].client, data[i].warehous, formatDate(data[i].orderDateTime), data[i].orderNo, data[i].customerCode, data[i].customerName, data[i].province, data[i].region, data[i].productNumber, data[i].productDesc, data[i].imei, data[i].qty]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'OrderHistoryDetails.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'OrderHistoryDetails.xlsx');
  }
};

export const fetchOrderHistoryDetailExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/OrderHistoryExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBookDetail(data.data, type);

        return dispatch({
          'type': commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
