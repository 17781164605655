import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';

export default async data => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 1000 ? data.length : 1000;
  ws.addRow(['Role Name', 'Role UUID', 'Organization', 'Organization Code', 'Status']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].name, data[i].organizationRoleId, data[i].organization.name, data[i].organization.code, data[i].isActive ? 'Active' : 'Inactive']);
  }

  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), 'RoleManagement.xlsx');
};
