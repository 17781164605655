import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';

import { Dropdowns } from '../../helpers';
import { fetchOrganizationsById, fetchRegion, fetchZoneByClient, fetchBranchAdd } from '../../actions';

import './AreaManagementAdd.scss';
import { Link } from 'react-router-dom';

const AreaManagementAdd = ({ match, history }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState();
  const [organ, setOrgan] = useState([]);
  const [regionType, setRegionType] = useState([]);
  const [zoneType, setZoneType] = useState([]);
  const [zoneClient, setZoneClient] = useState([]);
  const getOrganizationsById = useSelector(({ getOrganizationsById }) => getOrganizationsById.data);
  const getZoneByClient = useSelector(({ getZoneByClient }) => getZoneByClient.data);
  const getRegion = useSelector(({ getRegion }) => getRegion.data);
  const getBranchAdd = useSelector(({ getBranchAdd }) => getBranchAdd);
  const [model, setModel] = useState({
    region: {
      text: '',
      value: '',
    },
    zone: {
      text: '',
      value: '',
    },
    branch: '',
    client: '',
    role: '',
    current: 1,
  });

  useEffect(() => {
    if (match) { 
      setParams(match.params);
      dispatch(fetchOrganizationsById(match.params.code));
      dispatch(fetchZoneByClient(match.params.code));
    }
  }, [match]);
  useEffect(() => {
    if (getOrganizationsById) {setOrgan(getOrganizationsById);}
  }, [getOrganizationsById]);
  useEffect(() => {
    if (getBranchAdd && getBranchAdd.status === 200 && model.branch) {
      history.goBack('./');
    }
  }, [getBranchAdd]);
  useEffect(() => {
    if (getZoneByClient) {
      const items = getZoneByClient.map(item => {
        item.text = item.zoneName;
        item.value = item.zoneId;
        return item;
      });

      setZoneClient(items);
    }
  }, [getZoneByClient]);
  useEffect(() => {
    if (getRegion) {
      const items = getRegion.map(item => {
        item.text = item.regionName;
        item.value = item.regionId;
        return item;
      });

      setRegionType(items);
    }
    else {dispatch(fetchRegion());}
  }, [getRegion]);

  const handleSelectRegion = value => {
    const zoneItem = zoneClient.filter(item => item.regionId === value.regionId);
    setZoneType(zoneItem);
    setModel(Object.assign({ ...model }, { region: { text: value.regionName, value: value.regionId } }));
  };
  const handleSelectZone = value => {
    setModel(Object.assign({ ...model }, { zone: { text: value.zoneName, value: value.zoneId } }));
  };
  const addBlance = () => {
    if (model.zone.value && model.branch) {
      const params = {
        branchName: model.branch,
        branchCode: 'BRANCH_CODE',
        zoneId: model.zone.value,
      };
      
      dispatch(fetchBranchAdd(params));
    } else {
      alert('เกิดข้อผิดพลาด');
    }
  };
  
  return (
    <div className="AreaManagementAdd">
      <div className="template-with-sidebar-navbar">
        <div className="template-white-box mb-3">
          <div className="area-org-header">
            <div className="area-org-l">
              <div className="area-item-title">Add new area</div>
            </div>
            {/* <div className="area-org-r">
              <span>Edit</span>
            </div> */}
          </div>
          <div className="area-form-title mb-3">รายละเอียด Area</div>
          <div className="row mb-3">
            <div className="col-2">องค์กร</div>
            <div className="col-6">
              <div className="organize-info">
                <div className="organize-info-logo"><img src={organ.logo} alt="" /></div>
                <div className="organize-info-title">{organ.name}</div>
              </div>
            </div>
          </div>
          <div className="area-form-title mb-3">Add new area</div>
          <div className="row mb-3">
            <div className="col-2">ภาค</div>
            <div className="col-5">
              <Dropdowns
                placeholder="เลือกภาค"
                items={regionType}
                selected={model.region}
                disabled={true}
                handleSelect={value => handleSelectRegion(value)} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-2">โซน</div>
            <div className="col-5">
              <Dropdowns
                placeholder="เลือกโซน"
                items={zoneType}
                selected={model.zone}
                disabled={zoneType.length > 0}
                handleSelect={value => handleSelectZone(value)} />
            </div>
            {
              model.region.value
                ? <div className="col-2"><Link to={`./${model.region.value ? model.region.value : ''}`}>Add Zone</Link></div>
                : null
            }
          </div>
          <div className="row mb-3">
            <div className="col-2">สาขา</div>
            <div className="col-5"><Input onChange={e => setModel(Object.assign({ ...model }, { branch: e.target.value }))} value={model.branch} /></div>
          </div>
        </div>
        <div className="section-footer text-right">
          <Button onClick={() => history.goBack('./')} color="link">ย้อนกลับ</Button>
          <Button onClick={addBlance} color="secondary">ยืนยัน</Button>
        </div>
      </div>
    </div>
  );
};

AreaManagementAdd.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default AreaManagementAdd;
