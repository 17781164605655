import { createBrowserHistory } from 'history';
import Vars from '../common/commonConstant';
import { createCookie, getCookie } from '../helpers';
import exptWorkBook from './ExportWorks';

const fetchRefreshToken = async () => {
  const token = getCookie(Vars.cookieToken);
  try {
    const res = await fetch(`${Vars.yasUserServiceAPI}/users/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({ ttl: 21600 }),
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': token ? token : null,
      }),
    });
    if (res.status === 200) {
      return await createCookie('token', token, 3);
    } else if (res.status === 401) {
      return (window.location = '/login');
    } else if (res.status === 500 || res.status === 502) {
      return (window.location = '/warning');
    }
    return;
  } catch (err) {
    return;
  }
};
const checkStatus = async (dispatch, type, res, isType) => {
  try {
    const data = await res.json();
    if (res.status === 200 || res.status === 201 || res.status === 422) {
      if (isType === 'EXPORT') { await exptWorkBook(data); }

      if (isType !== 'FILE') { fetchRefreshToken(); }

      return dispatch({ type: type.success, data, status: res.status });
    } else if (res.status === 401) {
      window.location = '/login';
    } else if (res.status === 500 || res.status === 502) {
      window.location = '/warning';
    }
    return dispatch({
      type: type.failure,
      data: null,
      status: res.status ? res.status : res,
    });
  } catch (error) {
    return dispatch({
      type: type.success,
      data: null,
      status: 204,
    });
  }
};
const error = (dispatch, type) => {
  createBrowserHistory('/oop');
  dispatch({
    type: type.failure,
    data: null,
    status: 500,
  });
};
export const get = async (dispatch, type, url, filter, expFile) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const paramsFormat = filter
      ? `?filter=${encodeURIComponent(JSON.stringify(filter))}`
      : '';
    const res = await fetch(`${url + paramsFormat}`, {
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getCookie(Vars.cookieToken),
      }),
    });
    checkStatus(dispatch, type, res, expFile ? 'EXPORT' : null);
  } catch (err) {
    return error(dispatch, type);
  }
};
export const post = async (dispatch, type, url, params, isFile, isToken) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const res = await fetch(`${url}`, {
      method: 'POST',
      body: params ? isFile ? params : JSON.stringify(params) : null,
      headers: isFile
        ? new Headers({
          Authorization: getCookie(Vars.cookieToken),
          Enctype: 'multipart/form-data',
        })
        : new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': isToken ? '' : getCookie(Vars.cookieToken),
        }),
    });
  
    checkStatus(dispatch, type, res, 'FILE');
  } catch (err) {
    return error(dispatch, type);
  }
};
export const patch = async (dispatch, type, url, params) => {
  dispatch({ type: type.loading, status: 'PENDING' });
  try {
    const res = await fetch(`${url}`, {
      method: 'PATCH',
      body: params ? JSON.stringify(params) : null,
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getCookie(Vars.cookieToken),
      }),
    });

    checkStatus(dispatch, type, res);
  } catch (err) {
    return error(dispatch, type);
  }
};
