import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import './Dropdowns.scss';

export default class Dropdowns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'showDropdowns': false,
    };
    this.dropdowns = createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.dropdowns.current && !this.dropdowns.current.contains(event.target)) {
      return this.setState({ 'showDropdowns': false });
    }
    return;
  };

  handleShowDropdowns = () => this.setState({ 'showDropdowns': !this.state.showDropdowns });

  handleItems = () => {
    const { items, image, selected } = this.props;

    if (items) {
      return items.map(ele => (
        <div className={`item${selected === ele ? ' active' : ''}`} key={Math.random()} onClick={() => this.handleSelect(ele)}>
          {
            image ?
              ele.image ?
                <img className="image" src={ele.image} alt="" />
                : <span className="dummy-image">
                  <span>{ele.label ? ele.label[0] : ele.value ? ele.value[0] : null}</span>
                </span>
              : null
          }
          <span>{ele.text ? ele.text : ele.value}</span>
        </div>
      ));
    }
    return <div className="dummy" />;
  }

  handleSelect = selected => {
    this.props.handleSelect(selected);
    this.handleShowDropdowns();
    return;
  }

  render() {
    const { placeholder, selected, image, disabled } = this.props;
    const { showDropdowns } = this.state;

    return (
      <div className="Dropdowns" ref={this.dropdowns}>
        <div className={`box-dropdowns ${showDropdowns ? ' show' : ''} ${disabled ? '' : 'no-drop'}`} onClick={disabled ? this.handleShowDropdowns : null}>
          {
            selected && (selected.value || selected.text) ?
              <div className="text">
                {
                  image ?
                    selected.image ?
                      <img className="image" src={selected.image} alt="" />
                      : <span className="dummy-image">
                        <span>{selected.text ? selected.text[0] : selected.value ? selected.value[0] : null}</span>
                      </span>
                    : null
                }
                <span>{selected.text ? selected.text : selected.value}</span>
              </div>
              : <span className="placeholder">{placeholder}</span>
          }
          <span className={`icon ${showDropdowns ? 'icon-arrow-top-t' : 'icon-arrow-down-t'}`} />
        </div>
        <div className={`box-items${showDropdowns ? ' show' : ''}`}>
          {this.handleItems()}
        </div>
      </div>
    );
  }
}

Dropdowns.propTypes = {
  'placeholder': PropTypes.string,
  'selected': PropTypes.shape({
    'value': PropTypes.any,
    'text': PropTypes.string.isRequired,
    'image': PropTypes.string,
  }),
  'items': PropTypes.array,
  'handleSelect': PropTypes.func,
  'image': PropTypes.bool,
  'disabled': PropTypes.bool,
};
