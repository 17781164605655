import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const fetchClientType = () => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': '',
            'masterType': 'client',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_CLIENT_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_CLIENT_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_CLIENT_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchDeliveryZoneType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'deliveryZone',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_DELIVERY_ZONE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_DELIVERY_ZONE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_DELIVERY_ZONE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchLateStatusType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'lateStatus',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_LATE_STATUS_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_LATE_STATUS_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_LATE_STATUS_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchDayType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'dayType',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_DAY_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_DAY_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_DAY_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchWareHouseType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'wareHouse',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_WARE_HOUSE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_WARE_HOUSE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_WARE_HOUSE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchProductGroupType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'group',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_PRODUCT_GROUP_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PRODUCT_GROUP_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PRODUCT_GROUP_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchBrandType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'brand',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_BRAND_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_BRAND_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_BRAND_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchReceiveType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'receiveType',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_RECEIVE_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_RECEIVE_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_RECEIVE_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchAgingDayType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'agingDay',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_AGING_DAY_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_AGING_DAY_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_AGING_DAY_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchTransportType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'transportStatus',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_TRANSPORT_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORT_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRANSPORT_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchCarrierType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'carrier',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_CARRIER_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_CARRIER_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_CARRIER_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchPackType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'packType',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_PACK_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PACK_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PACK_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

export const fetchPickType = clientType => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/getMasterData',
          'param': {
            'client': clientType ? clientType : '',
            'masterType': 'pickType',
          },
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_PICK_TYPE_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PICK_TYPE_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_PICK_TYPE_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
