import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';

export const fetchStockAging = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/StockAgingReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_STOCK_AGING_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STOCK_AGING_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STOCK_AGING_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Warehouse', 'Product Group', 'Brand', 'Product Number', 'Product Description', 'Stock on hand', '7 Day', '7-15 Days', '15-30 Days', '30-60 Days', '60-90 Days', '90-180 Days', '180 Days', '1 years']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].warehouse, data[i].productGroup, data[i].brand, data[i].productNumber, data[i].productDescription, data[i].stockOnHand, data[i].day1_7, data[i].day8_15, data[i].day16_30, data[i].day31_60, data[i].day61_90, data[i].day91_180, data[i].day_180, data[i].year_1]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'StockAging.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'StockAging.xlsx');
  }
};

export const fetchStockAgingExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/StockAgingExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, type);

        return dispatch({
          'type': commonConstant.GET_STORCK_AGING_EXPORT_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_STORCK_AGING_EXPORT_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_STORCK_AGING_EXPORT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STORCK_AGING_EXPORT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
