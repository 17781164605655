import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';

export const fetchStockReceiveReport = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/StockReceiveReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_STOCK_RECEIVE_REPORT_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STOCK_RECEIVE_REPORT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STOCK_RECEIVE_REPORT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Warehouse', 'Receive Type', 'Receipt No.', 'Supplier Ref No.', 'Product Group', 'Brand', 'Products No.', 'Products Description', 'QTY', 'Receive Date']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].warehouse, data[i].receiveType, data[i].receiptNo, data[i].supplierRefNo, data[i].productGroup, data[i].brand, data[i].productNumber, data[i].productDesc, data[i].qty, formatDate(data[i].receiveDate)]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'StockReceive.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'StockReceive.xlsx');
  }
};

export const fetchStockReceiveExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/StockReceiveExport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, type);

        return dispatch({
          'type': commonConstant.GET_STOCK_RECEIVE_EXPORT_SUCCESS,
          'data': 'OK',
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          'type': commonConstant.GET_STOCK_RECEIVE_EXPORT_FAILURE,
          'data': 'OK',
          'status': 500,
          'dateTime': new Date(),
        });
      }
      return dispatch({
        'type': commonConstant.GET_STOCK_RECEIVE_EXPORT_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_STOCK_RECEIVE_EXPORT_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
