import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './Navbar.scss';

import commonConstant from '../../../common/commonConstant';
import { getCookieLanguage/*, getCookieYASToken */ } from '../../../common/commonFunction';
import { setLanguage/*, fetchGetProfile */ } from '../../../actions';
import { Loading, RefreshToken } from '../../../components';
import { namingUserType, history } from '../../../helpers';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      'userName': null,
      'avatar': null,
    };
  }
  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getProfile } = this.props;

    if (nextProps.getProfile && nextProps.getProfile !== getProfile) {
      this.setState({ 'userName': nextProps.getProfile.name, 'avatar': nextProps.getProfile.avatar });
      if (nextProps.getProfile.forceChangePassword) {
        return history.push(commonConstant.pathForceChangePassword);
      }
    }
  }

  handleTitle = () => {
    const { stateLanguage, 'match': { path, 'params': { type } } } = this.props;

    switch (path) {
      case commonConstant.pathAddNewUser:
        return 'Add new user';
      case commonConstant.pathAddNewUserByType:
        if (namingUserType(stateLanguage, type)) {
          return `Add new user - ${namingUserType(stateLanguage, type)}`;
        }
        return null;
      case commonConstant.pathAddNewUserSuccess:
        if (namingUserType(stateLanguage, type)) {
          return 'Add new user - Success';
        }
        return null;
      case commonConstant.pathProfile:
        return 'Profile';
      case commonConstant.pathOrderTrackingReport:
      case commonConstant.pathOrderTrackingReportNew:
        return 'Order tracking report';
      case commonConstant.pathOrderHistory:
        return 'Order history';
      case commonConstant.pathStockOnHand:
        return 'Stock on hand';
      case commonConstant.pathSOHSerial:
        return 'SOH serial';
      case commonConstant.pathStockReceive:
        return 'Stock receive';
      case commonConstant.pathStockAging:
        return 'Stock aging';
      case commonConstant.pathTracking:
        return 'Tracking';
      case commonConstant.pathTransport:
      case commonConstant.pathTransportNew:
        return 'Transportation performance';
      case commonConstant.pathPick:
        return 'Pick';
      case commonConstant.pathPack:
        return 'Pack';
      case commonConstant.pathDashboard:
        return 'Dashboard';
      case commonConstant.pathAreaManagement:
        return 'Area management';
      case commonConstant.pathAreaManagementAdd:
        return 'Add new area';
      case commonConstant.pathAreaOrganizeDetail:
        return 'Area organize detail';
      case commonConstant.pathAreaOrganizeEdit:
        return 'Area management organize detail';
      case commonConstant.pathRoleManagementAdd:
        return 'Add role management';
      case commonConstant.pathRoleManagementEdit:
        return 'Edit role management';
      case commonConstant.pathRoleManagementView:
        return 'View role management';
      case commonConstant.pathRoleManagement:
        return 'Role management';
      case commonConstant.pathUserManagement:
      case commonConstant.pathUserManagementView:
        return 'Users management';
      case commonConstant.pathUserManagementAdd:
        return 'Add news Users list';
      case commonConstant.pathUserManagementEdit:
        return 'Edit Users';
      case commonConstant.pathOrderStatusTracking:
        return 'Order Status Tracking Report';
      default:
        return null;
    }
  }

  handleIcon = () => {
    const { stateLanguage, 'match': { path, 'params': { type } } } = this.props;

    switch (path) {
      case commonConstant.pathAddNewUser:
        return 'icon-add';
      case commonConstant.pathAddNewUserByType:
        if (namingUserType(stateLanguage, type)) {
          return 'icon-add';
        }
        return null;
      case commonConstant.pathAddNewUserSuccess:
        if (namingUserType(stateLanguage, type)) {
          return 'icon-add';
        }
        return null;
      case commonConstant.pathProfile:
        return 'icon-person';
      case commonConstant.pathOrderTrackingReport:
      case commonConstant.pathOrderTrackingReportNew:
      case commonConstant.pathOrderStatusTracking:
        return 'icon-report';
      case commonConstant.pathOrderHistory:
        return 'icon-history';
      case commonConstant.pathStockOnHand:
        return 'icon-factory-stock-house';
      case commonConstant.pathSOHSerial:
        return 'icon-soh';
      case commonConstant.pathStockReceive:
        return 'icon-inbox';
      case commonConstant.pathStockAging:
        return 'icon-stock';
      case commonConstant.pathTracking:
        return 'icon-parcel';
      case commonConstant.pathTransport:
      case commonConstant.pathTransportNew:
        return 'icon-speedometer';
      case commonConstant.pathPick:
        return 'icon-hand';
      case commonConstant.pathPack:
        return 'icon-box';
      case commonConstant.pathDashboard:
        return 'icon-dashboard';
      case commonConstant.pathAreaManagement:
      case commonConstant.pathAreaManagementAdd:
      case commonConstant.pathAreaOrganizeDetail:
      case commonConstant.pathAreaOrganizeEdit:
        return 'icon-location';
      case commonConstant.pathRoleManagementAdd:
      case commonConstant.pathRoleManagementView:
      case commonConstant.pathRoleManagementEdit:
      case commonConstant.pathRoleManagement:
        return 'icon-assignment';
      case commonConstant.pathUserManagement:
      case commonConstant.pathUserManagementAdd:
      case commonConstant.pathUserManagementView:
      case commonConstant.pathUserManagementEdit:
        return 'icon-user';
      default:
        return null;
    }
  }

  handleMenu = () => {
    const { stateLanguage, 'match': { path, 'params': { type } } } = this.props;

    switch (path) {
      case commonConstant.pathAddNewUser:
        return commonConstant.defaultPathMenu.addNewUser.join(' / ');
      case commonConstant.pathAddNewUserByType:
        if (namingUserType(stateLanguage, type)) {
          return [...commonConstant.defaultPathMenu.addNewUser, namingUserType(stateLanguage, type)].join(' / ');
        }
        return null;
      case commonConstant.pathAddNewUserSuccess:
        if (namingUserType(stateLanguage, type)) {
          return commonConstant.defaultPathMenu.addNewUserSuccess.join(' / ');
        }
        return null;
      case commonConstant.pathProfile:
        return commonConstant.defaultPathMenu.profile.join(' / ');
      case commonConstant.pathOrderTrackingReport:
        return commonConstant.defaultPathMenu.orderTrackingReport.join(' / ');
      case commonConstant.pathOrderHistory:
        return commonConstant.defaultPathMenu.orderHistory.join(' / ');
      case commonConstant.pathStockOnHand:
        return commonConstant.defaultPathMenu.stockOnHand.join(' / ');
      case commonConstant.pathSOHSerial:
        return commonConstant.defaultPathMenu.sohSerial.join(' / ');
      case commonConstant.pathStockReceive:
        return commonConstant.defaultPathMenu.stockReceive.join(' / ');
      case commonConstant.pathStockAging:
        return commonConstant.defaultPathMenu.stockAging.join(' / ');
      case commonConstant.pathTracking:
        return commonConstant.defaultPathMenu.tracking.join(' / ');
      case commonConstant.pathTransport:
        return commonConstant.defaultPathMenu.transport.join(' / ');
      case commonConstant.pathPick:
        return commonConstant.defaultPathMenu.pick.join(' / ');
      case commonConstant.pathPack:
        return commonConstant.defaultPathMenu.pack.join(' / ');
      case commonConstant.pathDashboard:
        return commonConstant.defaultPathMenu.dashboard.join(' / ');
      case commonConstant.pathAreaManagement:
        return commonConstant.defaultPathMenu.areaManagement.join(' / ');
      case commonConstant.pathAreaManagementAdd:
        return commonConstant.defaultPathMenu.areaManagementAdd.join(' / ');
      case commonConstant.pathAreaOrganizeDetail:
        return commonConstant.defaultPathMenu.areaOrganizeDetail.join(' / ');
      case commonConstant.pathAreaOrganizeEdit:
        return commonConstant.defaultPathMenu.areaOrganizeEdit.join(' / ');
      case commonConstant.pathRoleManagement:
        return commonConstant.defaultPathMenu.roleManagement.join(' / ');
      case commonConstant.pathRoleManagementView:
        return commonConstant.defaultPathMenu.roleManagementView.join(' / ');
      case commonConstant.pathRoleManagementAdd:
        return commonConstant.defaultPathMenu.roleManagementAdd.join(' / ');
      case commonConstant.pathRoleManagementEdit:
        return commonConstant.defaultPathMenu.roleManagementEdit.join(' / ');
      case commonConstant.pathUserManagement:
      case commonConstant.pathUserManagementView:
        return commonConstant.defaultPathMenu.userManagement.join(' / ');
      case commonConstant.pathUserManagementAdd:
        return commonConstant.defaultPathMenu.userManagementAdd.join(' / ');
      case commonConstant.pathUserManagementEdit:
        return commonConstant.defaultPathMenu.userManagementEdit.join(' / ');
      case commonConstant.pathOrderStatusTracking:
        return commonConstant.defaultPathMenu.orderStatusTracking.join(' / ');
      default:
        return null;
    }
  }

  render() {
    const { stateLanguage } = this.props; // getProfile
    const { userName, avatar } = this.state;

    if (!stateLanguage /* || !getProfile */) {
      return <Loading />;
    }

    return (
      <div className="Navbar">
        <RefreshToken />
        <div className="box-detail">
          <div className="box-title">
            <span className={`icon ${this.handleIcon()}`} />
            <div>
              <div className="menu-title">{this.handleTitle()}</div>
              <div className="box-menu">
                {this.handleMenu()}
              </div>
            </div>
          </div>
        </div>
        <div className="box-profile">
          {/* <img className="image" src={commonConstant.userDummy} alt="" /> */}
          {
            avatar ?
              <img className="image" src={avatar} alt="" />
              : <img className="image" src={commonConstant.userDummy} alt="" />
          }
          {userName ? <span className="text">{userName}</span> : null}
        </div>
      </div>
    );
  }
}

Navbar.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'match': PropTypes.shape({
    'path': PropTypes.string,
    'params': PropTypes.shape({
      'type': PropTypes.string,
      'code': PropTypes.string,
      'id': PropTypes.string,
    }).isRequired,
  }).isRequired,
  'stateLanguage': PropTypes.string,
  'getProfile': PropTypes.shape({
    'forceChangePassword': PropTypes.bool,
    'name': PropTypes.string,
    'avatar': PropTypes.string,
  }),
};

const mapStatusToProps = ({ stateLanguage, getProfile }) => ({
  'stateLanguage': stateLanguage.data,
  'getProfile': getProfile.data,
});

export default connect(mapStatusToProps)(withRouter(Navbar));
