import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';
import Types from '../Types';

export const fetchOrderTrackingReport = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderTrackingReport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          type: commonConstant.GET_ORDER_TRACKING_REPORT_SUCCESS,
          data,
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        type: commonConstant.GET_ORDER_TRACKING_REPORT_FAILURE,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        type: commonConstant.GET_ORDER_TRACKING_REPORT_FAILURE,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);
export const fetchOrderTrackingNew = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderTrackingReport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          type: Types.orderTrackingNew.success,
          data,
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        type: Types.orderTrackingNew.failure,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        type: Types.orderTrackingNew.failure,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);
export const fetchOrderStatusTracking = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderStatusTrackingReport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          type: Types.orderStatus.success,
          data,
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        type: Types.orderStatus.failure,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        type: Types.orderStatus.failure,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);

const exportWorkBook = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Order Number','Customer Number', 'Customer Name', 'Delivery Zone', 'QTY', 'Order Date/Time', 'Latest Staus']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].orderNumber, data[i].customerNo, data[i].customerName, data[i].zone, data[i].qty, formatDate(data[i].orderDateTime), data[i].latestStaus]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'OrderTracking.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'OrderTracking.xlsx');
  }
};
const exportWorkBookNew = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Order Number','Customer Number', 'Customer Name', 'Delivery Zone', 'QTY', 'Order Date/Time',
    'Picked', 'Packed', 'Ready for ship', 'In Transit', 'Delivered', 'Undelivered', 'Latest Staus']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].orderNumber, data[i].customerNo, data[i].customerName, data[i].zone, data[i].qty, formatDate(data[i].orderDateTime), 
      formatDate(data[i].picked), formatDate(data[i].packed), formatDate(data[i].readyForShip), formatDate(data[i].inTransit), formatDate(data[i].delivered), formatDate(data[i].unDelivered), data[i].latestStaus]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'OrderTracking.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'OrderTracking.xlsx');
  }
};
const exportWorkBookNew2 = async (data, type) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet();
  const count = data.length < 60000 ? data.length : 60000;
  ws.addRow(['Order Number','Customer Number', 'Customer Name', 'Delivery Zone', 'QTY', 'Order Date/Time',
    'Picked', 'Packed', 'Ready for ship', 'In Transit', 'Delivered', 'Undelivered', 'Latest Staus', 'In Transit Status', 'Retry To Deliver Status']);
  for (let i = 0; i < count; i++) {
    ws.addRow([data[i].orderNumber, data[i].customerNo, data[i].customerName, data[i].zone, data[i].qty, formatDate(data[i].orderDateTime), 
      formatDate(data[i].picked), formatDate(data[i].packed), formatDate(data[i].readyForShip), formatDate(data[i].inTransit), formatDate(data[i].delivered), formatDate(data[i].unDelivered), data[i].latestStaus, data[i].delivery_SLA, data[i].retrytostatus]);
  }

  let buf = null;
  if (type === 'CSV') {
    buf = await wb.csv.writeBuffer();
    saveAs(new Blob([buf]), 'OrderStatusTracking.csv');
  } else {
    buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'OrderStatusTracking.xlsx');
  }
};

export const fetchOrderTrackingExport = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderTrackingExport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBook(data.data, type);

        return dispatch({
          type: commonConstant.GET_ORDER_TRACKING_EXPORT_SUCCESS,
          data: 'OK',
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          type: commonConstant.GET_ORDER_TRACKING_EXPORT_SUCCESS,
          data: 'OK',
          status: 500,
          dateTime: new Date(),
        });
      }
      return dispatch({
        type: commonConstant.GET_ORDER_TRACKING_EXPORT_FAILURE,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      return dispatch({
        type: commonConstant.GET_ORDER_TRACKING_EXPORT_FAILURE,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);

export const fetchOrderTrackingExportNew = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderTrackingExport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBookNew(data.data, type);

        return dispatch({
          type: Types.orderTrackingExportNew.success,
          data: 'OK',
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          type: Types.orderTrackingExportNew.success,
          data: 'OK',
          status: 500,
          dateTime: new Date(),
        });
      }
      return dispatch({
        type: Types.orderTrackingExportNew.failure,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      return dispatch({
        type: Types.orderTrackingExportNew.failure,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);

export const fetchOrderStatusTrackingExportNew = (params, type) => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          url:'/post/OrderStatusTrackingExport',
          param: params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        await exportWorkBookNew2(data.data, type);

        return dispatch({
          type: Types.orderStatusExport.success,
          data: 'OK',
          status: res.status,
          dateTime: new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return dispatch({
          type: Types.orderStatusExport.success,
          data: 'OK',
          status: 500,
          dateTime: new Date(),
        });
      }
      return dispatch({
        type: Types.orderStatusExport.failure,
        data: null,
        status: res.status ? res.status : res,
        dateTime: new Date(),
      });
    } catch (err) {
      return dispatch({
        type: Types.orderStatusExport.failure,
        data: null,
        status: err.status ? err.status : err,
        dateTime: new Date(),
      });
    }
  }
);
