import commonConstant from '../../common/commonConstant';

export const getOrderTrackingReport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_TRACKING_REPORT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_TRACKING_REPORT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getOrderTrackingExport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_TRACKING_EXPORT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_TRACKING_EXPORT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
