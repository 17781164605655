import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './ChangePasswordSuccess.scss';
import content from './Language';

import commonConstant from '../../common/commonConstant';
import { getCookieLanguage } from '../../common/commonFunction';
import { setLanguage } from '../../actions';

class ChangePasswordSuccess extends Component {
  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  render() {
    const { stateLanguage } = this.props;

    return (
      <div className="ChangePasswordSuccess template-center">
        <form className="box-page">
          <img className="logo" src={commonConstant.yasLogo} alt="" />
          <div className="box-form">
            <div className="icon icon-check-circle" />
            <div className="title">{content[stateLanguage]['title']}</div>
            <div className="description">{content[stateLanguage]['description']}</div>
            <Link className="btn btn-primary" to={commonConstant.pathLogout}>
              {content[stateLanguage]['btn']}
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

ChangePasswordSuccess.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
};

const mapStateToProps = ({ stateLanguage }) => ({ 'stateLanguage': stateLanguage.data });

export default connect(mapStateToProps)(ChangePasswordSuccess);
