import React from 'react';
import PropTypes from 'prop-types';

export const WhiteBox = props => (
  <div className="template-white-box">
    <div className="template-title">
      {props.title}
      {props.req ? <span className="req">*</span> : null}
    </div>
    {props.hr ? <hr className="hr" /> : null}
    {props.children}
  </div>
);

WhiteBox.propTypes = {
  title: PropTypes.any,
  req: PropTypes.bool,
  hr: PropTypes.bool,
  children: PropTypes.any,
};
