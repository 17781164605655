import React from 'react';

import './Loading.scss';

import CommonConstant from '../../../common/commonConstant';

export default () => (
  <div className="Loading">
    <div className="box">
      <div className="box-loading">
        <img className="image-loading" src={CommonConstant.loading} alt="" />
        <img className="image-logo" src={CommonConstant.yasLogo} alt="" />
      </div>
    </div>
  </div>
);
