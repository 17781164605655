import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';

import './AddNewUserByType.scss';
import content from './Language';

import commonConstant from '../../common/commonConstant';
import { getCookieLanguage } from '../../common/commonFunction';
import { Loading } from '../../components';
import { NotFound } from '../../pages';
import {
  Dropdowns,
  ButtonWithIcon,
  WhiteBox,
  history,
  ConvertMegabyte2Byte,
  // passParamsToPath,
  namingUserType,
} from '../../helpers';
import { setLanguage } from '../../actions';

const dummySelect = [{ 'label': 'aaaa', 'value': 'eiei', 'image': commonConstant.yasLogoSmall }, { 'value': 'eiei' }];

class AddNewUserByType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'selectOrg': null,
      'choosePrivilege': [{ ...commonConstant.defaultChoosePrivilege }],
      'name': '',
      'email': '',
      'phone': '',
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const { 'match': { params } } = this.props;
  //   TODO: check props and redirect to
  //   if () {
  //     return history.push(passParamsToPath(commonConstant.pathAddNewUserSuccess, params));
  //   }
  // }

  handleEventSelect = (key, value) => this.setState({ [key]: value });

  handleEventPrivilege = (index, key, value) => {
    const { choosePrivilege } = this.state;

    choosePrivilege[index][key] = value;
    this.setState({ choosePrivilege });
  }

  handleTitlePrivilege = index => {
    const { stateLanguage } = this.props;
    const { choosePrivilege } = this.state;

    return (
      <div className="box-title-privilege">
        <span>{content[stateLanguage]['choosePrivilege']}#{index + 1}</span>
        {
          choosePrivilege.length > 1 ?
            <span className="box-delete-privilege" onClick={() => this.handleDeletePrivilege(index)}>
              <span className="icon icon-delete" />
              <span>{content[stateLanguage]['deletePrivilege']}</span>
            </span>
            : null
        }
      </div>
    );
  }

  handleShowPrivilege = () => {
    const { stateLanguage } = this.props;
    const { choosePrivilege } = this.state;

    return choosePrivilege.map((ele, i) => (
      <WhiteBox title={this.handleTitlePrivilege(i)} hr key={Math.random()}>
        <div className="box-select-area">
          <span className="title-choice">{content[stateLanguage]['chooseArea']}</span>
          <div className="box-item-select">
            <span className="item-select">
              <Dropdowns
                placeholder={content[stateLanguage]['area']}
                items={dummySelect}
                selected={ele.area}
                handleSelect={value => this.handleEventPrivilege(i, 'area', value)}
              />
            </span>
            <span className="item-select">
              <Dropdowns
                placeholder={content[stateLanguage]['province']}
                items={dummySelect}
                selected={ele.province}
                handleSelect={value => this.handleEventPrivilege(i, 'province', value)}
              />
            </span>
            <span className="item-select">
              <Dropdowns
                placeholder={content[stateLanguage]['zone']}
                items={dummySelect}
                selected={ele.zone}
                handleSelect={value => this.handleEventPrivilege(i, 'zone', value)}
              />
            </span>
            <span className="item-select">
              <Dropdowns
                placeholder={content[stateLanguage]['branch']}
                items={dummySelect}
                selected={ele.branch}
                handleSelect={value => this.handleEventPrivilege(i, 'branch', value)}
              />
            </span>
            <span className="item-select">
              {/* TODO: action */}
              <ButtonWithIcon text={content[stateLanguage]['createArea']} icon="icon-add" className="btn-thirdary" />
            </span>
          </div>
        </div>
        <div className="box-select-role">
          <span className="title-choice">{content[stateLanguage]['selectRole']}</span>
          <div className="select-role">
            <div className="role">
              <Dropdowns
                placeholder={content[stateLanguage]['chooseRole']}
                items={dummySelect}
                selected={ele.role}
                handleSelect={value => this.handleEventPrivilege(i, 'role', value)}
              />
            </div>
            {/* TODO: action */}
            <ButtonWithIcon text={content[stateLanguage]['createRole']} icon="icon-add" className="btn-thirdary" />
          </div>
        </div>
      </WhiteBox>
    ));
  }

  handleDeletePrivilege = index => {
    const { choosePrivilege } = this.state;

    choosePrivilege.splice(index, 1);
    return this.setState({ 'choosePrivilege': choosePrivilege });
  }

  handleAddPrivilege = () => this.setState({ 'choosePrivilege': [...this.state.choosePrivilege, { ...commonConstant.defaultChoosePrivilege }] });

  handleClickUploadfile = () => document.getElementById('dummy-upload').click();

  handleUploadfile = event => {
    if (event.target.files[0] && event.target.files[0].size <= ConvertMegabyte2Byte(5)) {
      // TODO: call api upload file
    }
  }

  handleValidate = () => {
    const { selectOrg, name, email, phone } = this.state;

    if (selectOrg && name && email && phone) {
      // TODO: call api create user
      return true;
    }
    return false;
  }

  handleEventInput = (event, key) => this.setState({ [key]: event.target.value });

  render() {
    const { stateLanguage, 'match': { 'params': { type } } } = this.props;
    const { selectOrg, name, email, phone } = this.state;

    if (!stateLanguage) {
      return <Loading />;
    } else if (!namingUserType(stateLanguage, type)) {
      return (
        <div className="template-with-sidebar-navbar">
          <NotFound />
        </div>
      );
    }

    return (
      <div className="AddNewUserByType">
        <div className="template-with-sidebar-navbar">
          {/* section select type */}
          <WhiteBox title={content[stateLanguage]['selectType']} hr>
            <div className="box-select-type">
              <Input value={namingUserType(stateLanguage, type)} className="select-type" disabled />
              {/* TODO: update link */}
              <Link to={commonConstant.pathAddNewUser} className="link">{content[stateLanguage]['changeType']}</Link>
            </div>
          </WhiteBox>
          {/* section select organize */}
          <WhiteBox title={content[stateLanguage]['selectOrg']} req hr>
            <div className="box-select-org">
              <span className="title-choice">{content[stateLanguage]['selectOrg']}</span>
              <div className="select-org">
                <div className="org">
                  <Dropdowns
                    placeholder={content[stateLanguage]['chooseOrg']}
                    items={dummySelect}
                    selected={selectOrg}
                    handleSelect={value => this.handleEventSelect('selectOrg', value)}
                    image
                  />
                </div>
              </div>
            </div>
          </WhiteBox>
          {/* section privilege */}
          <div className="box-select-privilege">
            {this.handleShowPrivilege()}
            <div className="box-add-privilage" onClick={this.handleAddPrivilege}>
              <span className="link">{content[stateLanguage]['addPrivilege']}</span>
            </div>
          </div>
          {/* section user */}
          <WhiteBox title={content[stateLanguage]['createUser']} req hr>
            <div className="box-user">
              <div className="unit-title">{content[stateLanguage]['personalDetail']}</div>
              <div className="box-detail">
                <div className="left">
                  <div className="item">
                    <span className="title-choice">{content[stateLanguage]['name']}</span>
                    <Input className="select-type" placeholder="ex. Wahyu Fatur Rizki" value={name} onChange={event => this.handleEventInput(event, 'name')} />
                  </div>
                  <div className="item">
                    <span className="title-choice">{content[stateLanguage]['email']}</span>
                    <Input className="select-type" placeholder="mail@example.com" value={email} onChange={event => this.handleEventInput(event, 'email')} />
                  </div>
                  <div className="item">
                    <span className="title-choice">{content[stateLanguage]['phone']}</span>
                    <Input className="select-type" placeholder="ex. 06xxxxxxxx" value={phone} onChange={event => this.handleEventInput(event, 'phone')} />
                  </div>
                </div>
                <div className="right">
                  <div className="box-bg" onClick={this.handleClickUploadfile}>
                    <span className="icon fas fa-camera" />
                    <span className="text">{content[stateLanguage]['upload']}</span>
                  </div>
                  <Input id="dummy-upload" className="dummy" type="file" accept="image/x-png,image/gif,image/jpeg,image/jpg" onChange={this.handleUploadfile} />
                </div>
              </div>
            </div>
          </WhiteBox>
          {/* section button */}
          <div className="box-button">
            <ButtonWithIcon
              handleClick={() => history.push(commonConstant.pathAddNewUser)}
              className="btn-secondary" text={content[stateLanguage]['previous']}
              icon="icon-arrow-left"
            />
            <ButtonWithIcon
              className="btn-primary"
              text={content[stateLanguage]['confirm']}
              icon="icon-check"
              handleClick={this.handleValidate}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddNewUserByType.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'match': PropTypes.shape({
    'params': PropTypes.shape({
      'type': PropTypes.string,
    }).isRequired,
  }).isRequired,
  'stateLanguage': PropTypes.string,
};

const mapStateToProps = ({ stateLanguage }) => ({ 'stateLanguage': stateLanguage.data });

export default connect(mapStateToProps)(AddNewUserByType);
