import React from 'react';

const ChartLine = ({ type, percent, txt }) => (
  <div className={`_div_chart ${type}`}>
    <div className="_chart_percent">
      <div className="item_percent" style={{ width: `${percent}%` }}></div>
    </div>
    <div className="_chart_txt">{txt ? txt : `${percent}%`}</div>
  </div>
);

export default ChartLine;
