import React from 'react';
import Chart from 'react-apexcharts';
const pendingColors = ['#34D1BF', '#0496FF', '#6665DD', '#FF715B', '#DADADA'];

const ChartAndLegend = ({ title, series, labels }) => {
  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels,
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    colors: pendingColors,
    legend: {
      formatter: e => e.length > 9 ? `${e.substr(0, 9)}..` : e,
    },
  };
  return (
    <div className="ChartAndLegend">
      <Chart options={options} series={series} type="pie" width={'100%'} height="172" />
      <p>{title}</p>
    </div>
  );
};

export default ChartAndLegend;
