import commonConstant from '../../common/commonConstant';

export const login = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.LOGIN_SUCCESS:
      return { ...state, ...action };
    case commonConstant.LOGIN_FAILURE:
      return { ...state, ...action };
    case commonConstant.LOGOUT_SUCCESS:
      return {};
    case commonConstant.LOGOUT_FAILURE:
      return {};
    default:
      return state;
  }
};

export const logout = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.LOGOUT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.LOGOUT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const refreshToken = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.REFRESH_TOKEN_SUCCESS:
      return { ...state, ...action };
    case commonConstant.REFRESH_TOKEN_FAILURE:
      return { ...state, ...action };
    case commonConstant.LOGIN_SUCCESS:
      return {};
    case commonConstant.LOGIN_FAILURE:
      return {};
    case commonConstant.LOGOUT_SUCCESS:
      return {};
    case commonConstant.LOGOUT_FAILURE:
      return {};
    default:
      return state;
  }
};

export const forgotPassword = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.FORGOT_PASSWORD_SUCCESS:
      return { ...state, ...action };
    case commonConstant.FORGOT_PASSWORD_FAILURE:
      return { ...state, ...action };
    case commonConstant.LOGIN_SUCCESS:
      return {};
    case commonConstant.LOGIN_FAILURE:
      return {};
    case commonConstant.LOGOUT_SUCCESS:
      return {};
    case commonConstant.LOGOUT_FAILURE:
      return {};
    default:
      return state;
  }
};

export const changePassword = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.CHANGE_PASSWORD_SUCCESS:
      return { ...state, ...action };
    case commonConstant.CHANGE_PASSWORD_FAILURE:
      return { ...state, ...action };
    case commonConstant.LOGIN_SUCCESS:
      return {};
    case commonConstant.LOGIN_FAILURE:
      return {};
    case commonConstant.LOGOUT_SUCCESS:
      return {};
    case commonConstant.LOGOUT_FAILURE:
      return {};
    default:
      return state;
  }
};
