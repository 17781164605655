import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import { Button, Input, Table } from 'reactstrap';
import { 
  fetchTracking,
} from '../../actions';

import { WhiteBox, InputWithIcon, ThSorting } from '../../helpers';
import commonConstant from '../../common/commonConstant';
import { formatDate } from '../../common/commonFunction';

import { Loading, RefreshToken } from '../../components';

class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'serialNo': '',
      'uploadFile': null,
      'base64File': null,
      'tracking': '',
      'typeSort': '',
      'column': '',
      'current': 1,
      'isLoading': false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getTracking && nextProps.getTracking !== this.props.getTracking) {
      this.setState({ 'tracking': nextProps.getTracking, 'isLoading': false });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current, serialNo } = this.state;
    
    if (serialNo) {
      this.setState({ 'column': await column, 'typeSort': await typeSort });
      this.handleSearch(current);
    }
  }
  onChangeFile = async e => {
    const files = await e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onloadend = () => {
      this.setState({ 'uploadFile': files, 'base64File': reader.result });
      document.getElementById('openFile').value = '';
    };
  }
  onUploadFile = () => {
    const { base64File } = this.state;
    if (base64File) {
      this.setState({ 'isLoading': true, 'current': 1, 'typeSort': '', 'column': '' });

      const params = {
        'client': '',
        'serialNo': '',
        'uploadFile': base64File,
        'searchProductId': '',
        'orderBy': '',
        'orderType': '',
        'pageNumber': 1,
        'pageSize': commonConstant.pagination.itemsCountPerPage,
      };
      this.props.dispatch(fetchTracking(params));
    } else {
      alert('Please upload file');
    }
  }
  clsFile = () => this.setState({ 'uploadFile': null });
  handleTitle = () => (
    <div className="sch-btn">
      <div className="box-title">
        <span className="title">Search</span>
        <InputWithIcon
          onChange={event => this.handleSelect('serialNo', event.target.value)}
          value={this.state.serialNo}
          icon="icon-search"
          placeholder="ex. Accessor"
        />
      </div>
      <div className="box-btn">
        <Button className={ this.state.serialNo ? 'btn-primary' : 'disabled'} onClick={ this.state.serialNo ? () => this.handleSearch(this.state.current, true) : null }>
          Browse
          <span className="icon icon-search" />
        </Button>
      </div>
    </div>
  );
  handleFile = () => (
    <>
      <div className="sch-btn">
        <div className="box-upload">
          <span className="title">Upload Serial list</span>
          { !this.state.uploadFile ? <label className="btn-upload" htmlFor="openFile">Select File (CSV, XLSX)</label> : null }
          { this.state.uploadFile
            ? <span className="show-file-name">{this.state.uploadFile.name} <i className="menu-icon icon-close" onClick={this.clsFile} /></span>
            : null }
          <Input className="hide" id="openFile" type="file" onChange={this.onChangeFile}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
        </div>
        <div className="box-btn">
          <Button className="btn-light" onClick={this.onUploadFile}>
            Upload
            <span className="icon icon-upload" />
          </Button>
        </div>
      </div>
      <div className="example-csv-excel">
        Download Example File : 
        <a href="/files/csv-example.csv"><i className="menu-icon icon-csv" /> CSV</a>, {' '}
        <a href="/files/excel-example.xlsx"><i className="menu-icon icon-xlsx" /> Excel</a>
      </div>
    </>
  );
  handleSelect = (key, value) => this.setState({ [key]: value });
  handleSearch = (current, isSch) => {
    this.setState({ 'isLoading': true });
    const { serialNo, typeSort, column } = this.state;
    if (isSch) {this.setState({ current, 'typeSort': '', 'column': '' });}

    const params = {
      'client': '',
      'serialNo': serialNo,
      'uploadFile': '',
      'searchProductId': '',
      'orderBy': typeSort ? column : '',
      'orderType': typeSort,
      'pageNumber': current,
      'pageSize': commonConstant.pagination.itemsCountPerPage,
    };
    this.props.dispatch(fetchTracking(params));
  }
  exportWorkBook = async type => {
    const { 'tracking': { data } } = this.state;
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet();
    const count = data.length < 60000 ? data.length : 60000;
    ws.addRow(['Serial No. 1', 'Serial No. 2', 'Ref No.', 'Product Number', 'Product Description', 'Warehouse', 'Location', 'Date/Time', 'Status']);
    for (let i = 0; i < count; i++) {
      ws.addRow([data[i].serialNo1, data[i].serialNo2, data[i].refNo, data[i].productNumber, data[i].productDescription, data[i].warehous, data[i].location, formatDate(data[i].adjustDateTime), data[i].status]);
    }

    let buf = null;
    if (type === 'CSV') {
      buf = await wb.csv.writeBuffer();
      saveAs(new Blob([buf]), 'Tracking.csv');
    } else {
      buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), 'Tracking.xlsx');
    }
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  checkNull = data => data ? data : '-';

  render() {
    const {
      tracking,
      current,
      column,
      isLoading,
    } = this.state;
    
    return (
      <div className="Tracking">
        {isLoading ? <Loading /> : null}
        <div className="template-with-sidebar-navbar tracking-navbar">
          <WhiteBox title={this.handleTitle()} />
          <div className="mb-4" />
          <WhiteBox title={this.handleFile()} />

          {
            tracking ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportWorkBook('CSV')} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportWorkBook('XLS')} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                  </div>
                  <div className="overall-detail">
                    <div className="o-item">
                      <span>Total SN</span>
                      <p>{tracking ? tracking.total[0].totalSN.toLocaleString() : 0}</p>
                    </div>
                    <div className="o-item">
                      <span>Total Units</span>
                      <p>{tracking ? tracking.total[0].totalUnit.toLocaleString() : 0}</p>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Serial No. 1" name="serialNo1" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Serial No. 2" name="serialNo2" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Ref No." name="refNo" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Number" name="productNumber" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Product Description" name="productDescription" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Warehouse" name="warehous" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Location" name="location" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Date/Time" name="adjustDateTime" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Status" name="status" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tracking ? tracking.data.map((item, key) => (
                          <tr key={key}>
                            <td>{item.serialNo1}</td>
                            <td>{item.serialNo2}</td>
                            <td>{item.refNo}</td>
                            <td>{item.productNumber}</td>
                            <td>{this.checkNull(item.productDescription)}</td>
                            <td>{this.checkNull(item.warehous)}</td>
                            <td>{this.checkNull(item.location)}</td>
                            <td>{formatDate(item.adjustDateTime)}</td>
                            <td>{item.status}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>
          
                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                    Showing {current === 1 ? current : (current - 1) * commonConstant.pagination.itemsCountPerPage}{' '}
                    to {current === 1 ? commonConstant.pagination.itemsCountPerPage : (current - 1) * commonConstant.pagination.itemsCountPerPage + commonConstant.pagination.itemsCountPerPage}{' '}
                    of {tracking ? tracking.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={commonConstant.pagination.itemsCountPerPage}
                      pageRangeDisplayed={commonConstant.pagination.pageRangeDisplayed}
                      totalItemsCount={tracking ? tracking.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>
        <RefreshToken />
      </div>
    );
  }
}

Tracking.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'getTracking': PropTypes.func,
};

const mapStateToProps = state => ({
  'getTracking': state.getTracking.data,
});

export default connect(mapStateToProps)(Tracking);
