export default {
  'th': {
    'title': 'SUCCESS',
    'description': 'Please back to login page',
    'btn': 'BACK TO LOGIN',
  },
  'en': {
    'title': 'SUCCESS',
    'description': 'Please back to login page',
    'btn': 'BACK TO LOGIN',
  },
};
