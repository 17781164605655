import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './AddNewUser.scss';
import content from './Language';

import commonConstant from '../../common/commonConstant';
import { getCookieLanguage } from '../../common/commonFunction';
import { Loading } from '../../components';
import { setLanguage } from '../../actions';
import { RadioButton, ButtonWithIcon, passParamsToPath, history, WhiteBox } from '../../helpers';

class AddNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'selectType': null,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  handleSelectType = selectType => this.setState({ selectType });

  handleActive = key => {
    const { selectType } = this.state;

    switch (key) {
      case selectType:
        return true;
      default:
        return false;
    }
  }

  handleRedirect = () => {
    const { selectType } = this.state;
    const { stateLanguage } = this.props;

    if (selectType) {
      switch (selectType) {
        case content[stateLanguage]['warehouse']:
          return history.push(passParamsToPath(commonConstant.pathAddNewUserByType, { 'type': commonConstant.typePathWarehouse }));
        case content[stateLanguage]['client']:
          return history.push(passParamsToPath(commonConstant.pathAddNewUserByType, { 'type': commonConstant.typePathClient }));
        case content[stateLanguage]['ae']:
          return history.push(passParamsToPath(commonConstant.pathAddNewUserByType, { 'type': commonConstant.typePathAE }));
        default:
          return false;
      }
    }
    return;
  }

  render() {
    const { stateLanguage } = this.props;

    if (!stateLanguage) {
      return <Loading />;
    }

    return (
      <div className="AddNewUser">
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={content[stateLanguage]['selectType']} req hr>
            <div className="box-select-radio-button">
              <span className="box-unit">{content[stateLanguage]['type']}</span>
              <span className="box-choice">
                <RadioButton
                  active={this.handleActive(content[stateLanguage]['warehouse'])}
                  text={content[stateLanguage]['warehouse']}
                  handleSelect={() => this.handleSelectType(content[stateLanguage]['warehouse'])}
                />
                <RadioButton
                  active={this.handleActive(content[stateLanguage]['client'])}
                  text={content[stateLanguage]['client']}
                  handleSelect={() => this.handleSelectType(content[stateLanguage]['client'])}
                />
                <RadioButton
                  active={this.handleActive(content[stateLanguage]['ae'])}
                  text={content[stateLanguage]['ae']}
                  handleSelect={() => this.handleSelectType(content[stateLanguage]['ae'])}
                />
              </span>
            </div>
          </WhiteBox>
          <div className="box-button">
            {/* TODO: back to default page */}
            <ButtonWithIcon className="btn-secondary" text={content[stateLanguage]['cancel']} icon="icon-close" />
            <ButtonWithIcon
              className="btn-primary"
              text={content[stateLanguage]['next']}
              icon="icon-arrow-right"
              handleClick={this.handleRedirect}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddNewUser.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
};

const mapStateToProps = ({ stateLanguage }) => ({ 'stateLanguage': stateLanguage.data });

export default connect(mapStateToProps)(AddNewUser);
