import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import './ButtonWithIcon.scss';

const ButtonWithIcon = props => (
  <div className="ButtonWithIcon">
    <Button className={props.className} onClick={props.handleClick}>
      <span className="text">{props.text}</span>
      <span className={`icon ${props.icon}`} />
    </Button>
  </div>
);

ButtonWithIcon.propTypes = {
  'className': PropTypes.string,
  'text': PropTypes.string,
  'icon': PropTypes.string,
  'handleClick': PropTypes.func,
};

export default ButtonWithIcon;
