import commonConstant from '../../common/commonConstant';

export const getOrderHistory = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_HISTORY_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_HISTORY_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
export const getOrderDetails = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_DETAILS_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_DETAILS_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
export const getOrderHistoryExport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_HISTORY_CSV_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_HISTORY_CSV_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
export const getOrderHistoryDetailExport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
