export default {
  'th': {
    'addNewUser': 'Add new user - Success',
    'home': 'Home',
    'addNew': 'Add new',
    'success': 'Success',
    'title': 'เพิ่ม User',
    'title2': 'เสร็จสมบูรณ์',
    'description': 'คุณสามารถจัดการ User ได้ที่เมนู user management',
    'back': 'ไปหน้า user management',
    'backHome': 'หน้าหลัก',
  },
  'en': {
    'addNewUser': 'Add new user - Success',
    'home': 'Home',
    'addNew': 'Add new',
    'success': 'Success',
    'title': 'เพิ่ม User',
    'title2': 'เสร็จสมบูรณ์',
    'description': 'คุณสามารถจัดการ User ได้ที่เมนู user management',
    'back': 'ไปหน้า user management',
    'backHome': 'หน้าหลัก',
  },
};
