import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchClientView } from '../../actions';
import { ChartLine, ChartBox, CardPieAndPercent } from '../../components';
const colors = ['#34D1BF', '#FF715B', '#FED70D'];
const colorsDetail = ['#34D1BF', '#F2994A'];

const Client = () => {
  const [orderToday, setOrderToday] = useState([]);
  const [todayYesterday, setTodayYesterday] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const getClientView = useSelector(({ getClientView }) => getClientView.data);
  const getProfile = useSelector(({ getProfile }) => getProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getClientView) {
      const { orderStatusToday, orderStatusTodayYesterday, deliveryStatus } = getClientView;

      setOrderToday(orderStatusToday);
      setTodayYesterday(orderStatusTodayYesterday);
      setDelivery(deliveryStatus);
    } else if (getProfile) {
      const client = getProfile.clients.map(i => i.value).join();
      const params = {
        url: '/post/ClientViewDashBoard',
        param: {
          client,
          // client: _client ? _client : client ? client.value : '',
        },
      };
      dispatch(fetchClientView(params));
    }
  }, [getClientView, getProfile]);
  useEffect(() => {
    const interval = setInterval(() => {
      const params = {
        url: '/post/ClientViewDashBoard',
      };
      dispatch(fetchClientView(params));
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  const formatNumber = res => {
    const [a, b] = res.split('/');
    return `${parseInt(a).toLocaleString()}/${parseInt(b).toLocaleString()}`;
  };
  const checkToday = day => day === new Date().toISOString().split('T')[0] ? 'Today' : day;
  
  return (
    <div className="Client">
      <div className="c-overall">
        {
          orderToday.map((item, key) => (
            <ChartBox key={key} header={item.orderStatus} total={item.totalOrder} unit={item.totalUnit} percent={item.bkkPercent} />
          ))
        }
      </div>
      <div className="c-detail">
        <div className="c-detail-header">
          <div><i style={{ background: colorsDetail[0] }} /> Today</div>
          <div><i style={{ background: colorsDetail[1] }} /> Yesterday</div>
        </div>
        <table className="db-table">
          <thead>
            <tr>
              <td></td>
              <td>Order/Unit</td>
              <td>Picked</td>
              <td>Packed</td>
              <td>Ready to ship</td>
              <td>In transit</td>
              <td>Delivered</td>
            </tr>
          </thead>
          <tbody>
            {
              todayYesterday.map((item, key) => (
                <tr key={key}>
                  <td className="_w3">{item.region}</td>
                  <td>
                    <div className="txt_success">{formatNumber(item.orderUnit.totalOrder.today)}</div>
                    <div className="txt_winning">{formatNumber(item.orderUnit.totalOrder.yesterday)}</div>
                  </td>
                  <td className="_w18">
                    <ChartLine type="_success" percent={item.orderUnit.picking.today} />
                    <ChartLine type="_winning" percent={item.orderUnit.picking.yesterday} />
                  </td>
                  <td className="_w18">
                    <ChartLine type="_success" percent={item.orderUnit.packing.today} />
                    <ChartLine type="_winning" percent={item.orderUnit.packing.yesterday} />
                  </td>
                  <td className="_w18">
                    <ChartLine type="_success" percent={item.orderUnit.readyToShip.today} />
                    <ChartLine type="_winning" percent={item.orderUnit.readyToShip.yesterday} />
                  </td>
                  <td className="_w18">
                    <ChartLine type="_success" percent={item.orderUnit.intransit.today} />
                    <ChartLine type="_winning" percent={item.orderUnit.intransit.yesterday} />
                  </td>
                  <td className="_w18">
                    <ChartLine type="_success" percent={item.orderUnit.delivered.today} />
                    <ChartLine type="_winning" percent={item.orderUnit.delivered.yesterday} />
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="c-pie-card">
        <div className="c-pie-card-header">
          <div className="div-cpie-l">
            <div className="_title">Delivery status</div>
          </div>
          <div className="div-cpie-r">
            <div><i style={{ background: colors[0] }} /> Complete</div>
            <div><i style={{ background: colors[1] }} /> Packing</div>
            <div><i style={{ background: colors[2] }} /> Intransit</div>
          </div>
        </div>
        <div className="c-pie-card-body">
          {
            delivery.map((item, key) => (
              <div key={key} className="c-pie-item">
                <CardPieAndPercent label={item.totalPercentComplete} series={[parseInt(item.totalPercentComplete), parseInt(item.totalPercentWH), parseInt(item.totalPercentTS)]} />
                <div className="_title">{parseInt(item.totalOrderDelivery).toLocaleString()} Total order</div>
                <div className="_detail">{checkToday(item.date)}</div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default Client;
