import React from 'react';

const ChartBox = ({ header, total, unit, percent, isChangeTxt }) => {
  // const formatTxt = txt => {
  //   switch (txt) {
  //     // case 'Delivered':
  //     //   return 'Total order';
  //     case 'Received Order':
  //       return 'Total order';
  //     default:
  //       return txt
  //   }
  // }
  return (
    <div className="c-item">
      <div className="c-type">{header === 'Received Order' && !isChangeTxt ? 'TOTAL ORDER' : header}</div>
      {/* <div className="c-type">{formatTxt(header)}</div> */}
      <div className="c-total">{total.toLocaleString()} {header === 'Received Order' ? <small>Order</small> : ''}</div>
      {
        unit 
          ? <div className="c-unit">{unit ? unit.toLocaleString() :''} Unit.</div>
          : <div className="c-unit">&nbsp;</div>
      }
      {
        percent ?
          <div className="c-chart">
            <div className="c-txt">
              <div className="l-txt">BKK {percent}%</div>
              <div className="r-txt">UCP {100 - percent}%</div>
            </div>
            <div className="c-success" style={{ width: `${percent}%` }}></div>
            <div className="c-winning" style={{ width: `${100 - percent}%` }}></div>
          </div> : ''
      }
    </div>
  );
};

export default ChartBox;
