import { combineReducers } from 'redux';
import { login, logout, refreshToken, forgotPassword, changePassword } from './Authen';
import { stateLanguage } from './Language';
import { getProfile } from './Profile';
import { getOrderTrackingReport, getOrderTrackingExport } from './OrderTrackingReport';
import { 
  getOrderHistory, 
  getOrderDetails, 
  getOrderHistoryExport,
  getOrderHistoryDetailExport,
} from './OrderHistory';
import { getSOHReport, getSOHSerial, getSOHExport } from './SOH';
import { 
  getProductGroupType, 
  getBrandType, 
  getReceiveType,
  getClientType, 
  getDeliveryZoneType, 
  getLateStatusType, 
  getDayType, 
  getWareHouseType,
  getAgingDayType,
  getTransporType,
  getCarrierType,
  getPickType,
  getPackType,
} from './MasterData';
import { getStockReceiveReport, getStockReceiveExport } from './StockReceive';
import { getStockAging, getStockAgingExport } from './StockAging';
import { getTracking } from './Tracking';
import { getPick, getPickExport } from './Pick';
import { getPack, getPackExport } from './Pack';
import { getTransportation, getTransportationCsv, getTransportationXls } from './Transportation';

import Types from '../actions/Types';
const checkAction = (state, action, type) => {
  switch (action.type) {
    case type.success:
    case type.failure:
    case type.loading:
      return Object.assign({}, state, { ...action });
    default:
      return state;
  }
};

const getOrganizations = (state = {}, action) => checkAction(state, action, Types.organizations);
const getOrganizationsById = (state = {}, action) => checkAction(state, action, Types.organizationsById);
const getOrganizationsRole = (state = {}, action) => checkAction(state, action, Types.organizationsRole);
const getOrganizationsRoleOrgan = (state = {}, action) => checkAction(state, action, Types.organizationsRoleOrgon);
const getOrganizationsRoleSearch = (state = {}, action) => checkAction(state, action, Types.organizationsRoleSearch);
const getOrganizationsRegion = (state = {}, action) => checkAction(state, action, Types.organizationsRegion);
const getRegion = (state = {}, action) => checkAction(state, action, Types.region);
const getProvinces = (state = {}, action) => checkAction(state, action, Types.provinces);
const getZoneAdd = (state = {}, action) => checkAction(state, action, Types.zoneAdd);
const getZoneByClient = (state = {}, action) => checkAction(state, action, Types.zoneByClient);
const getZoneProvinces = (state = {}, action) => checkAction(state, action, Types.zoneProvinces);
const getZoneProvincesAdd = (state = {}, action) => checkAction(state, action, Types.zoneProvincesAdd);
const getBranchAdd = (state = {}, action) => checkAction(state, action, Types.branchAdd);
const getBranchClient = (state = {}, action) => checkAction(state, action, Types.branchClient);
const getRoleAdd = (state = {}, action) => checkAction(state, action, Types.rolesAdd);
const getRoleCnt = (state = {}, action) => checkAction(state, action, Types.rolesCnt);
const getRoleUpdate = (state = {}, action) => checkAction(state, action, Types.rolesUpdate);
const getRoleType = (state = {}, action) => checkAction(state, action, Types.roleType);
const getRoleList = (state = {}, action) => checkAction(state, action, Types.roleList);
const getUsersList = (state = {}, action) => checkAction(state, action, Types.usersList);
const getUsersSearch = (state = {}, action) => checkAction(state, action, Types.usersSearch);
const getUsersListCnt = (state = {}, action) => checkAction(state, action, Types.usersListCnt);
const getUsersListCntSch = (state = {}, action) => checkAction(state, action, Types.usersListCntSch);
const getUserType = (state = {}, action) => checkAction(state, action, Types.userType);
const getUserListAdd = (state = {}, action) => checkAction(state, action, Types.usersListAdd);
const getLocations = (state = {}, action) => checkAction(state, action, Types.locations);
const getMenberProfile = (state = {}, action) => checkAction(state, action, Types.memberProfile);
const getUpdateUserRole = (state = {}, action) => checkAction(state, action, Types.updateUserRole);
const getUpdateUserLocal = (state = {}, action) => checkAction(state, action, Types.updateUserLocations);
const getExportRole = (state = {}, action) => checkAction(state, action, Types.exportRole);
const getClientView = (state = {}, action) => checkAction(state, action, Types.clientView);
const getOpView = (state = {}, action) => checkAction(state, action, Types.opView);
const getHighView = (state = {}, action) => checkAction(state, action, Types.highView);
const getResetPass = (state = {}, action) => checkAction(state, action, Types.resetPass);
const getOrderTrackingNew = (state = {}, action) => checkAction(state, action, Types.orderTrackingNew);
const getTransportNew = (state = {}, action) => checkAction(state, action, Types.transportNew);
const getTransportExportNew = (state = {}, action) => checkAction(state, action, Types.transportExportNew);
const getOrderStatus = (state = {}, action) => checkAction(state, action, Types.orderStatus);
const getOrderStatusExport = (state = {}, action) => checkAction(state, action, Types.orderStatusExport);
const getOrderTrackingExportNew = (state = {}, action) => checkAction(state, action, Types.orderTrackingExportNew);

export default combineReducers({
  // authen
  login,
  logout,
  refreshToken,
  forgotPassword,
  changePassword,
  // language
  stateLanguage,
  // profile
  getProfile,
  // Order Tracking Report
  getOrderTrackingReport,
  getOrderTrackingExport,
  getOrderTrackingNew,
  getTransportNew,
  getTransportExportNew,
  // Order history
  getOrderHistory,
  getOrderDetails,
  getOrderHistoryExport,
  getOrderHistoryDetailExport,
  // Stock on hand
  getSOHReport,
  getSOHSerial,
  getSOHExport,
  // Get master type
  getClientType,
  getDeliveryZoneType,
  getLateStatusType,
  getDayType,
  getWareHouseType,
  getProductGroupType,
  getBrandType,
  getReceiveType,
  getAgingDayType,
  getTransporType,
  getCarrierType,
  getPickType,
  getPackType,
  // Get Stock Receive Report
  getStockReceiveReport,
  getStockReceiveExport,
  getStockAging,
  getStockAgingExport,
  // Get Tracking
  getTracking,
  // Get Transportation
  getTransportation,
  getTransportationCsv, 
  getTransportationXls,
  // Get Pick
  getPick,
  getPickExport,
  // Get Pack
  getPack,
  getPackExport,
  // User management
  getOrganizations,
  getOrganizationsById,
  getOrganizationsRole,
  getOrganizationsRoleOrgan,
  getOrganizationsRoleSearch,
  getOrganizationsRegion,
  getRegion,
  getProvinces,
  getZoneAdd,
  getZoneByClient,
  getZoneProvinces,
  getZoneProvincesAdd,
  getBranchAdd,
  getBranchClient,
  getUserType,
  getLocations,
  getRoleAdd,
  getRoleCnt,
  getRoleUpdate,
  getRoleList,
  getUserListAdd,
  getUsersList,
  getUsersSearch,
  getUsersListCnt,
  getUsersListCntSch,
  getMenberProfile,
  getRoleType,
  getUpdateUserRole,
  getUpdateUserLocal,
  getExportRole,
  getClientView,
  getOpView,
  getHighView,
  getResetPass,
  getOrderStatus,
  getOrderStatusExport,
  getOrderTrackingExportNew,
});
