import {
  fetchLogin,
  fetchLogout,
  fetchRefreshToken,
  fetchForgotPassword,
  fetchChangePassword,
} from './Authen';
import { setLanguage } from './Language';
import { fetchGetProfile } from './Profile';
import { 
  fetchOrderTrackingReport, 
  fetchOrderTrackingExport,
  fetchOrderTrackingExportNew,
  fetchOrderStatusTrackingExportNew,
  fetchOrderStatusTracking,
  fetchOrderTrackingNew } from './OrderTrackingReport';
import { 
  fetchOrderHistory, 
  fetchOrderDetails, 
  fetchOrderHistoryExport,
  fetchOrderHistoryDetailExport,
} from './OrderHistory';
import { fetchSOHReport, fetchSOHSerial, fetchHOSExport } from './SOH';
import { 
  fetchProductGroupType, 
  fetchBrandType, 
  fetchReceiveType, 
  fetchClientType, 
  fetchDeliveryZoneType, 
  fetchLateStatusType, 
  fetchDayType, 
  fetchWareHouseType,
  fetchAgingDayType,
  fetchCarrierType,
  fetchTransportType,
  fetchPickType,
  fetchPackType,
} from './MasterData';
import { fetchStockReceiveReport, fetchStockReceiveExport } from './StockReceive';
import { fetchStockAging, fetchStockAgingExport } from './StockAging';
import { fetchTracking } from './Tracking';
import { fetchPick, fetchPickExport } from './Pick';
import { fetchPack, fetchPackExport } from './Pack';
import { 
  fetchTransportation, 
  fetchTransportationCsv, 
  fetchTransportationXls,
  fetchTransportationNew,
  fetchTransportationExportNew,
} from './Transportation';

import * as Services from './Services';
import Types from './Types';
import Vars from '../common/commonConstant';

const fetchOrganizations = () => dispatch => Services.get(dispatch, Types.organizations, `${Vars.yasUserServiceAPI}/organizations`);
const fetchOrganizationsById = code => dispatch => Services.get(dispatch, Types.organizationsById, `${Vars.yasUserServiceAPI}/organizations/${code}`);
const fetchOrganizationsRole = filter => dispatch => Services.get(dispatch, Types.organizationsRole, `${Vars.yasUserServiceAPI}/organization-roles`, filter);
const fetchOrganizationsRoleOrgan = organId => dispatch => Services.get(dispatch, Types.organizationsRoleOrgon, `${Vars.yasUserServiceAPI}/organization-roles/roles/${organId}`);
const fetchOrganizationsRoleSearch = params => dispatch => Services.post(dispatch, Types.organizationsRoleSearch, `${Vars.yasUserServiceAPI}/organization-roles/search`, params);
const fetchOrganizationsRegion = organId => dispatch => Services.get(dispatch, Types.organizationsRegion, `${Vars.yasUserServiceAPI}/organizations/region/${organId}`);
const fetchRegion = () => dispatch => Services.get(dispatch, Types.region, `${Vars.yasUserServiceAPI}/region`);
const fetchProvinces = () => dispatch => Services.get(dispatch, Types.provinces, `${Vars.yasUserServiceAPI}/provinces`);
const fetchZoneAdd = params => dispatch => Services.post(dispatch, Types.zoneAdd, `${Vars.yasUserServiceAPI}/zone`, params);
const fetchZoneByClient = organId => dispatch => Services.get(dispatch, Types.zoneByClient, `${Vars.yasUserServiceAPI}/zone/client/${organId}`);
const fetchZoneProvinces = code => dispatch => Services.get(dispatch, Types.zoneProvinces, `${Vars.yasUserServiceAPI}/zone-provinces/${code}/provinces`);
const fetchZoneProvincesAdd = params => dispatch => Services.post(dispatch, Types.zoneProvincesAdd, `${Vars.yasUserServiceAPI}/zone-provinces/provinces`, params);
const fetchBranchAdd = params => dispatch => Services.post(dispatch, Types.branchAdd, `${Vars.yasUserServiceAPI}/branch`, params);
const fetchBranchClient = organId => dispatch => Services.get(dispatch, Types.branchClient, `${Vars.yasUserServiceAPI}/branch/client/${organId}`);
const fetchRoleType = () => dispatch => Services.get(dispatch, Types.roleType, `${Vars.yasUserServiceAPI}/role-template`);
const fetchRoleList = params => dispatch => Services.get(dispatch, Types.roleList, `${Vars.yasUserServiceAPI}/organization-roles`, params);
const fetchRoleAdd = params => dispatch => Services.post(dispatch, Types.rolesAdd, `${Vars.yasUserServiceAPI}/organization-roles/replaceOrCreate`, params);
const fetchRoleCnt = params => dispatch => Services.post(dispatch, Types.rolesCnt, `${Vars.yasUserServiceAPI}/organization-roles/count/search`, params);
const fetchRoleUpdate = (params, roleId) => dispatch => Services.patch(dispatch, Types.rolesUpdate, `${Vars.yasUserServiceAPI}/organization-roles/${roleId}`, params);
const fetchUsersList = filter => dispatch => Services.get(dispatch, Types.usersList, `${Vars.yasUserServiceAPI}/users`, filter);
const fetchUsersSearch = params => dispatch => Services.post(dispatch, Types.usersSearch, `${Vars.yasUserServiceAPI}/users/search`, params);
const fetchUsersListCnt = () => dispatch => Services.get(dispatch, Types.usersListCnt, `${Vars.yasUserServiceAPI}/users/count`);
const fetchUsersListCntSch = params => dispatch => Services.post(dispatch, Types.usersListCntSch, `${Vars.yasUserServiceAPI}/users/count/search`, params);
const fetchUserType = () => dispatch => Services.get(dispatch, Types.userType, `${Vars.yasUserServiceAPI}/user-types`);
const fetchUserListAdd = params => dispatch => Services.post(dispatch, Types.usersListAdd, `${Vars.yasUserServiceAPI}/users/import-users`, params, true);
const fetchLocations = () => dispatch => Services.get(dispatch, Types.locations, `${Vars.yasUserServiceAPI}/locations`);
const fetchMenberProfile = memberId => dispatch => Services.get(dispatch, Types.memberProfile, `${Vars.yasUserServiceAPI}/users/${memberId}`);
const fetchUpdateUserRole = (params, userId) => dispatch => Services.post(dispatch, Types.updateUserRole, `${Vars.yasUserServiceAPI}/users/update-role/${userId}`, params);
const fetchUpdateUserLocal = params => dispatch => Services.post(dispatch, Types.updateUserLocations, `${Vars.yasUserServiceAPI}/user-locations/replaceOrCreate`, params);
const fetchExportRole = () => dispatch => Services.get(dispatch, Types.exportRole, `${Vars.yasUserServiceAPI}/organization-roles`, null, true);
const fetchClientView = params => dispatch => Services.post(dispatch, Types.clientView, Vars.yasAPITracking, params, null, true);
const fetchOpView = params => dispatch => Services.post(dispatch, Types.opView, Vars.yasAPITracking, params, null, true);
const fetchHighView = params => dispatch => Services.post(dispatch, Types.highView, Vars.yasAPITracking, params, null, true);
const fetchResetPass = params => dispatch => Services.post(dispatch, Types.resetPass, `${Vars.yasUserServiceAPI}/users/reset-password`, params, null, true);
// const fetchOrderTrackingNew = params => dispatch => Services.post(dispatch, Types.orderTrackingNew, Vars.yasAPITracking, params, null, true);

export {
  // authen
  fetchLogin,
  fetchLogout,
  fetchRefreshToken,
  fetchForgotPassword,
  fetchChangePassword,
  // language
  setLanguage,
  // profile
  fetchGetProfile,
  // Order Tracking Report
  fetchOrderTrackingReport,
  fetchOrderTrackingExport,
  fetchOrderTrackingExportNew,
  fetchOrderStatusTrackingExportNew,
  fetchOrderTrackingNew,
  // Order history
  fetchOrderHistory,
  fetchOrderDetails,
  fetchOrderHistoryExport,
  fetchOrderHistoryDetailExport,
  // SOH report
  fetchSOHReport,
  fetchSOHSerial,
  fetchHOSExport,
  // Master data
  fetchProductGroupType,
  fetchBrandType,
  fetchReceiveType,
  fetchClientType,
  fetchDeliveryZoneType,
  fetchLateStatusType,
  fetchDayType,
  fetchWareHouseType,
  fetchAgingDayType,
  fetchCarrierType,
  fetchTransportType,
  fetchPickType,
  fetchPackType,
  // Stock Receive Report
  fetchStockReceiveReport,
  fetchStockReceiveExport,
  fetchStockAging,
  fetchStockAgingExport,
  // Tracking
  fetchTracking,
  // Transportation
  fetchTransportation, 
  fetchTransportationCsv, 
  fetchTransportationXls,
  fetchTransportationNew,
  fetchTransportationExportNew,
  // Pick
  fetchPick,
  fetchPickExport,
  // Pack
  fetchPack,
  fetchPackExport,
  // User management
  fetchOrganizations,
  fetchOrganizationsById,
  fetchOrganizationsRole,
  fetchOrganizationsRoleOrgan,
  fetchOrganizationsRoleSearch,
  fetchOrganizationsRegion,
  fetchRegion,
  fetchProvinces,
  fetchZoneAdd,
  fetchZoneByClient,
  fetchZoneProvinces,
  fetchZoneProvincesAdd,
  fetchBranchAdd,
  fetchBranchClient,
  fetchUserType,
  fetchLocations,
  fetchRoleAdd,
  fetchRoleCnt,
  fetchRoleUpdate,
  fetchUserListAdd,
  fetchRoleList,
  fetchUsersList,
  fetchUsersSearch,
  fetchUsersListCnt,
  fetchUsersListCntSch,
  fetchMenberProfile,
  fetchRoleType,
  fetchUpdateUserRole,
  fetchUpdateUserLocal,
  fetchExportRole,
  fetchClientView,
  fetchOpView,
  fetchHighView,
  fetchResetPass,
  fetchOrderStatusTracking,
};
