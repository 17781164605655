// import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
// import { saveAs } from 'file-saver';
import commonConstant from '../../common/commonConstant';
import { history } from '../../helpers';
// import { formatDate } from '../../common/commonFunction';

export const fetchTracking = params => (
  async dispatch => {
    try {
      const res = await fetch(`${commonConstant.yasAPI}/gateway/tracking`, {
        'headers': new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        'method': 'POST',
        'body': JSON.stringify({
          'url':'/post/SerialTrackingReport',
          'param': params,
        }),
      });
      const data = await res.json();

      if (res.status === 200 || res.status === 401) {
        return dispatch({
          'type': commonConstant.GET_TRACKING_SUCCESS,
          data,
          'status': res.status,
          'dateTime': new Date(),
        });
      } else if (res.status === 500 || res.status === 502) {
        return history.push(commonConstant.pathServerError);
      }
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRACKING_FAILURE,
        'data': null,
        'status': res.status ? res.status : res,
        'dateTime': new Date(),
      });
    } catch (err) {
      history.push(commonConstant.pathOop);
      return dispatch({
        'type': commonConstant.GET_TRACKING_FAILURE,
        'data': null,
        'status': err.status ? err.status : err,
        'dateTime': new Date(),
      });
    }
  }
);
