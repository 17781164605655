import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import './ForgotPassword.scss';
import content from './Language';

import commonConstant from '../../common/commonConstant';
import { getCookieLanguage } from '../../common/commonFunction';
import { Loading } from '../../components';
import { setLanguage, fetchForgotPassword } from '../../actions';
import { ForgotPasswordSuccess } from '../../pages';
import { InputWithIcon } from '../../helpers';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'showForgotPasswordSuccess': false,
      'warning': false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.stateLanguage) {
      this.props.dispatch(setLanguage(getCookieLanguage()));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { forgotPassword } = this.props;

    if (nextProps.forgotPassword && nextProps.forgotPassword !== forgotPassword) {
      if (nextProps.forgotPassword.status === 200) {
        return this.setState({ 'showForgotPasswordSuccess': true, 'loading': false });
      } else if (nextProps.forgotPassword.status === 400) {
        return this.setState({ 'warning': true, 'loading': false });
      }
    }
  }

  handleForgotPassword = () => {
    const { email } = this.state;

    if (email) {
      this.setState({ 'loading': true, 'forgotPassword': false, 'warning': false });
      return this.props.dispatch(fetchForgotPassword({ email }));
    }
    return this.setState({ 'forgotPassword': false, 'warning': false });
  }

  handleEvent = (event, key) => this.setState({ [key]: event.target.value });

  render() {
    const { stateLanguage } = this.props;
    const { showForgotPasswordSuccess, email, warning, loading } = this.state;

    if (!stateLanguage) {
      return <Loading />;
    } else if (showForgotPasswordSuccess) {
      return <ForgotPasswordSuccess />;
    }

    return (
      <div className="ForgotPassword template-center">
        {loading ? <Loading /> : null}
        <div className="box-page">
          <img className="logo" src={commonConstant.yasLogo} alt="" />
          <div className="box-form">
            <div className="title">{content[stateLanguage]['forgotPassword']}</div>
            <div className="description">{content[stateLanguage]['description']}</div>
            <InputWithIcon
              onChange={event => this.handleEvent(event, 'email')}
              value={email}
              icon="icon-email"
              placeholder={content[stateLanguage]['email']}
              warning={warning}
            />
            <Button className="btn-primary btn-login" onClick={this.handleForgotPassword}>
              {content[stateLanguage]['send']}
            </Button>
            <Link className="link" to={commonConstant.pathLogin}>
              {content[stateLanguage]['backToLogin']}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  'dispatch': PropTypes.func.isRequired,
  'stateLanguage': PropTypes.string,
  'forgotPassword': PropTypes.shape({
    'status': PropTypes.number,
  }),
};

const mapStateToProps = ({ stateLanguage, forgotPassword }) => ({ 'stateLanguage': stateLanguage.data, forgotPassword });

export default connect(mapStateToProps)(ForgotPassword);
