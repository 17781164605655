import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import { fetchRegion, fetchOrganizationsById, fetchOrganizationsRegion } from '../../actions';

import './AreaOrganizeDetail.scss';

const AreaOrganizeDetail = ({ match }) => {
  const [codePath, setCodePath] = useState();
  const [region, setRegion] = useState([]);
  const [organ, setOrgan] = useState([]);
  const [organRegion, setOrganRegion] = useState([]);
  const getOrganizationsById = useSelector(({ getOrganizationsById }) => getOrganizationsById.data);
  const getOrganizationsRegion = useSelector(({ getOrganizationsRegion }) => getOrganizationsRegion.data);
  const getRegion = useSelector(({ getRegion }) => getRegion.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match) {
      setCodePath(match.params.code);
      dispatch(fetchOrganizationsById(match.params.code));
      dispatch(fetchOrganizationsRegion(match.params.code));
    }
  }, [match]);
  useEffect(() => {
    if (getOrganizationsById) {setOrgan(getOrganizationsById);}
  }, [getOrganizationsById]);
  useEffect(() => {
    if (getOrganizationsRegion) {
      const items = getOrganizationsRegion.map(item => {
        // item.totalZone = item.zone.length;
        item.totalRegion = item.zone.map(item => item.branch.length).reduce((a, b) => a + b, 0);
        return item;
      });
      setOrganRegion(items);
    }
  }, [getOrganizationsRegion]);
  useEffect(() => {
    if (getRegion) {setRegion(getRegion);}
    else {dispatch(fetchRegion());}
  }, [getRegion]);

  return (
    <div className="AreaOrganizeDetail">
      <div className="template-with-sidebar-navbar">
        <div className="area-org-header">
          <div className="area-org-l">
            <div className="area-item-logo">
              <img src={organ.logo} alt="" />
            </div>
            <div className="area-item-title">{organ.name}</div>
          </div>
          <div className="area-org-r">
            <Link to={`${match.params.code}/add`} className="">สร้าง Area ใหม่ <i className="icon-plus" /></Link>
          </div>
        </div>

        <div className="template-white-box yas-grid">
          <Table>
            <thead>
              <tr>
                <th>ภาค</th>
                <th>โซน</th>
                <th>สาขา</th>
              </tr>
            </thead>
            <tbody>
              {
                organRegion ? organRegion.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <Link to={`${codePath}/${item.regionId}`}>
                        {item.regionName}
                      </Link>
                    </td>
                    <td>{item.zone.length}</td>
                    <td>{item.totalRegion}</td>
                  </tr>
                )) : null
              }
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

AreaOrganizeDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default AreaOrganizeDetail;
