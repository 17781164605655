export const menus = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  TRANSPORTATION_PERFORMANCE: 'TRANSPORTATION_PERFORMANCE',
  PICK_REPORT: 'PICK_REPORT',
  PACK_REPORT: 'PACK_REPORT',
  STOCK_ON_HAND: 'STOCK_ON_HAND',
  SOH_SERIAL: 'SOH_SERIAL',
  COURIER: 'COURIER',
  PERFORMANCE_REPORT: 'PERFORMANCE_REPORT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  STOCK_RECEIVE: 'STOCK_RECEIVE',
  STOCK_AGING: 'STOCK_AGING',
  DASHBOARD: 'DASHBOARD',
  ORDER_HISTORY: 'ORDER_HISTORY',
  ORDER_TRACKING_REPORT: 'ORDER_TRACKING_REPORT',
  TRACKING: 'TRACKING',
};
export const sortType = {
  ASC: 'asc',
  DESC: 'desc',
};
export const orderStatusTrackingType = [
  { text: 'Received Order', value: 'Received Order' },
  // { text: 'Picked', value: 'Picked' },
  // { text: 'Packed', value: 'Packed' },
  { text: 'Packing', value: 'Packing' },
  { text: 'Ready For Ship', value: 'Ready For Ship' },
  { text: 'In Transit', value: 'In Transit' },
  { text: 'Delivered', value: 'Delivered' },
  { text: 'Undelivered', value: 'Undelivered' },
];
export const intransitType = [
  { text: 'Still under SLA', value: '1' },
  { text: 'Over SLA', value: '0' },
  { text: 'Retry to deliver', value: '631_394,631_305,613_332,612_334,616_339,616_351,632_350' },
];
export const retryToDeliverType = [
  { text: 'All', value: '631_394,631_305,613_332,612_334,616_339,616_351,632_350' },
  { text: '1.Incorrect address / phone number', value: '631_394,631_305' },
  { text: '2.Unable to reach recipient', value: '613_332,613_334' },
  { text: '3.Postpone for delivery', value: '616_339,616_351,632_350' },
];
export default {
  // path
  pathLogin: '/login',
  pathLogout: '/logout',
  pathForgotPassword: '/forgot-password',
  pathForceChangePassword: '/force-change-password',
  pathServerError: '/server-error',
  pathOop: '/oop',
  // User management
  pathAddNewUser: '/add-new-user',
  pathAddNewUserByType: '/add-new-user/:type',
  pathAddNewUserSuccess: '/add-new-user/:type/success',
  pathUserManagement: '/user-management',
  pathUserManagementAdd: '/user-management/add',
  pathUserManagementView: '/user-management/:code',
  pathUserManagementEdit: '/user-management/:code/edit',
  pathProfile: '/profile',
  pathOrderTrackingReport: '/order-tracking-report',
  // Create for test
  pathOrderTrackingReportNew: '/order-tracking-report-new',
  pathTransportNew: '/transportation-performance-new',
  pathOrderHistory: '/order-history',
  pathStockOnHand: '/stock-on-hand',
  pathSOHSerial: '/soh-serial',
  pathStockReceive: '/stock-receive',
  pathStockAging: '/stock-aging',
  pathTracking: '/tracking',
  pathTransport: '/transportation-performance',
  pathPick: '/pick',
  pathPack: '/pack',
  pathDashboard: '/dashboard',
  pathAreaManagement: '/area-management',
  pathAreaManagementAdd: '/area-management/organize/:code/add',
  pathAreaOrganizeDetail: '/area-management/organize/:code',
  pathAreaOrganizeEdit: '/area-management/organize/:organId/:localId',
  pathRoleManagement: '/role-management',
  pathRoleManagementAdd: '/role-management/add',
  pathRoleManagementView: '/role-management/:code',
  pathRoleManagementEdit: '/role-management/:code/edit',
  pathReCreatePass: '/recreate-password',
  pathOrderStatusTracking: '/order-status-tracking-report',
  // image
  yasLogo: '/images/yas_logo.png',
  error404: '/images/error_404.png',
  error500: '/images/error_500.png',
  errorOop: '/images/error_oop.png',
  loading: '/images/loading.gif',
  iconCheck: '/images/icon_check.png',
  yasLogoWhite: '/images/yas_logo_white.png',
  yasLogoSmall: '/images/yas_logo_sm.png',
  userDummy: '/images/user_dummy.png',
  // env
  yasAPI: process.env.REACT_APP_DOMAIN_YAS_API,
  yasAPITracking: `${process.env.REACT_APP_DOMAIN_YAS_API}/gateway/tracking`,
  yasUserServiceAPI: process.env.REACT_APP_DOMAIN_YAS_USER_SERVICE,
  domainCookie: process.env.REACT_APP_DOMAIN_COOKIE
    ? process.env.REACT_APP_DOMAIN_COOKIE
    : '',
  // app bg color
  removeBG: '',
  addBGGray: 'app-bg-gray',
  // defalut value
  cookieLanguage: 'language',
  cookieToken: 'yas_token',
  defaultLanguage: 'th',
  defaultTTLLogin: 10800, // unit secound
  defaultTTLRefreshToken: 10800,
  // action type
  //  - language
  SET_LANGUAGE_SUCCESS: 'SET_LANGUAGE_SUCCESS',
  SET_LANGUAGE_FAILURE: 'SET_LANGUAGE_FAILURE',
  //  - authen
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
  //  - profile
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  // type path
  typePathWarehouse: 'warehouse',
  typePathClient: 'client',
  typePathAE: 'ae',
  // default value
  defaultChoosePrivilege: {
    area: null,
    province: null,
    zone: null,
    branch: null,
    role: null,
  },
  defaultPathMenu: {
    dashboard: ['Home', 'Dashboard'],
    addNewUser: ['Home', 'Add new'],
    addNewUserSuccess: ['Home', 'Add new', 'Success'],
    profile: ['Home', 'Profile'],
    orderTrackingReport: ['Home', 'Order-tracking-report'],
    orderHistory: ['Home', 'Order-history'],
    stockOnHand: ['Home', 'Stock-on-hand'],
    sohSerial: ['Home', 'SOH-serial'],
    stockReceive: ['Home', 'Stock-receive'],
    stockAging: ['Home', 'Stock-aging'],
    tracking: ['Home', 'Tracking'],
    transport: ['Home', 'Transportation-performance'],
    pick: ['Home', 'Pick'],
    pack: ['Home', 'Pack'],
    areaManagement: ['Home', 'Area-management'],
    areaManagementAdd: ['Home', 'Area-management', 'Add-new'],
    areaOrganizeDetail: ['Home', 'Area-management', 'Details'],
    areaOrganizeEdit: ['Home', 'Area-management', 'Organize'],
    roleManagement: ['Home', 'Role-management'],
    roleManagementAdd: ['Home', 'Role-management', 'Add'],
    roleManagementView: ['Home', 'Role-management', 'View'],
    roleManagementEdit: ['Home', 'Role-management', 'Edit'],
    userManagement: ['Home', 'User-management'],
    userManagementAdd: ['Home', 'User-management', 'Add'],
    userManagementEdit: ['Home', 'User-management', 'Edit'],
    orderStatusTracking: ['Home', 'Order-status-tracking-report'],
  },
  // Pagination
  pagination: {
    itemsCountPerPage: 30,
    pageRangeDisplayed: 6,
  },
  // Order tracking report
  GET_ORDER_TRACKING_REPORT_SUCCESS: 'GET_ORDER_TRACKING_REPORT_SUCCESS',
  GET_ORDER_TRACKING_REPORT_FAILURE: 'GET_ORDER_TRACKING_REPORT_FAILURE',
  GET_ORDER_TRACKING_EXPORT_SUCCESS: 'GET_ORDER_TRACKING_EXPORT_SUCCESS',
  GET_ORDER_TRACKING_EXPORT_FAILURE: 'GET_ORDER_TRACKING_EXPORT_FAILURE',
  // Master Type
  GET_DELIVERY_ZONE_SUCCESS: 'GET_DELIVERY_ZONE_SUCCESS',
  GET_DELIVERY_ZONE_FAILURE: 'GET_DELIVERY_ZONE_FAILURE',
  GET_LATE_STATUS_SUCCESS: 'GET_LATE_STATUS_SUCCESS',
  GET_LATE_STATUS_FAILURE: 'GET_LATE_STATUS_FAILURE',
  GET_CLIENT_TYPE_SUCCESS: 'GET_CLIENT_TYPE_SUCCESS',
  GET_CLIENT_TYPE_FAILURE: 'GET_CLIENT_TYPE_FAILURE',
  GET_DAY_TYPE_SUCCESS: 'GET_DAY_TYPE_SUCCESS',
  GET_DAY_TYPE_FAILURE: 'GET_DAY_TYPE_FAILURE',
  GET_BRAND_TYPE_SUCCESS: 'GET_BRAND_TYPE_SUCCESS',
  GET_BRAND_TYPE_FAILURE: 'GET_BRAND_TYPE_FAILURE',
  GET_AGING_DAY_TYPE_SUCCESS: 'GET_AGING_DAY_TYPE_SUCCESS',
  GET_AGING_DAY_TYPE_FAILURE: 'GET_AGING_DAY_TYPE_FAILURE',
  GET_RECEIVE_TYPE_SUCCESS: 'GET_RECEIVE_TYPE_SUCCESS',
  GET_RECEIVE_TYPE_FAILURE: 'GET_RECEIVE_TYPE_FAILURE',
  GET_TRANSPORT_TYPE_SUCCESS: 'GET_TRANSPORT_TYPE_SUCCESS',
  GET_TRANSPORT_TYPE_FAILURE: 'GET_TRANSPORT_TYPE_FAILURE',
  GET_CARRIER_TYPE_SUCCESS: 'GET_CARRIER_TYPE_SUCCESS',
  GET_CARRIER_TYPE_FAILURE: 'GET_CARRIER_TYPE_FAILURE',
  GET_PICK_TYPE_SUCCESS: 'GET_PICK_TYPE_SUCCESS',
  GET_PICK_TYPE_FAILURE: 'GET_PICK_TYPE_FAILURE',
  GET_PACK_TYPE_SUCCESS: 'GET_PACK_TYPE_SUCCESS',
  GET_PACK_TYPE_FAILURE: 'GET_PACK_TYPE_FAILURE',
  GET_PRODUCT_GROUP_SUCCESS: 'GET_PRODUCT_GROUP_SUCCESS',
  GET_PRODUCT_GROUP_FAILURE: 'GET_PRODUCT_GROUP_FAILURE',
  GET_WARE_HOUSE_SUCCESS: 'GET_WARE_HOUSE_SUCCESS',
  GET_WARE_HOUSE_FAILURE: 'GET_WARE_HOUSE_FAILURE',
  GET_ORDER_DETAILS_SUCCESS: 'GET_ORDER_DETAILS_SUCCESS',
  GET_ORDER_DETAILS_FAILURE: 'GET_ORDER_DETAILS_FAILURE',
  // Order history
  GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
  GET_ORDER_HISTORY_FAILURE: 'GET_ORDER_HISTORY_FAILURE',
  GET_ORDER_HISTORY_CSV_SUCCESS: 'GET_ORDER_HISTORY_CSV_SUCCESS',
  GET_ORDER_HISTORY_CSV_FAILURE: 'GET_ORDER_HISTORY_CSV_FAILURE',
  GET_ORDER_HISTORY_DETAIL_EXPORT_SUCCESS:
    'GET_ORDER_HISTORY_DETAIL_EXPORT_SUCCESS',
  GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE:
    'GET_ORDER_HISTORY_DETAIL_EXPORT_FAILURE',
  // SOH Report
  GET_SOH_REPORT_SUCCESS: 'GET_SOH_REPORT_SUCCESS',
  GET_SOH_REPORT_FAILURE: 'GET_SOH_REPORT_FAILURE',
  GET_SOH_SERIAL_SUCCESS: 'GET_SOH_SERIAL_SUCCESS',
  GET_SOH_SERIAL_FAILURE: 'GET_SOH_SERIAL_FAILURE',
  GET_SOH_EXPORT_SUCCESS: 'GET_SOH_EXPORT_SUCCESS',
  GET_SOH_EXPORT_FAILURE: 'GET_SOH_EXPORT_FAILURE',
  // Stock receive report
  GET_STOCK_RECEIVE_REPORT_SUCCESS: 'GET_STOCK_RECEIVE_REPORT_SUCCESS',
  GET_STOCK_RECEIVE_REPORT_FAILURE: 'GET_STOCK_RECEIVE_REPORT_FAILURE',
  GET_STOCK_RECEIVE_EXPORT_SUCCESS: 'GET_STOCK_RECEIVE_EXPORT_SUCCESS',
  GET_STOCK_RECEIVE_EXPORT_FAILURE: 'GET_STOCK_RECEIVE_EXPORT_FAILURE',
  // Stock Aging
  GET_STOCK_AGING_SUCCESS: 'GET_STOCK_AGING_SUCCESS',
  GET_STOCK_AGING_FAILURE: 'GET_STOCK_AGING_FAILURE',
  GET_STORCK_AGING_EXPORT_SUCCESS: 'GET_STORCK_AGING_EXPORT_SUCCESS',
  GET_STORCK_AGING_EXPORT_FAILURE: 'GET_STORCK_AGING_EXPORT_FAILURE',
  // Tracking
  GET_TRACKING_SUCCESS: 'GET_TRACKING_SUCCESS',
  GET_TRACKING_FAILURE: 'GET_TRACKING_FAILURE',
  // Transportation performance
  GET_TRANSPORTATION_SUCCESS: 'GET_TRANSPORTATION_SUCCESS',
  GET_TRANSPORTATION_FAILURE: 'GET_TRANSPORTATION_FAILURE',
  GET_TRANSPORTATION_CSV_SUCCESS: 'GET_TRANSPORTATION_CSV_SUCCESS',
  GET_TRANSPORTATION_CSV_FAILURE: 'GET_TRANSPORTATION_CSV_FAILURE',
  GET_TRANSPORTATION_XLS_SUCCESS: 'GET_TRANSPORTATION_XLS_SUCCESS',
  GET_TRANSPORTATION_XLS_FAILURE: 'GET_TRANSPORTATION_XLS_FAILURE',
  // Pick
  GET_PICK_SUCCESS: 'GET_PICK_SUCCESS',
  GET_PICK_FAILURE: 'GET_PICK_FAILURE',
  GET_PICK_EXPORT_SUCCESS: 'GET_PICK_EXPORT_SUCCESS',
  GET_PICK_EXPORT_FAILURE: 'GET_PICK_EXPORT_FAILURE',
  // Pack
  GET_PACK_SUCCESS: 'GET_PACK_SUCCESS',
  GET_PACK_FAILURE: 'GET_PACK_FAILURE',
  GET_PACK_EXPORT_SUCCESS: 'GET_PACK_EXPORT_SUCCESS',
  GET_PACK_EXPORT_FAILURE: 'GET_PACK_EXPORT_FAILURE',
  orderStatusTrackingType,
  intransitType,
  retryToDeliverType,
};
