import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { 
  fetchOrganizations, 
  fetchRegion, 
  fetchUserType, 
  fetchOrganizationsRole, 
  fetchMenberProfile,
  fetchUpdateUserRole,
  fetchZoneByClient,
  fetchBranchClient,
  fetchOrganizationsRoleOrgan,
} from '../../actions';
import { Dropdowns, history } from '../../helpers';
import { formatDate } from '../../common/commonFunction';

import './UserManagenemt.scss';

const UserManagenemtProfileEdit = ({ match }) => {
  const [params, setParams] = useState({});
  const [locations, setLocations] = useState([{}]);
  const [clientType, setClientType] = useState([]);
  const [userType, setUserType] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const [branchClient, setBranchClient] = useState([]);
  const [regionType, setRegionType] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [zoneType, setZoneType] = useState([]);
  const [statusType] = useState([
    { text: 'Active', value: true }, { text: 'Inactive', value: false },
  ]);
  const [model, setModel] = useState({
    tracking: '',
    client: '',
    user: '',
    name: '',
    email: '',
    phone: '',
    status: '',
  });

  const getUserType = useSelector(({ getUserType }) => getUserType.data);
  const getOrganizations = useSelector(({ getOrganizations }) => getOrganizations.data);
  const getOrganizationsRole = useSelector(({ getOrganizationsRole }) => getOrganizationsRole.data);
  const getRegion = useSelector(({ getRegion }) => getRegion.data);
  const getZoneByClient = useSelector(({ getZoneByClient }) => getZoneByClient.data);
  const getBranchClient = useSelector(({ getBranchClient }) => getBranchClient.data);
  const getOrganizationsRoleOrgan = useSelector(({ getOrganizationsRoleOrgan }) => getOrganizationsRoleOrgan.data);
  const getMenberProfile = useSelector(({ getMenberProfile }) => getMenberProfile.data);
  const getUpdateUserRole = useSelector(({ getUpdateUserRole }) => getUpdateUserRole);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match) { setParams(match.params); }
    if (match && Object.keys(match.params).length > 0 && getMenberProfile === undefined) {
      dispatch(fetchMenberProfile(match.params.code));
      dispatch(fetchRegion());
    }
  }, [match]);
  useEffect(() => {
    if (getMenberProfile) {
      const data = {
        name: getMenberProfile.name,
        email: getMenberProfile.email,
        client: { text: getMenberProfile.organization.name, value: getMenberProfile.organization.organizationId },
        user: { text: getMenberProfile.userType.name, value: getMenberProfile.userType.userTypeId },
        status: { text: getMenberProfile.isActive ? 'Active' : 'Inactive', value: getMenberProfile.isActive },
      };
      const rolelItems = getMenberProfile.userLocations.length > 0
        ? getMenberProfile.userLocations.map(item => {
          const branch = item.branch ? { text: item.branch.branchName, value: item.branch.branchId } : {};
          const zone = item.zone ? { text: item.zone.zoneName, value: item.zone.zoneId } : {};
          const region = item.region ? { text: item.region.regionName, value: item.region.regionId } : {};
          return {
            role : { text: item.userRoles.name, value: item.userRoles.organizationRoleId },
            branch,
            zone,
            region,
          };
        })
        : getMenberProfile.userRoles.map(item => {
          return { role : { text: item.role.name, value: item.role.organizationRoleId } };
        });
      
      setLocations(rolelItems);
      setModel(Object.assign({ ...model }, data));
      setUserProfile(getMenberProfile);
      // get user role by organization id
      dispatch(fetchZoneByClient(getMenberProfile.organizationId));
      dispatch(fetchBranchClient(getMenberProfile.organizationId));
      dispatch(fetchOrganizationsRoleOrgan(getMenberProfile.organizationId));
    }
  }, [getMenberProfile]);
  useEffect(() => {
    if (getOrganizations) {
      const items = getOrganizations.map(item => {
        return { text: item.name, value: item.code };
      });
      
      setClientType(items);
    } else {
      dispatch(fetchOrganizations());
    }
  }, [getOrganizations]);
  useEffect(() => {
    if (getUserType) {
      const items = getUserType.map(item => {
        return { text: item.name, value: item.userTypeId, isActive: false };
      });
      setUserType(items);
    } 
    else { dispatch(fetchUserType()); }
  }, [getUserType]);
  useEffect(() => {
    if (getOrganizationsRole) {
      const items = getOrganizationsRole.map(item => {
        return { text: item.name, value: item.organizationRoleId };
      });
      setRoleType(items);
    }
    else { dispatch(fetchOrganizationsRole()); }
  }, [getOrganizationsRole]);
  useEffect(() => {
    if (getUpdateUserRole && getUpdateUserRole.status === 200) {
      cancelUserProfile();
    }
  }, [getUpdateUserRole]);
  useEffect(() => {
    if (getRegion) {
      const items = getRegion.map(item => {
        item.text = item.regionName;
        item.value = item.regionId;
        return item;
      });
      setRegionType(items);
    }
  }, [getRegion]);
  useEffect(() => {
    if (getZoneByClient) {
      const items = getZoneByClient.map(item => {
        item.text = item.zoneName;
        item.value = item.zoneId;
        return item;
      });
      setZoneType(items);
    }
  }, [getZoneByClient]);
  useEffect(() => {
    if (getBranchClient) {
      const items = getBranchClient.map(item => {
        item.text = item.branchName;
        item.value = item.branchId;
        return item;
      });
      setBranchClient(items);
    }
  }, [getBranchClient]);
  useEffect(() => {
    if (getOrganizationsRoleOrgan) {
      const items = getOrganizationsRoleOrgan.map(item => {
        item.text = item.name;
        item.value = item.organizationRoleId;
        return item;
      });
      setRoleType(items);
    }
  }, [getOrganizationsRoleOrgan]);

  const handleSelect = (key, value) => setModel(Object.assign({ ...model }, { [key]: value }));
  const handleLocat = (key, type, value) => {
    const items = locations.map((item, i) => {
      if (i === key) {
        return Object.assign(item, { [type]: value });
      }
      return item;
    });
    
    setLocations(items);
  };
  const addUserRole = () => {
    const data = [];
    for (let i = 0; locations.length > i; i++) {
      if (locations[i].role) {
        data.push({
          regionId: locations[i].region ? locations[i].region.value : null,
          zoneId: locations[i].zone ? locations[i].zone.value : null,
          branchId: locations[i].branch ? locations[i].branch.value : null, 
          organizationRoleId: locations[i].role.value,
        });
      } else {
        alert('กรุณาเช็ค role ให้ครบ');
      }
    }
    dispatch(fetchUpdateUserRole({ data: [...data], userId: userProfile.userId }, userProfile.userId));
  };
  const cancelUserProfile = () => {
    return history.push(`/user-management/${params.code}`);
  };
  const deleteRole = key => setLocations(locations.filter((_, i) => i !== key));

  return (
    <div className="UserManagenemtProfile">
      <div className="template-with-sidebar-navbar">
        <div className="template-white-box mb-4">
          <div className="row mb-3">
            <div className="col h4">แก้ไขข้อมูลส่วนตัว</div>
          </div>
          <div className="row mb-3">
            <div className="col-4 form-user">
              <small>ประเภท user</small>
              <Dropdowns
                placeholder="เลือก Organization"
                items={userType}
                selected={model.user}
                disabled={false}
                handleSelect={value => handleSelect('user', value)}
              />
            </div>
            <div className="col-5 form-user">
              <small>องค์กร</small>
              <Dropdowns
                placeholder="เลือกองค์กร"
                items={clientType}
                selected={model.client}
                disabled={false}
                handleSelect={value => handleSelect('client', value)}
              />
            </div>
          </div>
          <div className="row align-items-center">
            {/* <div className="form-user-avetar">
              <div className="user-box-avetar"></div>
            </div> */}
            <div className="col form-user">
              <small>ชื่อ - นามสกุล</small>
              <Input value={model.name} onChange={e => handleSelect('name', e.target.value)} type="text" placeholder="ชื่อ - นามสกุล" readOnly />
            </div>
            <div className="col form-user">
              <small>อีเมลล์</small>
              <Input value={model.email} onChange={e => handleSelect('email', e.target.value)} type="email" placeholder="Email" readOnly />
            </div>
            <div className="col form-user">
              <small>เบอร์โทรศัพท์</small>
              <Input value={model.phone} onChange={e => handleSelect('phone', e.target.value)} type="email" placeholder="เบอร์โทรศัพท์" readOnly />
            </div>
          </div>
          <div className="row bg-light">
            <div className="col-4 form-user">
              <small>สร้างเมื่อ</small>
              <p>{formatDate(userProfile.createdAt)}</p>
            </div>
            <div className="col form-user">
              <small>สร้างโคย</small>
              <p>Super Administrator</p>
            </div>
            <div className="col form-user">
              <small>การแก้ไข</small>
              <p>ล่าสุด {formatDate(userProfile.createdAt)}</p>
            </div>
          </div>
          <div className="row-role mb-4">
            <div className="h6">User roles</div>
            {
              locations ? locations.map((item, key) => (
                <div key={key} className="role-detail mb-3">
                  <div className="role-detail-number">สิทธิ์ {key + 1}</div>
                  {
                    locations.length > 1 ? <div onClick={() => deleteRole(key)} className="role-detail-del">ลบ</div> : null
                  }
                  <div className="form-user">
                    <small>ภาค</small>
                    <Dropdowns
                      placeholder="ภาค"
                      items={regionType}
                      selected={locations[key].region}
                      disabled={true}
                      handleSelect={value => handleLocat(key, 'region', value)}
                    />
                  </div>
                  <div className="form-user">
                    <small>โซน</small>
                    <Dropdowns
                      placeholder="โซน"
                      items={zoneType}
                      selected={locations[key].zone}
                      disabled={true}
                      handleSelect={value => handleLocat(key, 'zone', value)}
                    />
                  </div>
                  <div className="form-user">
                    <small>สาขา</small>
                    <Dropdowns
                      placeholder="สาขา"
                      items={branchClient}
                      selected={locations[key].branch}
                      disabled={true}
                      handleSelect={value => handleLocat(key, 'branch', value)}
                    />
                  </div>
                  <div className="form-user">
                    <small>เลือก Role</small>
                    <Dropdowns
                      placeholder="Role"
                      items={roleType}
                      selected={locations[key].role}
                      disabled={true}
                      handleSelect={value => handleLocat(key, 'role', value)}
                    />
                  </div>
                </div>
              )) : null
            }
            <div className="mt-3 text-center">
              <Button color="success" size="sm" onClick={() => setLocations(Object.assign([...locations, {}]))}> +  เพิ่มสิทธิ์</Button>
              <span className="mr-4 ml-4">|</span>
              <Link to="/role-management"><small> +  Add roles</small></Link>
              <Link to="/area-management" className="ml-3"><small> +  Add areas</small></Link>
            </div>
          </div>
          <div className="row">
            <div className="col-4 d-flex align-items-center">
              <span className="mr-4">สถานะ</span>
              <Dropdowns
                placeholder="เลือกองค์กร"
                items={statusType}
                selected={model.status}
                disabled={false}
                handleSelect={value => handleSelect('status', value)}
              />
            </div>
          </div>
        </div>
        
        <div className="text-right">
          <Button onClick={cancelUserProfile} color="light">ยกเลิก</Button>
          <Button onClick={addUserRole} color="secondary">ยืนยัน</Button>
        </div>
      </div>
    </div>
  );
};

UserManagenemtProfileEdit.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default UserManagenemtProfileEdit;
