import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchOpView, fetchClientType } from '../../actions';
import { ChartLineWidth } from '../../components';
import { Dropdowns } from '../../helpers';
const colors = ['#FF715B', '#34D1BF', '#0496FF', '#6665DD', '#F2994A', '#F2C94C'];

const OPView = () => {
  const [clientType, setClientType] = useState([{ text: 'Daily (in 5 day)', value: 'DAY' }, { text: 'Weekly (in 4 week)', value: 'WEEK' }, { text: 'Monthy (in 3 month)', value: 'MONTH' }]);
  const [client, setClient] = useState({ text: '', value: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [areaModel, setAreaModel] = useState([]);
  const [tableModel, setTableModel] = useState([]);
  const getOpView = useSelector(({ getOpView }) => getOpView.data);
  const getProfile = useSelector(({ getProfile }) => getProfile);
  const getClientType = useSelector(({ getClientType }) => getClientType.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getOpView) {
      const { orderStatusByArea, orderStatusByDay } = getOpView;

      setAreaModel(orderStatusByArea ? orderStatusByArea : []);
      setTableModel(orderStatusByDay ? orderStatusByDay : []);
    }
  }, [getOpView]);
  useEffect(() => {
    // if (getClientType) {
      setClientType(getClientType.result.list);
    // } else if (client && client.value === 'YAS') {
    //   dispatch(fetchClientType());
    // }
  }, [getClientType]);
  useEffect(() => {
    if (getProfile && getProfile.status === 200) {
      const { clients } = getProfile;

      setClient(...clients);
      init('');
    }
  }, [getProfile]);
  useEffect(() => {
    const interval = setInterval(() => {
      init('');
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  const init = _client => {
    const params = {
      param: {
        client: _client ? _client : Object.keys(client).length ? client.value : '',
      },
      url: '/post/OPViewDashBoard',
    };
    dispatch(fetchOpView(params));
  };
  const headleClient = data => {
    setClient(data);
    init(data.value);
  };
  const handlerOpen = () => setIsOpen(!isOpen);
  const checkToday = day => day === new Date().toISOString().split('T')[0] ? 'Today' : day;

  return (
    <div className="OPView">
      <div className="c-filter">
        <div className="txt-filter">Clients</div>
        <div className="filter-dropdown">
          <Dropdowns
            placeholder="Client..."
            items={clientType}
            selected={client}
            disabled={true}
            handleSelect={value => headleClient(value)} />
        </div>
      </div>
      <div className="c-detail">
        {
          areaModel.length ? areaModel.map((item, key) => (
            isOpen ? <div key={key} className="div-and-table">
              <div className="_l">
                <p>{checkToday(item.date)}</p>
                <h4>{parseInt(item.totalOrderUnit.split('/')[0]).toLocaleString()}</h4>
                <small>Total unit: {parseInt(item.totalOrderUnit.split('/')[1]).toLocaleString()}</small>
              </div>
              <div className="_r">
                <table className="db-table">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Picked</td>
                      <td>Packed</td>
                      <td>Ready to ship</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      item.totalOfDate.map((i, k) => (
                        <tr key={k}>
                          <td className="_op-title">{i.region}</td>
                          <td className="_w28">
                            <ChartLineWidth txt={`${i.picking}%`} percent={`${i.picking}%`} color={colors[k]} />
                          </td>
                          <td className="_w28">
                            <ChartLineWidth txt={`${i.packing}%`} percent={`${i.packing}%`} color={colors[k]} />
                          </td>
                          <td className="_w28">
                            <ChartLineWidth txt={`${i.readyToShip}%`} percent={`${i.readyToShip}%`} color={colors[k]} />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
              : key < 1 ? <div key={key} className="div-and-table">
                <div className="_l">
                  <p>{checkToday(item.date)}</p>
                  <h4>{parseInt(item.totalOrderUnit.split('/')[0]).toLocaleString()}</h4>
                  <small>Total unit: {parseInt(item.totalOrderUnit.split('/')[1]).toLocaleString()}</small>
                </div>
                <div className="_r">
                  <table className="db-table">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Picked</td>
                        <td>Packed</td>
                        <td>Ready to ship</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        item.totalOfDate.map((i, k) => (
                          <tr key={k}>
                            <td className="_op-title">{i.region}</td>
                            <td className="_w28">
                              <ChartLineWidth txt={`${i.picking}%`} percent={`${i.picking}%`} color={colors[k]} />
                            </td>
                            <td className="_w28">
                              <ChartLineWidth txt={`${i.packing}%`} percent={`${i.packing}%`} color={colors[k]} />
                            </td>
                            <td className="_w28">
                              <ChartLineWidth txt={`${i.readyToShip}%`} percent={`${i.readyToShip}%`} color={colors[k]} />
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
                : null
          )) : null
        }
        <div className="bottom-headle"><span onClick={handlerOpen}>See more</span></div>
      </div>

      <div className="c-detail-table">
        <table className="opview">
          <thead>
            <tr>
              <td></td>
              <td>Ready to ship</td>
              <td>In-transit</td>
              <td>Delivered</td>
              <td>Delivered failed</td>
              <td>Delivered</td>
            </tr>
          </thead>
          <tbody>
            {
              tableModel.length ? tableModel.map((item, key) => (
                <tr key={key}>
                  <td>{item.date}</td>
                  <td><b>{parseInt(item.readyToShip).toLocaleString()}</b></td>
                  <td><b>{parseInt(item.intransit).toLocaleString()}</b></td>
                  <td><b>{parseInt(item.delivered).toLocaleString()}</b></td>
                  <td><b>{parseInt(item.unDelivered).toLocaleString()}</b></td>
                  <td><b>{parseInt(item.deliveredPercent)}%</b></td>
                </tr>
              )) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OPView;
