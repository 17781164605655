export default {
  'th': {
    'detail': 'Oop! Something went wrong',
    'description': 'Sorry, we can’t get the infomation right now. Please try again later',
    'backToHome': 'BACK TO HOMEPAGE',
  },
  'en': {
    'detail': 'Oop! Something went wrong',
    'description': 'Sorry, we can’t get the infomation right now. Please try again later',
    'backToHome': 'BACK TO HOMEPAGE',
  },
};
