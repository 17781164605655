import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';

import { fetchRegion, fetchProvinces, fetchZoneByClient, fetchOrganizationsById, fetchZoneProvinces, fetchZoneAdd, fetchZoneProvincesAdd } from '../../actions';

import './AreaOrganizeEdit.scss';

const AreaOrganizeEdit = ({ match }) => {
  const [filterPv, setFilterPv] = useState('');
  const [zone, setZone] = useState();
  const [zoneName, setZoneName] = useState('');
  const [params, setParams] = useState();
  const [region, setRegion] = useState();
  const [zoneClient, setZoneClient] = useState([]);
  const [organ, setOrgan] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [zoneProvinces, setZoneProvinces] = useState([]);
  const getRegion = useSelector(({ getRegion }) => getRegion.data);
  const getOrganizationsById = useSelector(({ getOrganizationsById }) => getOrganizationsById.data);
  const getZoneAdd = useSelector(({ getZoneAdd }) => getZoneAdd);
  const getZoneByClient = useSelector(({ getZoneByClient }) => getZoneByClient.data);
  const getZoneProvinces = useSelector(({ getZoneProvinces }) => getZoneProvinces.data);
  const getZoneProvincesAdd = useSelector(({ getZoneProvincesAdd }) => getZoneProvincesAdd.data);
  const getProvinces = useSelector(({ getProvinces }) => getProvinces.data);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (match) { 
      setParams(match.params);
      dispatch(fetchOrganizationsById(match.params.organId));
      dispatch(fetchZoneByClient(match.params.organId));
      dispatch(fetchProvinces());
    }
  }, [match]);
  useEffect(() => {
    if (getProvinces) {
      const addActiveProvinces = getProvinces.map(item => {
        item.active = true;
        return item;
      });
      setProvinces(addActiveProvinces);
    }
  }, [getProvinces]);
  useEffect(() => {
    if (getRegion && params && params.localId) {
      const items = getRegion.filter(item => item.regionId === params.localId);
      
      setRegion(items[0]);
    }
    else {dispatch(fetchRegion());}
  }, [getRegion]);
  useEffect(() => {
    if (getOrganizationsById) {setOrgan(getOrganizationsById);}
  }, [getOrganizationsById]);
  useEffect(() => {
    if (getZoneByClient && region && params && params.localId) {
      const addStatusToZoneClient = getZoneByClient.map(item => {
        item.active = true;
        item.isShow = region.regionId === item.regionId ? true : false;
        return item;
      });
      
      setZoneClient(addStatusToZoneClient);
    }
  }, [getZoneByClient]);
  useEffect(() => {
    assignZoneProvinces(getZoneProvinces);
  }, [getZoneProvinces]);
  useEffect(() => {
    if (getZoneAdd && getZoneAdd.status === 200 && params && params.organId) {
      dispatch(fetchZoneByClient(params.organId));
      setZoneName('');
    }
  }, [getZoneAdd]);
  useEffect(() => {
    if (getZoneProvincesAdd && getZoneProvincesAdd.status === 200) {
      console.log('Succussfully');
    }
  }, [getZoneProvincesAdd]);

  function assignZoneProvinces(getZoneProvinces) {
    if (getZoneProvinces && getZoneProvinces.length > 0) {
      const getZoneToProvince = getZoneProvinces.map(item => item.province);
      const provinceMatchZone = provinces.map(item => {
        item.active = _.some(getZoneToProvince, ['provinceId', item.provinceId]) ? false : true;
        return item;
      });

      setProvinces(provinceMatchZone);
      setZoneProvinces(getZoneToProvince);
    } else {
      setZoneProvinces([]);
    }
  }
  const reZoneProvinces = () => assignZoneProvinces(getZoneProvinces);
  const toggleZoneItem = zoneItem => {
    const setPV = provinces.map(item => {
      item.active = true;
      return item;
    });
    
    setProvinces(setPV);
    setZone(zoneItem);
    dispatch(fetchZoneProvinces(zoneItem.zoneId));
  };
  const toggleProvince = provinceItem => {
    const provinceActive = provinces.map(item => {
      item.active = item.active ? item.provinceId === provinceItem.provinceId ? false : true : false;
      return item;
    });

    setProvinces(provinceActive);
    setZoneProvinces(zoneProvinces ? Object.assign([...zoneProvinces, provinceItem]) : [provinceItem]);
  };
  const toggleDelProvince = provinceItem => {
    const provinceActive = provinces.map(item => {
      item.active = !item.active ? item.provinceId === provinceItem.provinceId ? true : false : true;
      return item;
    });

    setProvinces(provinceActive);
    setZoneProvinces(zoneProvinces.filter(item => item.provinceId !== provinceItem.provinceId));
  };
  const addZone = () => {
    const params = {
      zoneName: zoneName,
      zoneCode: 'ZONE_CODE',
      regionId: region.regionId,
      organizationId: organ.organizationId,
    };

    dispatch(fetchZoneAdd(params));
  };
  const addZoneProvinces = () => {
    const params = {
      zoneId: zone.zoneId,
      provinces: zoneProvinces.map(item => item.provinceId),
    };

    dispatch(fetchZoneProvincesAdd(params));
  };
  const DeleteZoneProvinces = () => {
    if (window.confirm('ต้องการจะลบจริงหรือไม่')) {
      const params = {
        zoneId: zone.zoneId,
        provinces: [],
      };
  
      setZoneProvinces([]);
      dispatch(fetchZoneProvincesAdd(params));
    }
  };

  return (
    <div className="AreaOrganizeEdit">
      <div className="template-with-sidebar-navbar">
        <div className="area-org-header">
          <div className="area-org-l">
            <div className="area-item-logo">
              <img src={organ.logo} alt="" />
            </div>
            <div className="area-item-title">{organ.name}</div>
            <div className="area-item-title">{' > '}</div>
            <div className="area-item-title"><small>ภาค</small>{region ? region.regionName : null}</div>
          </div>
        </div>

        <div className="organize-detail-edit">
          <div className="organize-detail-edit-l">
            <div className="organize-box mb-3">
              <div className="header-org">โซน</div>
              {
                zoneClient ? zoneClient.map((item, key) => (
                  item.isShow ? <div key={key} onClick={() => toggleZoneItem(item)} className="org-list-item">{item.zoneName}</div> : null
                )) : 'ไม่มีข้อมูล'
              }
            </div>
            <div className="orginize-form-input mb-3">
              <Input onChange={e => setZoneName(e.target.value)} value={zoneName} type="text" placeholder="เพิ่มโซน..." />
            </div>
            <div className="organize-buttom mb-3">
              <Button onClick={addZone} color="secondary">ยืนยัน</Button>
            </div>
          </div>
          {
            zone ?
              <div className="organize-detail-edit-r">
                <div className="organize-detail-header">
                  <div className="_org-l">{zone ? zone.zoneName : '-'}</div>
                  <div onClick={DeleteZoneProvinces} className="_org-r">ลบโซน <i className="icon-close" /></div>
                </div>
                <div className="organize-detail-doby">
                  <div className="org-detail-doby-l">
                    <div className="org-detail-doby-title">จังหวัดทั้งหมด</div>
                    <div className="org-detail-list">
                      <Input onChange={e => setFilterPv(e.target.value)} type="text" placeholder="ค้นหารายชื่อ..." />
                      {
                        provinces ? provinces.map((item, key) =>
                          item.active && item.provinceName.search(filterPv) >= 0 ? <div key={key} onClick={() => toggleProvince(item)} className="org-detail-list-item">{item.provinceName}</div> : null
                        ) : null
                      }
                    </div>
                  </div>
                  <div className="org-detail-doby-c"><i className="icon-arrow-down-t" /></div>
                  <div className="org-detail-doby-r">
                    <div className="org-detail-doby-title">จังหวัดที่เลือก</div>
                    <div className="org-detail-list odl_r">
                      {
                        zoneProvinces ? zoneProvinces.map((item, key) => (
                          <div key={key} onClick={() => toggleDelProvince(item)} className="org-detail-list-item">{item.provinceName}</div>
                        )) : null
                      }
                    </div>
                  </div>
                </div>
                <div className="organize-detail-footer">
                  <Button onClick={reZoneProvinces} color="link">ยกเลิก</Button>
                  <Button onClick={addZoneProvinces} color="secondary">ยืนยัน</Button>
                </div>
              </div>
              : null  
          }
        </div>
      </div>
    </div>
  );
};

AreaOrganizeEdit.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organId: PropTypes.string,
      localId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default AreaOrganizeEdit;
