import commonConstant from '../../common/commonConstant';

export const getTransportation = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_TRANSPORTATION_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_TRANSPORTATION_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
export const getTransportationCsv = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_TRANSPORTATION_CSV_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_TRANSPORTATION_CSV_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
export const getTransportationXls = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_TRANSPORTATION_XLS_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_TRANSPORTATION_XLS_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
