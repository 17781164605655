import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import './App.scss';

import commonConstant from './common/commonConstant';
import { history } from './helpers';
import { SideBar, Navbar, NewLoading } from './components';
import {
  Login,
  ForgotPassword,
  CheckRedirect,
  NotFound,
  ServerError,
  Oop,
  Logout,
  ForceChangePassword,
  OrderTrackingReport,
  OrderHistory,
  StockOnHand,
  SOHSerial,
  StockReceive,
  StockAging,
  Tracking,
  Transportation,
  Pick,
  Pack,
  Dashboard,
  AreaManagement,
  AreaManagementAdd,
  AreaOrganizeDetail,
  AreaOrganizeEdit,
  RoleManagement,
  RoleManagementView,
  RoleManagementAddEdit,
  UserManagenemt,
  UserManagenemtAdd,
  UserManagenemtProfile,
  UserManagenemtProfileEdit,
  ReCreatePassword,
  OrderStatusTrackingReport,
  // Create for Test
  OrderTrackingReportNew,
  TransportationNew,
} from './pages';

const MainTemplateWithSideBar = () => (
  <div className="template-with-sidebar">
    <SideBar />
    <div className="box-page">
      <Navbar />
      <div className="template-with-navbar">
        <NewLoading />
        <Switch>
          <Route sensitive strict exact path={commonConstant.pathUserManagement} component={UserManagenemt} />
          <Route sensitive strict exact path={commonConstant.pathUserManagementAdd} component={UserManagenemtAdd} />
          <Route sensitive strict exact path={commonConstant.pathUserManagementView} component={UserManagenemtProfile} />
          <Route sensitive strict exact path={commonConstant.pathUserManagementEdit} component={UserManagenemtProfileEdit} />
          <Route sensitive strict exact path={commonConstant.pathDashboard} component={Dashboard} />
          {/* <Route sensitive strict exact path={commonConstant.pathOrderTrackingReport} component={OrderTrackingReport} /> */}
          <Route sensitive strict exact path={commonConstant.pathOrderTrackingReport} component={OrderTrackingReportNew} />
          <Route sensitive strict exact path={commonConstant.pathOrderHistory} component={OrderHistory} />
          <Route sensitive strict exact path={commonConstant.pathStockOnHand} component={StockOnHand} />
          <Route sensitive strict exact path={commonConstant.pathSOHSerial} component={SOHSerial} />
          <Route sensitive strict exact path={commonConstant.pathStockReceive} component={StockReceive} />
          <Route sensitive strict exact path={commonConstant.pathStockAging} component={StockAging} />
          <Route sensitive strict exact path={commonConstant.pathTracking} component={Tracking} />
          {/* <Route sensitive strict exact path={commonConstant.pathTransport} component={Transportation} /> */}
          <Route sensitive strict exact path={commonConstant.pathTransport} component={TransportationNew} />
          {/* // Add test url pathOrderTrackingReport */}
          <Route sensitive strict exact path={commonConstant.pathOrderTrackingReportNew} component={OrderTrackingReportNew} />
          <Route sensitive strict exact path={commonConstant.pathTransportNew} component={TransportationNew} />
          <Route sensitive strict exact path={commonConstant.pathPick} component={Pick} />
          <Route sensitive strict exact path={commonConstant.pathPack} component={Pack} />
          <Route sensitive strict exact path={commonConstant.pathAreaManagement} component={AreaManagement} />
          <Route sensitive strict exact path={commonConstant.pathAreaManagementAdd} component={AreaManagementAdd} />
          <Route sensitive strict exact path={commonConstant.pathAreaOrganizeDetail} component={AreaOrganizeDetail} />
          <Route sensitive strict exact path={commonConstant.pathAreaOrganizeEdit} component={AreaOrganizeEdit} />
          <Route sensitive strict exact path={commonConstant.pathRoleManagement} component={RoleManagement} />
          <Route sensitive strict exact path={commonConstant.pathRoleManagementAdd} component={RoleManagementAddEdit} />
          <Route sensitive strict exact path={commonConstant.pathRoleManagementEdit} component={RoleManagementAddEdit} />
          <Route sensitive strict exact path={commonConstant.pathRoleManagementView} component={RoleManagementView} />
          <Route sensitive strict exact path={commonConstant.pathOrderStatusTracking} component={OrderStatusTrackingReport} />
          <Route sensitive strict exact path={commonConstant.pathLogout} component={Logout} />
        </Switch>
      </div>
    </div>
  </div>
);

export default () => (
  <Router history={history}>
    <div className="App">
      <Switch>
        <Route sensitive strict exact path="/" component={CheckRedirect} />
        <Route sensitive strict exact path={commonConstant.pathLogin} component={Login} />
        <Route sensitive strict exact path={commonConstant.pathLogout} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathForgotPassword} component={ForgotPassword} />
        <Route sensitive strict exact path={commonConstant.pathForceChangePassword} component={ForceChangePassword} />
        <Route sensitive strict exact path={commonConstant.pathUserManagement} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathUserManagementAdd} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathUserManagementView} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathUserManagementEdit} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathOrderTrackingReport} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathOrderHistory} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathStockOnHand} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathSOHSerial} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathStockReceive} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathStockAging} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathTracking} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathTransport} component={MainTemplateWithSideBar} />
        {/* // Add test url pathOrderTrackingReport */}
        <Route sensitive strict exact path={commonConstant.pathOrderTrackingReportNew} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathTransportNew} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathPick} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathPack} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathDashboard} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathAreaManagement} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathAreaManagementAdd} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathAreaOrganizeDetail} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathAreaOrganizeEdit} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathRoleManagement} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathRoleManagementAdd} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathRoleManagementEdit} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathRoleManagementView} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathOrderStatusTracking} component={MainTemplateWithSideBar} />
        <Route sensitive strict exact path={commonConstant.pathReCreatePass} component={ReCreatePassword} />
        <Route sensitive strict exact path={commonConstant.pathServerError} component={ServerError} />
        <Route sensitive strict exact path={commonConstant.pathOop} component={Oop} />
        <Route sensitive strict exact component={NotFound} />
      </Switch>
    </div>
  </Router>
);
