import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import { fetchOrganizations, fetchUserType, fetchRoleType, fetchRoleAdd, fetchOrganizationsRole, fetchRoleUpdate } from '../../actions';
import { Dropdowns, history } from '../../helpers';

import './RoleManagement.scss';

const RoleManagementAddEdit = ({ match }) => {
  const [params, setParams] = useState({});
  const [clientType, setClientType] = useState([]);
  const [userType, setUserType] = useState([]);
  const [roleType, setRoleType] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [organs, setOrgans] = useState([]);
  const [customMenu, setCustomMenu] = useState([]);
  const [isCustom, setIsCustom] = useState(false);
  const [roleModel, setRoleModel] = useState({});
  const [model, setModel] = useState({
    roleName: '',
    client: {
      text: '',
      value: '',
    },
    user: {
      text: '',
      value: '',
    },
    role: {
      text: '',
      value: '',
    },
  });
  const getUserType = useSelector(({ getUserType }) => getUserType.data);
  const getOrganizations = useSelector(({ getOrganizations }) => getOrganizations.data);
  const getRoleType = useSelector(({ getRoleType }) => getRoleType.data);
  const getRoleAdd = useSelector(({ getRoleAdd }) => getRoleAdd);
  const getRoleUpdate = useSelector(({ getRoleUpdate }) => getRoleUpdate);
  const getOrganizationsRole = useSelector(({ getOrganizationsRole }) => getOrganizationsRole.data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (match && Object.keys(match.params).length > 0) {
      setParams(match.params);
      if (match.params.code) {
        dispatch(fetchOrganizationsRole({ 'where': { 'organizationRoleId': match.params.code } }));
      }
    }
  }, [match]);
  useEffect(() => {
    if (getOrganizationsRole && userType && organs && Object.keys(params).length > 0) {
      const OrganRole = getOrganizationsRole[0];
      const user = OrganRole.userType ? { text: OrganRole.userType.name, value: OrganRole.userType.userTypeId } : { text: '', value: '' };
      const role = OrganRole.roleTemplate ? { text: OrganRole.roleTemplate.name, value: OrganRole.roleTemplate.roleTemplateId } : { text: '', value: '' };
      const upUserType = OrganRole.userType ? userType.map(item => {
        item.isActive = item.value === OrganRole.userType.userTypeId ? true : false;
        return item;
      }) : userType;
      const roleFeatures = OrganRole.roleTemplate && OrganRole.roleTemplate.roleFeatureTemplates ? OrganRole.roleTemplate.roleFeatureTemplates : [];
      const data = {
        roleName: OrganRole.name,
        user,
        role,
        client: { text: OrganRole.organization.name, value: OrganRole.organization.organizationId },
      };

      setIsCustom(OrganRole.userType && OrganRole.userType.code === 'CUSTOM' ? true : false);
      const orgItems = organs.filter(item => item.userTypeId === OrganRole.userTypeId);
      setReRole(orgItems);
      setUserType(upUserType);
      setMenuList(roleFeatures);
      setCustomMenu(OrganRole.roleFeatures);
      setModel(Object.assign({ ...model }, data));
      setRoleModel(getOrganizationsRole[0]);
    }
  }, [getOrganizationsRole, organs]);
  useEffect(() => {
    if (getOrganizations) {
      const items = getOrganizations.map(item => {
        return { text: item.name, value: item.organizationId };
      });
      setClientType(items);
    } else {
      dispatch(fetchOrganizations());
    }
  }, [getOrganizations]);
  useEffect(() => {
    if (getUserType) {
      const items = getUserType.map(item => {
        return { text: item.name, value: item.userTypeId, isActive: false };
      });
      setUserType(items);
    } 
    else { dispatch(fetchUserType()); }
  }, [getUserType]);
  useEffect(() => {
    if (getRoleType) {
      setOrgans(getRoleType);
    }
    else { dispatch(fetchRoleType()); }
  }, [getRoleType]);
  useEffect(() => {
    if (getRoleAdd && model.roleName) {
      history.push('/role-management');
    }
  }, [getRoleAdd]);
  useEffect(() => {
    if (getRoleUpdate && model.roleName) {
      history.push('/role-management');
    }
  }, [getRoleUpdate]);

  const setReRole = orgItems => {
    const items = orgItems.map(item => {
      item.text = item.name;
      item.value = item.roleTemplateId;
      return item;
    });
    setRoleType(items);
  };
  const handleSelect = (key, value) => setModel(Object.assign({ ...model }, { [key]: value }));
  const handleUserType = code => {
    setRoleType([]);
    setMenuList([]);
    setCustomMenu([]);
    
    const items = userType.map(item => {
      item.isActive = item.value === code ? true : false;
      return item;
    });

    const checkCustom = getUserType.filter(i => i.userTypeId === code);
    if (checkCustom.length && checkCustom[0].code === 'CUSTOM') {
      setIsCustom(true);
    }
    if (organs) {
      const orgItems = organs.filter(item => item.userTypeId === code);
      setReRole(orgItems);
    }
    setModel(Object.assign(model, { user: code, role: { text: '', value: '' } }));
    setUserType(items);
  };
  const handleRoleType = code => {
    setModel(Object.assign(model, { role: code }));
    setMenuList(organs.filter(item => item.roleTemplateId === code.value)[0].roleFeatureTemplates);
  };
  const addNewRole = type => {
    const { roleName, client, user, role } = model;
    if (roleName && client.value && user) {
      const roleFeature = customMenu.length > 0
        ? customMenu.map(item => item.featureId)
        : menuList.map(item => item.featureId);
      const nUser = user.length > 0 ? user : user.value;
      const params = {
        name: roleName,
        code: 'ROLE_CODE',
        organizationId: client.value,
        userTypeId: nUser,
        roleTemplateId: role.value ? role.value : null,
        isActive: true,
        roleFeature,
      };

      if (type) {
        params.organizationRoleId = roleModel.organizationRoleId;
        dispatch(fetchRoleAdd(params));
      } else {
        dispatch(fetchRoleAdd(params));
      }
    } else {
      alert('กรอกข้อมูลให้ครบด้วยครับ');
    }
  };
  const cancelRole = type => {
    if (type) {
      return history.push('/role-management');
    } else {
      return history.push(`/role-management/${params.code}`);
    }
  };
  const addMenuList = itemMenu => {
    if (isCustom) {
      const checkItems = customMenu.length > 0 ? customMenu.filter(i => i.featureId === itemMenu.featureId) : [];
      if (checkItems.length > 0) {
        const items = customMenu.length > 0 ? customMenu.filter(i => i.featureId !== itemMenu.featureId) : [];
        setCustomMenu(items);
      } else {
        setCustomMenu(Object.assign([...customMenu, itemMenu]));
      }
    }
  };
  const isCheckBox = id => customMenu.some(i => i.featureId === id);

  return <div className="RoleManagement">
    <div className="template-with-sidebar-navbar">
      <div className="template-white-box  mb-4">
        <div className="title-white-box mb-3">
          <div className="title-white-box-r">{ params.code ? 'Edit' : 'Add new'} role</div>
        </div>
        <div className="last-title mb-3">รายละเอียด Role</div>
        <div className="row mb-3">
          <div className="col-2">ชื่อ Role</div>
          <div className="col-5"><Input value={model.roleName} onChange={e => handleSelect('roleName', e.target.value)} type="text" placeholder="Ex. warehouse Rungsit" /></div>
        </div>
        <div className="row mb-3">
          <div className="col-2">องค์กร</div>
          <div className="col-5">
            <Dropdowns
              placeholder="องค์กร"
              items={clientType}
              selected={model.client}
              disabled={true}
              handleSelect={value => handleSelect('client', value)}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-2">รูปแบบ</div>
          <div className="items-check-box col">
            {
              userType && userType.length > 0 ? userType.map((item, key) => (
                <div key={key} onClick={ () => handleUserType(item.value)} className={`item ${item.isActive ? '_active' : ''}`}>{item.text}</div>
              )) : null
            }
          </div>
        </div>
        <div className="last-title mb-3">เลือกประเภท Role</div>
        <div className="row mb-3">
          <div className="col-2">Role</div>
          <div className="col-5">
            <Dropdowns
              placeholder="Role"
              items={roleType}
              selected={model.role}
              disabled={roleType.length > 0 ? true : false}
              handleSelect={value => handleRoleType(value)}
            />
          </div>
        </div>
        <div className="items-roles row mb-3">
          {
            menuList && menuList.length ? menuList.map((item, key) => (
              <div key={key} onClick={() => addMenuList(item)} className={`col-3 ${isCustom ? 'pointer' : ''} ${isCheckBox(item.featureId) && isCustom ? '_active' : ''}`}><i className="icon-dashboard" /> {item.feature.name}</div>
            )) : <div className="col-3">ไม่มีข้อมูล</div>
          }
        </div>
      </div>
      <div className="text-right">
        { params.code
          ? <Button onClick={() => cancelRole()} color="link">ยกเลิก</Button>
          : <Button onClick={() => cancelRole('goBack')} color="link">ยกเลิก</Button>}
        { params.code
          ? <Button onClick={() => addNewRole('edit')} color="secondary">แก้ไข</Button>
          : <Button onClick={() => addNewRole()} color="secondary">ยืนยัน</Button>}
      </div>
    </div>
  </div>;
};

RoleManagementAddEdit.prototype = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organId: PropTypes.string,
      localId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default RoleManagementAddEdit;
