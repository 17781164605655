import commonConstant from '../../common/commonConstant';

export const getSOHReport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_SOH_REPORT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_SOH_REPORT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getSOHSerial = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_SOH_SERIAL_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_SOH_SERIAL_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};

export const getSOHExport = (state = {}, action) => {
  switch (action.type) {
    case commonConstant.GET_SOH_EXPORT_SUCCESS:
      return { ...state, ...action };
    case commonConstant.GET_SOH_EXPORT_FAILURE:
      return { ...state, ...action };
    default:
      return state;
  }
};
