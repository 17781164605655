import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import Select from 'react-select';
import { Button, Table } from 'reactstrap';
import {
  fetchOrderHistory,
  fetchClientType,
  fetchDeliveryZoneType,
  fetchWareHouseType,
  fetchOrderDetails,
  fetchOrderHistoryExport,
  fetchOrderHistoryDetailExport,
  fetchGetProfile
} from '../../actions';

import './OrderHistory.scss';

import { WhiteBox, InputWithIcon, Dropdowns, DatePicker, ThSorting } from '../../helpers';
import commonConstant from '../../common/commonConstant';
import { formatDate } from '../../common/commonFunction';

import { Loading, RefreshToken } from '../../components';

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientProps: '',
      client: '',
      deliveryZone: null,
      orderFromDate: null,
      orderToDate: null,
      tracking: null,
      wareHouse: null,
      deliveryDate: null,
      orderHistory: null,
      orderDetails: [],
      clientType: [],
      deliveryZoneType: [],
      wareHouseType: [],
      modalOrderHistoryDetail: [],
      current: 1,
      typeSort: '',
      column: '',
      isLoading: false,
      roleList: [],
    };
    this.props.dispatch(fetchGetProfile());
  }
  componentDidMount() {
    if (this.props.getProfile) {
      const { clients, roles } = this.props.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if (nextProps.getProfile && nextProps.getProfile !== this.props.getProfile) {
      const { clients, roles } = nextProps.getProfile;
      const roleList = roles && roles.length > 0 ? roles : [];
      this.setState({ roleList });
      if (clients) { this.checkRole(clients); }
    }
    if (nextProps.getOrderHistory && nextProps.getOrderHistory !== this.props.getOrderHistory) {
      this.setState({ orderHistory: nextProps.getOrderHistory, isLoading: false });
    }
    if (nextProps.getOrderHistoryExport && nextProps.getOrderHistoryExport !== this.props.getOrderHistoryExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getOrderHistoryDetailExport && nextProps.getOrderHistoryDetailExport !== this.props.getOrderHistoryDetailExport) {
      this.setState({ isLoading: false });
    }
    if (nextProps.getOrderDetails && nextProps.getOrderDetails !== this.props.getOrderDetails) {
      this.setState({ orderDetails: nextProps.getOrderDetails.data, isLoading: false });
    }
    if (nextProps.getClientType && nextProps.getClientType !== this.props.getClientType) {
      const clientType = nextProps.getClientType.result.list.map(item => {
        item.label = item.text;
        return item;
      });
      this.setState({ clientType });
    }
    if (nextProps.getDeliveryZoneType && nextProps.getDeliveryZoneType !== this.props.getDeliveryZoneType) {
      this.setState({ deliveryZoneType: nextProps.getDeliveryZoneType.result.list });
    }
    if (nextProps.getWareHouseType && nextProps.getWareHouseType !== this.props.getWareHouseType) {
      this.setState({ wareHouseType: nextProps.getWareHouseType.result.list });
    }
  }
  onSorting = async (column, typeSort) => {
    const { current } = this.state;

    this.setState({ column: await column, typeSort: await typeSort });
    this.handleSearch(current);
  }
  getInit = code => {
    this.setState({
      wareHouseType: 'LOADING',
      deliveryZoneType: 'LOADING',
    });
    this.props.dispatch(fetchDeliveryZoneType(code));
    this.props.dispatch(fetchWareHouseType(code));
  }
  checkRole = clients => {
    this.setState({ clientProps: clients });
    const roleList = this.state.roleList;
    console.log('------- client --------');
    console.log(roleList);
    if (roleList[0] === 'SUPER_ADMIN') {
      this.props.dispatch(fetchClientType());
      this.getInit('');
    } else {
      const items = clients.map(item => {
        const data = {};
        data.label = item.text;
        data.value = item.value;
        return data;
      });

      this.setState({ clientType: items });
      this.getInit(clients.map(_ => _.value).join());
    }
  }
  handleTitle = () => (
    <div className="box-title">
      <span className="title">Search</span>
      <InputWithIcon
        onChange={event => this.handleSelect('tracking', event.target.value)}
        value={this.state.tracking}
        icon="icon-search"
        placeholder="ex. order number"
      />
    </div>
  );
  selectClient = client => this.setState({ client });
  selectClientOnblue = (value, action) => {
    if (action.action === 'input-blur') {
      const { client } = this.state;
      const clientFilter = client && client.length > 0 ? client.map(item => item.value).join() : '';
      if (clientFilter) {
        this.getInit(clientFilter);
      }
    }
  }

  handleSelect = (key, value) => this.setState({ [key]: value });

  handleSearch = (current, isSch) => {
    this.setState({ isLoading: true });
    if (isSch) {this.setState({ current, typeSort: '', column: '' });}
    const { client, clientProps, deliveryZone, orderFromDate, orderToDate, tracking, wareHouse, typeSort, column } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      deliveryZone: deliveryZone ? deliveryZone.value : '',
      warehouse:  wareHouse ? wareHouse.value : '',
      orderDatefrom: orderFromDate,
      orderDateto: orderToDate,
      searchProductId:'',
      tracking: tracking,
      orderBy: typeSort ? column : '',
      orderType: typeSort,
      pageNumber: current,
      pageSize: commonConstant.pagination.itemsCountPerPage,
    };
    this.props.dispatch(fetchOrderHistory(params));
  }
  clsSearch = () => {
    const { clientProps } = this.state;
    this.getInit(clientProps);
    this.setState({
      client: '',
      deliveryZone: '',
      orderFromDate: '',
      orderToDate: '',
      tracking: '',
      wareHouse: '',
      column: '',
      typeSort: '',
    });
  }
  pageChange = current => {
    this.setState({ current });
    this.handleSearch(current);
  }
  toggleOrderDetail = item => {
    document.body.style.overflow = 'hidden';
    const params = {
      client: '',
      tracking: item.orderNo,
    };
    this.props.dispatch(fetchOrderDetails(params));
    this.setState({ modalOrderHistoryDetail: [item], isLoading: true });
  };
  toggleClsOrderDetail = () => {
    document.body.style.overflow = 'auto';
    this.setState({ modalOrderHistoryDetail: [] });
  };
  exportWorkBook = (type, expMode) => {
    this.setState({ isLoading: true });
    const { client, clientProps, deliveryZone, orderFromDate, orderToDate, tracking, wareHouse } = this.state;
    const clientFilter = client && client.length > 0
      ? client.map(item => item.value).join()
      : clientProps[0].value === 'YAS' ? '' : clientProps.map(item => item.value).join();

    const params = {
      client: clientFilter,
      deliveryZone: deliveryZone ? deliveryZone.value : '',
      warehouse:  wareHouse ? wareHouse.value : '',
      orderDatefrom: orderFromDate,
      orderDateto: orderToDate,
      searchProductId:'',
      tracking: tracking,
      exportMode: expMode,
    };

    if (expMode === 1) {
      this.props.dispatch(fetchOrderHistoryExport(params, type));
    } else if (expMode === 2) {
      this.props.dispatch(fetchOrderHistoryDetailExport(params, type));
    }
  }

  checkNull = data => data ? data : '-';

  render() {
    const {
      client,
      deliveryZone,
      orderFromDate,
      orderToDate,
      wareHouse,
      orderHistory,
      orderDetails,
      modalOrderHistoryDetail,
      clientType,
      deliveryZoneType,
      wareHouseType,
      current,
      column,
      isLoading,
    } = this.state;

    if (deliveryZoneType === 'LOADING' || wareHouseType === 'LOADING') {
      return <Loading />;
    }

    return (
      <div className="OrderHistory">
        {isLoading || clientType.length < 1 ? <Loading /> : null}
        <div className="template-with-sidebar-navbar">
          <WhiteBox title={this.handleTitle()} hr>
            <div className="row mb-2">
              <div className="col-4">
                <Select
                  placeholder="Client"
                  closeMenuOnSelect={false}
                  isMulti
                  value={client}
                  options={clientType}
                  onChange={this.selectClient}
                  onInputChange={this.selectClientOnblue.bind(this)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Warehouse"
                  items={wareHouseType}
                  selected={wareHouse}
                  disabled={Array.isArray(wareHouseType)}
                  handleSelect={value => this.handleSelect('wareHouse', value)}
                />
              </div>
              <div className="col-4">
                <Dropdowns
                  placeholder="Zone"
                  items={deliveryZoneType}
                  selected={deliveryZone}
                  disabled={Array.isArray(deliveryZoneType)}
                  handleSelect={value => this.handleSelect('deliveryZone', value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5 box-date">
                <span className="title">Order date</span>
                <DatePicker
                  selectYear
                  selectDate={orderFromDate}
                  handleSelectDate={value => this.handleSelect('orderFromDate', value)}
                  placeholder="from"
                />
                <DatePicker
                  selectYear
                  selectDate={orderToDate}
                  handleSelectDate={value => this.handleSelect('orderToDate', value)}
                  placeholder="to"
                  disabledDays={orderFromDate}
                  disabled={orderFromDate ? false : true}
                />
              </div>
              <div className="col text-right">
                <div className="box-btn">
                  <Button className="btn-light" onClick={this.clsSearch}>Clear</Button>
                  <Button className="btn-primary" onClick={() => this.handleSearch(1, true)}>
                    Browse
                    <span className="icon icon-search" />
                  </Button>
                </div>
              </div>
            </div>
          </WhiteBox>

          {
            orderHistory ?
              <div className="overall-details">
                <div className="overall">
                  <div className="overall-head">
                    <div className="title">Overall</div>
                    <div onClick={() => this.exportWorkBook('CSV', 1)} className="export"><i className="menu-icon icon-csv" /> Export CSV</div>
                    <div onClick={() => this.exportWorkBook('CSV', 2)} className="export"><i className="menu-icon icon-csv" /> Export CSV with detail</div>
                    <div onClick={() => this.exportWorkBook('XLS', 1)} className="export"><i className="menu-icon icon-xlsx" /> Export Excel</div>
                    <div onClick={() => this.exportWorkBook('XLS', 2)} className="export"><i className="menu-icon icon-xlsx" /> Export excel with detail</div>
                  </div>
                  <div className="overall-detail">
                    <div className="o-item">
                      <span>No. of order</span>
                      <p>{orderHistory.total[0].countOrder.toLocaleString()}</p>
                    </div>
                    <div className="o-item">
                      <span>Total Unit</span>
                      <p>{orderHistory.total[0].totalProduct.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
                <div className="template-white-box yas-grid">
                  <Table>
                    <thead>
                      <tr>
                        <ThSorting click={this.onSorting} label="Client" name="client" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order Date/Time" name="orderDateTime" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order Number" name="orderNo" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Code" name="customerCode" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Customer Name" name="customerName" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Address" name="address" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Region" name="region" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="BOX" name="boxQty" currentColumn={column} />
                        <ThSorting click={this.onSorting} label="Order QTY" name="orderQty" currentColumn={column} />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        orderHistory ? orderHistory.data.map((item, key) => (
                          <tr key={key} className="pointer" onClick={() => this.toggleOrderDetail(item)}>
                            <td>{item.client}</td>
                            <td>{formatDate(item.orderDateTime)}</td>
                            <td>{item.orderNo}</td>
                            <td>{item.customerCode}</td>
                            <td>{item.customerName}</td>
                            <td>{item.address}</td>
                            <td>{item.region}</td>
                            <td>{item.boxQty}</td>
                            <td>{item.orderQty}</td>
                          </tr>
                        )) : null
                      }
                    </tbody>
                  </Table>

                </div>
                <div className="yas-pagination">
                  <div className="d-left">
                      Showing {current === 1 ? current : (current - 1) * commonConstant.pagination.itemsCountPerPage}{' '}
                      to {current === 1 ? commonConstant.pagination.itemsCountPerPage : (current - 1) * commonConstant.pagination.itemsCountPerPage + commonConstant.pagination.itemsCountPerPage}{' '}
                      of {orderHistory ? orderHistory.totalRow[0].countRow.toLocaleString() : 0} entries
                  </div>
                  <div className="d-right">
                    <Pagination
                      activePage={current}
                      itemsCountPerPage={commonConstant.pagination.itemsCountPerPage}
                      pageRangeDisplayed={commonConstant.pagination.pageRangeDisplayed}
                      totalItemsCount={orderHistory ? orderHistory.totalRow[0].countRow : 0}
                      onChange={this.pageChange}
                    />
                  </div>
                </div>
              </div> : null
          }
        </div>

        {
          modalOrderHistoryDetail.length > 0 ?
            <div className="modal-order-details">
              <div className="modal-details">
                <div className="yas-order-detail container">
                  <div className="head-detail">
                    <div className="row order-header">
                      <div className="col">
                        <span>Order number</span>
                        <h1>{modalOrderHistoryDetail[0].orderNo}</h1>
                      </div>
                    </div>
                    <div className="row order-detail">
                      <div className="col-3">
                        <span>Customer Code</span>
                        <p>{this.checkNull(modalOrderHistoryDetail[0].customerCode)}</p>
                      </div>
                      <div className="col-3">
                        <span>Customer Name</span>
                        <p>{this.checkNull(modalOrderHistoryDetail[0].customerName)}</p>
                      </div>
                      <div className="col-3">
                        <span>Order Date</span>
                        <p>{formatDate(modalOrderHistoryDetail[0].orderDateTime)}</p>
                      </div>
                      <div className="col-3">
                        <span>Total Box</span>
                        <p>{this.checkNull(modalOrderHistoryDetail[0].boxQty)}</p>
                      </div>
                      <div className="col-3">
                        <span>Total QTY</span>
                        <p>{this.checkNull(modalOrderHistoryDetail[0].orderQty)}</p>
                      </div>
                    </div>
                  </div>
                  <div className="body-detail yas-grid">
                    <h1>List</h1>
                    <div className="scroll-grid">
                      <Table>
                        <thead>
                          <tr>
                            <th>Serial number</th>
                            <th>Product Number</th>
                            <th>Product Description</th>
                            <th>QTY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            orderDetails ? orderDetails.map((item, key) => (
                              <tr key={key}>
                                <td>{this.checkNull(item.imei)}</td>
                                <td>{this.checkNull(item.productNumber)}</td>
                                <td>{this.checkNull(item.productDesc)}</td>
                                <td>{item.qty ? item.qty.toLocaleString() : 0}</td>
                              </tr>
                            )) : null
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="yas-modal-footer close">
                    <button className="btn btn-light" onClick={this.toggleClsOrderDetail}>ปิดหน้าต่าง <i className="menu-icon icon-close" /></button>
                  </div>
                </div>
              </div>
            </div> : null
        }
        <RefreshToken />
      </div>
    );
  }
}

OrderHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getProfile: PropTypes.func,
  getOrderHistory: PropTypes.func,
  getClientType: PropTypes.func,
  getDeliveryZoneType: PropTypes.func,
  getWareHouseType: PropTypes.func,
  getOrderDetails: PropTypes.func,
  getOrderHistoryExport: PropTypes.func,
  getOrderHistoryDetailExport: PropTypes.func,
};

const mapStateToProps = state => ({
  getProfile: state.getProfile,
  getOrderHistory: state.getOrderHistory.data,
  getClientType: state.getClientType.data,
  getDeliveryZoneType: state.getDeliveryZoneType.data,
  getWareHouseType: state.getWareHouseType.data,
  getOrderDetails: state.getOrderDetails.data,
  getOrderHistoryExport: state.getOrderHistoryExport,
  getOrderHistoryDetailExport: state.getOrderHistoryDetailExport,
});

export default connect(mapStateToProps)(OrderHistory);
