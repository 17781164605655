import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';

import { fetchResetPass } from '../../actions';
import { InputWithIcon, validatePassword } from '../../helpers';

import '../ForceChangePassword/ForceChangePassword.scss';

const ReCreatePassword = ({ location }) => {
  const [newPassword, setNewPassword] = useState();
  const [qRef, setQRef] = useState();
  const [isError, setIsError] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const getResetPass = useSelector(({ getResetPass }) => getResetPass);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      const urlParams = new URLSearchParams(location.search);
      setQRef(urlParams.get('ref'));
    }
  }, [location]);
  useEffect(() => {
    if (getResetPass && getResetPass.status === 404) {
      setIsError('เกิดข้อผิดพลาด');
    } else if (getResetPass && getResetPass.status === 200) {
      window.location = './login';
    }
  }, [getResetPass]);
  const handleSubmit = () => {
    setIsError(null);
    if (qRef && newPassword && confirmPassword && newPassword === confirmPassword) {
      if (!validatePassword(newPassword)) { return setIsError('รหัสผ่านง่ายเกินไป'); }
      const params = {
        ref: qRef,
        password: newPassword,
      };
  
      dispatch(fetchResetPass(params));
    } else {
      setIsError('Please check your password');
    }
  };
  
  return (
    <div className="ForceChangePassword template-center">
      <div className="box-page">
        <div className="box-form">
          <h3 className="text-center">Re-Create password</h3>
          <p className="text-center">Please enter a new password</p>

          <small>รหัสผ่านต้องมีจำนวนตัวอักษรระหว่าง 8-20 ตัวอักษร รวมถึง มีตัวอักษรพิมพ์เล็ก, พิมพ์ใหญ่ และตัวเลขอย่างน้อยหนึ่งตัวอักษร</small>

          {isError ? <p className="warning-text mt-4">{isError}</p> : null}

          <InputWithIcon
            onChange={e => setNewPassword(e.target.value)}
            value={newPassword}
            icon="icon-lock"
            placeholder="New password"
            type="password"
          />
          <InputWithIcon
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            icon="icon-lock"
            placeholder="Re-enter password"
            type="password"
          />
          <Button className="btn-primary btn-submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReCreatePassword;
